import { IHttpClient } from "core/protocols/http_client";
import * as Sentry from "@sentry/react";

export class CreateUserInput {
    authMethod: string;
    authUid: string;
    email: string;

    constructor(args: { authMethod: string, authUid: string, email: string }) {
        this.authMethod = args.authMethod;
        this.authUid = args.authUid;
        this.email = args.email;
    }

    validate() {
        if (!this.authMethod) {
            throw new Error('authMethod is required');
        }
        if (!this.authUid) {
            throw new Error('authUid is required');
        }
        if (!this.email) {
            throw new Error('email is required');
        }
    }

    toMap() {
        return {
            aut_method: this.authMethod,
            auth_uid: this.authUid,
            email: this.email,
            state: 'logged',
            customer: {
                email: this.email,
            }
        };
    }
}

export interface ICreateUserUsecase {
    execute(args: CreateUserInput): Promise<void>;
}

export default class CreateUserUsecaseImpl implements ICreateUserUsecase {
    private httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }

    async execute(input: CreateUserInput): Promise<void> {
        const endpoint = '/bff/v1/user';
        input.validate();
        return this.httpClient.post<any>(endpoint, input.toMap()).then((result) => {
            return result.data;
        }).catch((error) => {
            Sentry.captureException(error, {
                tags: {
                    'feature.purchase': "CreateUserUsecase",
                    'api.endpoint': endpoint,
                },
            });
            throw error;
        });
    }
}