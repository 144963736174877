import {
    Dispatch,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useReducer
} from 'react';
import Drawer from './drawer';

type DrawerAction = {
    type: 'OPEN' | 'CLOSE';
    content?: ReactNode;
};

type DrawerState = {
    open: boolean;
    content: ReactNode;
};

function drawerReducer(state: DrawerState, action: DrawerAction) {
    switch (action.type) {
        case 'OPEN':
            return { open: true, content: action.content };
        case 'CLOSE':
            return { ...state, open: false };
        default:
            return state;
    }
}

const DrawerContext = createContext<{
    open: boolean;
    dispatch: Dispatch<DrawerAction>;
}>({ open: false, dispatch: () => { } });

export default function DrawerProvider({ children }: { children: ReactNode }) {
    const [{ open, content }, dispatch] = useReducer(drawerReducer, {
        open: false,
        content: <>Empty</>
    });

    return (
        <DrawerContext.Provider value={{ open, dispatch }}>
            {children}
            <Drawer
                open={open}
                onClose={() => {
                    dispatch({ type: 'CLOSE' });
                }}
            >
                {content}
            </Drawer>
        </DrawerContext.Provider>
    );
}

export const useDrawer = () => {
    const context = useContext(DrawerContext);
    if (!context) {
        throw new Error('useDrawer must be used within a DrawerProvider');
    }

    const { dispatch } = context;

    if (!dispatch) {
        throw new Error('useDrawer must be used within a DrawerProvider');
    }

    return {
        openDrawer: useCallback(
            (content: ReactNode) => {
                dispatch({ type: 'OPEN', content });
            },
            [dispatch]
        ),
        closeDrawer: useCallback(() => {
            dispatch({ type: 'CLOSE' });
        }, [dispatch])
    };
};
