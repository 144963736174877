import { createContext, useContext } from 'react';

import { IUpdateUserUsecase } from './domain/usecases/update_user_usecase';

const UpdateUserContext = createContext<IUpdateUserUsecase | undefined>(
    undefined
);

export function useUpdateUserUsecase() {
    const context = useContext(UpdateUserContext);
    if (context === undefined) {
        throw new Error('IUpdateUserUsecase not found');
    }
    return context;
}

export default UpdateUserContext;
