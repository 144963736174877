import { IHttpClient } from "core/protocols/http_client";
import ProfileEntity from "../entities/profile_entity";
import * as Sentry from '@sentry/react';

type CreateProfileInput = {
  authMethod: string,
  authUid: string,
  email: string,
  fullname?: string,
  profile: ProfileEntity
}

export interface ICreateProfileUsecase {
  execute(args: CreateProfileInput): Promise<any>;
}

export default class CreateProfileUsecaseImpl implements ICreateProfileUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async execute(args: CreateProfileInput): Promise<any> {
    try {
      const entity = args.profile;
      const payload = {
        auth_method: args.authMethod,
        auth_uid: args.authUid,
        cpf: entity.cpf,
        email: args.email,
        phone: entity.phone.replace(/\D/g, ''),
        state: "logged",
        fullname: args.fullname,
        have_ticket: false,
        created_at: new Date().toISOString(),
        birthday: args.profile.birthday,
        customer: {
          address: {
            city: entity.address.localidade,
            complement: entity.address.complemento,
            neighborhood: entity.address.bairro,
            name: entity.address.logradouro,
            number: entity.address.numero !== '' ? entity.address.numero : 'NA',
            state: entity.address.uf,
            zipcode: entity.address.cep
          },
          birthday: args.profile.birthday,
          cpf: entity.cpf,
          email: args.email,
          force_email: args.email,
          first_name: args.fullname!.split(' ')[0],
          last_name: args.fullname!.split(' ')[1],
          phone_number: entity.phone,
        }
      };
      const response = await this.httpClient.post<any>('/bff/v1/user', payload);
      return {
        ...payload,
        user_id: response.data.user_id
      };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          'feature.profile': "CreateProfileUsecaseImpl",
          'api.endpoint': '/bff/v1/user',
        },
      });
      throw error;
    }
  }
}
