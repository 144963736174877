import { useEffect, useState } from 'react'
import Footer from './components/Footer'
import FormCheckout from './components/FormCheckout'
import Header from './components/Header'
import Loader from './components/Loader'
import Failed from './Failed'
import { Sales } from './services/Backend'
import Decode from './services/JWT'

const CheckoutApp = () => {
    const infoDecode = Decode()
    const [loader, setLoader] = useState(false)
    const [statusSales, setStatusSales] = useState(null)
    const [statusPayment, setStatusPayment] = useState(null)

    const dataUrl = () => {
        // Captura o parâmetro data da URL
        let url_string = window.location.href;
        let url = new URL(url_string);
        const param = url.searchParams.get("data")

        return param !== null ? param : false
    }



    const sendSales = async (data, authToken) => {
        const dataSales = await Sales(data, authToken)

        if (dataSales.status) {
            setStatusPayment('payment_pending')
            setLoader(false)
        } else {
            setStatusSales(false)
            setLoader(false)
        }
    }

    const formatInvoiceDate = (e) => {
        const dateString = e;
        const date = new Date(dateString);

        const dia = date.getDate();
        const mes = date.getMonth() + 1;
        const ano = date.getFullYear();

        return `${ano}-${mes}-${dia}`;
    }

    const childToParent = (childdata) => {
        // Recupera os dados do formulário
        setLoader(true)
        const credCardName = childdata.creditCardUserName
        const month = splitStringDate(childdata.creditCardDateValid)[1]
        const year = splitStringDate(childdata.creditCardDateValid)[0]

        const newData = {
            creditCardNumber: childdata.creditCardNumber,
            creditCardName: credCardName,
            creditCardMonth: month,
            creditCardYear: year,
            creditCardCVV: childdata.creditCardCVV
        }

        const payload = {
            "mobileNumber": infoDecode.mobileNumber,
            "imei": infoDecode.imei,
            "transactionId": infoDecode.transactionId,
            "providerId": infoDecode.providerId,
            "payment": {
                "creditCard": {
                    "name": newData.creditCardName === undefined ? '' : newData.creditCardName,
                    "cardNumber": newData.creditCardNumber === undefined ? '' : newData.creditCardNumber,
                    "cvv": newData.creditCardCVV === undefined ? '' : newData.creditCardCVV,
                    "month": newData.creditCardMonth === undefined ? '' : newData.creditCardMonth,
                    "year": newData.creditCardYear === undefined ? '' : newData.creditCardYear
                }
            }
        };

        sendSales(
            payload,
            infoDecode.auth_token
        )
    }

    const splitStringDate = (date) => {
        return date !== undefined && date.split('-')
    }

    useEffect(() => {
        if (localStorage.getItem('success_payment') !== null) {
            window.location.href = "/success"
        }
    });

    if (loader) {
        return <Loader />
    }

    if (statusPayment === "active") {
        window.location.href = "/success"
    } else if (statusPayment === "payment_pending") {
        return (
            <div className="struct">
                <Header warning={true} text="Aguardando liberação de pagamento" />
                <Failed details='O seu pagamento está sendo processado, volte ao app e verifique o status em "Certificado de Seguro".' repeat_process={false} />
                <Footer />
            </div>
        )
    } else if (statusPayment !== null) {
        return (
            <div className="struct">
                <Header userName={`${infoDecode.customer.first_name} ${infoDecode.customer.last_name}`} error={true} text="não foi possível processar o pagamento." />
                <Failed details="Houve um erro ao processar a operação e sua assinatura não foi realizada. Tente novamente para finalizar o processo de proteção do seu celular." repeat_process={false} />
                <Footer />
            </div>
        )
    }

    if (statusSales === false) {
        return (
            // <div className="struct">
            //     <Header error={true} text='Erro genérico' />
            //     <Failed details="Desculpe, ocorreu um erro genérico, volte ao app e faça uma nova tentativa." repeat_process={false}/>
            //     <Footer />  
            // </div>

            <div className="struct">
                <Header userName={`${infoDecode.customer.first_name} ${infoDecode.customer.last_name}`} error={true} text="não foi possível processar o pagamento." />
                <Failed details="Houve um erro ao processar a operação e sua assinatura não foi realizada. Tente novamente para finalizar o processo de proteção do seu celular." repeat_process={false} />
                <Footer />
            </div>
        )
    }

    if (!infoDecode.status && infoDecode.status !== undefined) {
        return (
            <div className="struct">
                <Header error={infoDecode.error} text={infoDecode.data} />
                <Failed details={infoDecode.details} repeat_process={infoDecode.repeat_process} />
                <Footer />
            </div>
        )
    }

    return (
        <div className="struct">
            <Header userName={`${infoDecode.customer.first_name} ${infoDecode.customer.last_name}`} />
            <FormCheckout
                childToParent={childToParent}
                plan_name={infoDecode.product.name !== undefined ? infoDecode.product.name : ''}
                amount_plan={infoDecode.product.price !== undefined ? infoDecode.product.amount.toString().replace(".", ",") : ''}
            />
            <Footer />
        </div>
    )
}

export default CheckoutApp