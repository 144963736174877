import { createContext, useContext } from "react";

import { IImeiCheckUsecase } from "./domain/usecases/check_imei_usecase";

const ImeiCheckContext = createContext<IImeiCheckUsecase | undefined>(
  undefined
);

export function useImeiCheckUsecase() {
  const context = useContext(ImeiCheckContext);
  if (context === undefined) {
    throw new Error("ICheckImeiUsecase not found");
  }
  return context;
}

export default ImeiCheckContext;
