import { IHttpClient } from "core/protocols/http_client";

import { endpoint } from "constants/endpoints";
import BrandEntity from "../entities/brand_entity";
import * as Sentry from "@sentry/react";

export interface IGetBrandsByNameUsecase {
  execute(name: string): Promise<BrandEntity[]>;
}

export default class GetBrandsByNameUsecaseImpl
  implements IGetBrandsByNameUsecase
{
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

    async execute(name: string): Promise<BrandEntity[]> {
        try {
            const response = await this.httpClient.get<BrandEntity[]>(endpoint.brands + `?q=${name}`);
            return response.data;
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
                'feature.brand_and_model_search': "GetBrandsByNameUsecaseImpl",
                'api.endpoint': `?q=${name}`,
            }
        });
            throw error;
        }
    }
}
