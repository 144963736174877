import { createContext, useContext } from "react";
import { IGetUserUsecase } from "./domain/usecases/get_user_usecase";

export const GetUserContext = createContext<IGetUserUsecase | undefined>(undefined);

export function useGetUserUsecase(){
    const usecase = useContext(GetUserContext);
    if(!usecase){
        throw new Error("useGetUserByIdUsecase must be used within a GetUserContext.Provider");
    }
    return usecase;
}