import SendCodeByEmailContext from "./send_code_by_email_context";
import { SendCodeByEmailUsecaseImpl } from "./domain/usecases/send_code_by_email";
import EmailCheckPage from "./presenter/email_check_page";
import VerifyEmailCodeContext from "./verify_email_code_context";
import { VerifyEmailCodeUsecaseImpl } from "./domain/usecases/verify_email_code";
import { useHttpSalesClient } from "bindings/http_sales_binding";

function EmailCheck() {

    const httpClient = useHttpSalesClient();

    return (
        <SendCodeByEmailContext.Provider value={new SendCodeByEmailUsecaseImpl(httpClient)}>
            <VerifyEmailCodeContext.Provider value={new VerifyEmailCodeUsecaseImpl(httpClient)}>
                <EmailCheckPage />
            </VerifyEmailCodeContext.Provider>
        </SendCodeByEmailContext.Provider>
    );
}

export default EmailCheck;