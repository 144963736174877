import { FooterSection, HeaderSection, TryNowSection } from "components";

import BannerSection from "../../components/banner_section";
import CardsSection from "../../components/cards_section";
import SecurityPhoneExaSection from "./components/security_phone_exa_section";
import UrlApps from "./url_apps";
import Chatbot from "features/chatbot";


const LadingPage = () => {
  const urlApps: UrlApps = new UrlApps(
    "https://play.google.com/store/apps/details?id=br.com.exa.testefone.testefone",
    "https://apps.apple.com/br/app/testefone-seguros-e-testes/id6459995038",
  );

  return (
    <>
      <HeaderSection />
      <CardsSection />
      <SecurityPhoneExaSection />
      <BannerSection urlApps={urlApps}/>
      <TryNowSection urlApps={urlApps} />
      <FooterSection />
      <Chatbot/>
    </>
  );
};

export default LadingPage;
