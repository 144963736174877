import { createContext, useContext } from "react";

import { IGetModelsByBrandAndNameUsecase } from "./domain";

const GetModelsByBrandAndNameContext = createContext<
  IGetModelsByBrandAndNameUsecase | undefined
>(undefined);

export function useGetModelsByBrandAndNameUsecase() {
  const context = useContext(GetModelsByBrandAndNameContext);
  if (context === undefined) {
    throw new Error("IGetModelsByBrandAndNameUsecase not found");
  }
  return context;
}

export default GetModelsByBrandAndNameContext;
