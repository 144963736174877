import PolicyEntity from 'features/purchase/domain/entities/order_entity';
import PolicyDrawer from 'features/purchase/presenter/components/policy_drawer';
import {
    CardWrapper,
    IconArrowRightLight,
    IconLockOpen,
    ListTile,
    Text,
    useDrawer
} from 'gosafe';
import OutlinedButton from 'gosafe/molecules/buttons/outlined_button';

interface InactiveApoliceProps {
    onClick: () => void;
    policy: PolicyEntity;
}

const InactiveApoliceSection = ({ onClick, policy }: InactiveApoliceProps) => {
    const { openDrawer } = useDrawer();
    return (
        <div className="pt-20 flex justify-center items-center">
            <div className="flex flex-col md:flex-row md:space-x-32 space-y-4 md:space-y-0 p-4 mb-28">
                <div id="inactive-policy-title" className="flex flex-col">
                    <span className="text-primary font-medium font-sans text-4xl mb-8">
                        Seu seguro está inativo!
                    </span>
                    <CardWrapper className="p-4 mt-4 flex flex-col">
                        <div
                            id="inactive-policy"
                            className="pb-4 items-center border-md border-b-[.1px]"
                            onClick={() => {
                                openDrawer(<PolicyDrawer openStep={0} />);
                            }}
                        >
                            <ListTile
                                trealing={<IconLockOpen />}
                                leading={<IconArrowRightLight />}
                            >
                                <div className="flex flex-col pr-4">
                                    <Text
                                        color="text-neutral-10"
                                        fontSize="text-base"
                                        fontFamily="font-sans"
                                        fontWeight="font-medium"
                                    >
                                        {policy.planName ?? ''}
                                    </Text>
                                    <Text
                                        color="text-neutral-10"
                                        fontSize="text-sm"
                                        fontFamily="font-serif"
                                        fontWeight="font-normal"
                                    >
                                        Nº da apólice: {policy.policyNumber}
                                    </Text>
                                </div>
                            </ListTile>
                        </div>
                        <div>
                            <div className="tag-error">
                                <Text
                                    fontFamily="font-serif"
                                    color="text-neutral-10"
                                    fontSize="text-xs"
                                    fontWeight="font-bold"
                                >
                                    Inativo
                                </Text>
                            </div>
                        </div>
                    </CardWrapper>
                    <div className=" mt-8">
                        <OutlinedButton
                            text="Ativar seguro"
                            onClick={onClick}
                        />
                    </div>
                </div>
                <div className="flex flex-row items-center justify-center">
                    <img
                        id="inactive-policy-image"
                        width={300}
                        alt="Error"
                        src="/images/attention.png"
                    />
                </div>
            </div>
        </div>
    );
};

export default InactiveApoliceSection;
