import ProfileEntity from "features/profile/domain/entities/profile_entity";
import ProfileOutputType from "features/profile/domain/types/profile_output_type";


function initialValueEqualToOutputValue(initialValue: ProfileEntity, output: ProfileOutputType) {
  return initialValue?.cpf.replace(/\D/g, '') === output.cpf.replace(/\D/g, '') &&
    initialValue?.phone.replace(/\D/g, '') === output.phone.replace(/\D/g, '') &&
    initialValue?.birthday === output.birthday &&
    initialValue?.address?.cep?.replace(/\D/g, '') === output.address.zipcode?.replace(/\D/g, '') &&
    initialValue?.address?.logradouro === output.address.name &&
    initialValue?.address?.numero === output.address.number &&
    initialValue?.address?.complemento === output.address.complement &&
    initialValue?.address?.bairro === output.address.neighborhood &&
    initialValue?.address?.localidade === output.address.city &&
    initialValue?.address?.uf === output.address.uf;
}

export default initialValueEqualToOutputValue;