import PurchaseStepType from "features/purchase/presenter/purchase_step_type";
import { Text } from "gosafe/atoms";

interface DotStepperProps {
  steps?: number;
  currentStep?: PurchaseStepType;
  onClick?: (step: number) => void;
  align?: "end" | "start";
}

export default function DotStepper({
  steps = 3,
  currentStep = 'CHOOSE_PHONE',
  onClick,
  align = "end",
}: DotStepperProps) {

  function getIndex(step: PurchaseStepType) {
    switch (step) {
      case 'CHOOSE_PHONE':
        return 0;
      case 'CHOOSE_PRODUCT':
        return 1;
      case 'SET_PROFILE':
        return 2;
      default:
        return 0;
    }
  }

  return (
    <div data-testid="DotStepper">
      <div className={`flex gap-[10px] justify-${align} items-center`}>
        {Array.from({ length: steps }, (_, index) => {
          return (
            <div
              key={index}
              data-testid={`DotStepper-${index}`}
              className={`${getIndex(currentStep) === index ? "h-[10px] w-[10px]" : "h-[7px] w-[7px]"} cursor-pointer rounded-full ${getIndex(currentStep) === index ? "bg-blue" : "bg-blue-dark"}`}
              onClick={() => {
                if (onClick) {
                  onClick(index);
                }
              }}
            />
          );
        })}
      </div>
      <div className="p-1" />
      <Text fontWeight="font-bold" color="text-blue">
        <span className="text-sm">{`Etapa ${getIndex(currentStep) + 1} de ${steps}`}</span>
      </Text>
    </div>
  );
}
