import SendPaymentUseCaseImpl from "./domain/usecases/send_payment_usecase";
import CheckoutView from "./presenter/checkout_view";
import SendPaymentContext from "./send_payment_context";
import ProductEntity from "./domain/entities/product_entity";
import { useAuthentication } from "features/authentication/authentication_bindings";
import { useHttpSalesClient } from "bindings/http_sales_binding";

interface CheckoutProps {
    phoneNumber: string | undefined
    product: ProductEntity | undefined
    imei: string | undefined
    onSuccess?: () => void
    onError?: () => void
}

export default function Checkout({ phoneNumber, product, imei,  onSuccess, onError }: CheckoutProps) {

    const httpClient = useHttpSalesClient();

    const authState = useAuthentication();

    return (<SendPaymentContext.Provider value={new SendPaymentUseCaseImpl(httpClient, authState) }>
        <CheckoutView phoneNumber={phoneNumber} product={product} onSuccess={onSuccess} imei={imei} onError={onError} />
    </SendPaymentContext.Provider>);
}