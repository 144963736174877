import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { Button } from "gosafe";

interface DialogInfoProps {
    open: boolean;
    account: string;
    onClose: VoidFunction;
}

function DialogInfo({ open, account, onClose }: DialogInfoProps) {

    return (
        <Dialog open={open} as="div" className="relative z-10 focus:outline-none" onClose={onClose}>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                        transition
                        className="w-full max-w-md rounded-xl bg-neutral-100 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0 shadow-md"
                    >
                        <DialogTitle as="h3" className="font-medium">
                            Para tornar seu login mais simples e seguro, vinculamos seu acesso à sua conta {account}
                        </DialogTitle>
                        <p className="py-2 text-sm font-thin">
                            A partir de agora, utilize sua conta {account} para acessar detalhes da sua apólice.
                        </p>
                        <p className="py-2 text-sm font-thin">
                            Seus dados continuam protegidos e você pode acessar normalmente com um simples clique!
                        </p>
                        <div className="mt-4">
                            <Button
                                onClick={onClose}
                                infinity
                                backgroundColor="bg-blue-navy"
                                color="text-neutral-100"
                                fontSize="text-xs"
                                text="Entendi" />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default DialogInfo;