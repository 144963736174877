import { ReactNode, createContext, useContext, useEffect, useState } from "react";

import { IHttpClient } from "core/protocols/http_client";
import { HttpClient } from "core/protocols/http_client_impl";
import { useAuthentication } from "features/authentication/authentication_bindings";
import { useFeatureToggle } from "features/feature_toogle/feature_toggle_bindings";
import { useEncryptService } from "./encrypt_binding";

const HttpSalesContext = createContext<IHttpClient | undefined>(undefined);

interface HttpBindingProps {
  children: ReactNode;
}

export function useHttpSalesClient(): IHttpClient {
  const context = useContext(HttpSalesContext);
  if (context === undefined) {
    throw new Error("HttpSalesClient not found int context");
  }
  return context;
}

export default function HttpSalesBinding({ children }: HttpBindingProps) {
  let { accessToken } = useAuthentication();
  accessToken = accessToken ?? undefined; 

  const featureToggle = useFeatureToggle();
  const encryptService = useEncryptService();

  const [useEncryption, setUseEncryption] = useState<boolean | null>(null); // Inicialmente null para indicar carregamento

  useEffect(() => {
    const fetchFeatureToggle = async () => {
      const isEnabled = await featureToggle.isEnabled("encrypt_body");
      setUseEncryption(isEnabled);
    };

    fetchFeatureToggle();
  }, [featureToggle]);


  if (useEncryption === null) {
    return null;
  }
  
  return (
    <HttpSalesContext.Provider
      value={
        new HttpClient({
          baseUrl: process.env.REACT_APP_SALES_URL as string,
          accessToken: accessToken,
          useEncryption: useEncryption,
          encryptService: encryptService}
        )
      }
    >
      {children}
    </HttpSalesContext.Provider>
  );
}
