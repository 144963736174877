class AddressEntity {
  cep?: string;
  logradouro?: string;
  bairro?: string;
  numero?: string;
  complemento?: string;
  localidade?: string;
  uf?: string;

  constructor(
    args: {
      cep?: string,
      logradouro?: string,
      numero?: string,
      bairro?: string,
      complemento?: string,
      localidade?: string,
      uf?: string
    }
  ) {
    this.cep = args.cep;
    this.logradouro = args.logradouro;
    this.numero = args.numero;
    this.complemento = args.complemento;
    this.bairro = args.bairro;
    this.localidade = args.localidade;
    this.uf = args.uf;
  }
}

export default AddressEntity;
