import { createContext, useContext } from 'react';
import { IGetAllOrdersUseCase } from './domain/usecases/get_all_orders_use_case';

const GetAllOrdersContext = createContext<IGetAllOrdersUseCase | undefined>(
    undefined
);

export function useGetAllOrdersContext() {
    const context: IGetAllOrdersUseCase | undefined =
        useContext(GetAllOrdersContext);
    if (context === undefined) {
        throw new Error('GetAllOrdersContext not found');
    }
    return context;
}

export default GetAllOrdersContext;
