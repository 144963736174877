import OrderEntity from 'features/purchase/domain/entities/order_entity';
import { Button, CardWrapper, Text } from 'gosafe';
import OutlinedButton from 'gosafe/molecules/buttons/outlined_button';

export interface InsuranceCardDrawerProps {
    order: OrderEntity;
}

function InsuranceCardDrawer({ order }: InsuranceCardDrawerProps) {

    return (
        <div id="policy-card-drawer" className="flex flex-col">

            <CardWrapper className="p-4 mt-4 flex flex-col">
                <Text
                    color="text-neutral-10"
                    fontSize="text-base"
                    fontFamily="font-serif"
                    fontWeight="font-bold"
                >
                    {order.planName ?? ''}
                </Text>
                <Text
                    color="text-neutral-10"
                    fontSize="text-sm"
                    fontFamily="font-serif"
                    fontWeight="font-normal"
                >
                    Seguro de celular
                </Text>
                <div className="pb-4 items-center border-md border-b-[.5px]" />
                <div>
                    <div
                        className={`${order.state === 'active' ? 'tag' : 'tag-error'}`}
                    >
                        <Text
                            fontFamily="font-serif"
                            color="text-neutral-10"
                            fontSize="text-xs"
                            fontWeight="font-bold"
                        >
                            {order.state === 'active' ? 'Ativo' : 'Inativo'}
                        </Text>
                    </div>
                </div>
            </CardWrapper>
            <CardWrapper className="p-4 mt-4 flex flex-col">
                <Text
                    color="text-neutral-10"
                    fontSize="text-base"
                    fontFamily="font-serif"
                    fontWeight="font-bold"
                >
                    {order.modelName}
                </Text>
                <Text
                    color="text-neutral-10"
                    fontSize="text-sm"
                    fontFamily="font-serif"
                    fontWeight="font-normal"
                >
                    Celular protegido
                </Text>
            </CardWrapper>
            <CardWrapper className="p-4 mt-4 flex flex-col">
                <Text
                    color="text-neutral-10"
                    fontSize="text-base"
                    fontFamily="font-serif"
                    fontWeight="font-bold"
                >
                    Número da apólice
                </Text>
                <Text
                    color="text-neutral-10"
                    fontSize="text-sm"
                    fontFamily="font-serif"
                    fontWeight="font-normal"
                >
                    {order.policyNumber}
                </Text>
            </CardWrapper>
            <CardWrapper className="p-4 mt-4 flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                        <Text
                            color="text-neutral-10"
                            fontSize="text-base"
                            fontFamily="font-serif"
                            fontWeight="font-bold"
                        >
                            {new Date(order.startValidity).toLocaleDateString(
                                'pt-BR'
                            )}
                        </Text>
                        <Text
                            color="text-neutral-10"
                            fontSize="text-sm"
                            fontFamily="font-serif"
                            fontWeight="font-normal"
                        >
                            Início de contrato
                        </Text>
                    </div>
                    <div className="flex flex-col">
                        <Text
                            color="text-neutral-10"
                            fontSize="text-base"
                            fontFamily="font-serif"
                            fontWeight="font-bold"
                        >
                            {new Date(order.endValidity).toLocaleDateString(
                                'pt-BR'
                            )}
                        </Text>
                        <Text
                            color="text-neutral-10"
                            fontSize="text-sm"
                            fontFamily="font-serif"
                            fontWeight="font-normal"
                        >
                            Fim de contrato
                        </Text>
                    </div>
                </div>
            </CardWrapper>
            <div className="flex flex-col my-6">
                {order.state === 'active' ? (
                    <div className="mb-4">
                        <OutlinedButton
                            text="Acessar Certificado"
                            onClick={() => {
                                window.open(order.policyUrl, '_blank');
                            }}
                        />
                    </div>
                ) : (
                    <div className="mb-4">
                        <OutlinedButton
                            text="Ativar seguro"
                            onClick={() => { }}
                        />
                    </div>
                )}
                <Button
                    text="Solicitar sinistro"
                    type="button"
                    enabled={false}
                    backgroundColor="bg-primary"
                    color="text-neutral-100"
                    padding="py-5 px-6"
                    onClick={() => { }}
                />
            </div>
        </div >
    );
}
export default InsuranceCardDrawer;
