import * as Sentry from '@sentry/react';
import OrderEntity from 'features/purchase/domain/entities/order_entity';
import { useGetAllOrdersContext } from 'features/purchase/get_all_orders_context';

import { useEffect, useState } from 'react';

export function useProfileCertificateController() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [certificates, setCertificates] = useState<OrderEntity[] | null>(
        null
    );

    const getTickets = useGetAllOrdersContext();

    useEffect(() => {
        getTickets
            .execute()
            .then((value) => {
                setCertificates(value.data);
            })
            .catch((error) => {
                Sentry.captureException(error, {
                    tags: {
                        'feature.purchase.profile':
                            'useProfileCertificateController'
                    }
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return {
        isLoading,
        certificates
    };
}
