export default function IconPhone() {
  return (
    <svg
      width="20"
      height="30"
      viewBox="0 0 20 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 0.346829L3.33341 0.333496C1.86675 0.333496 0.666748 1.5335 0.666748 3.00016V27.0002C0.666748 28.4668 1.86675 29.6668 3.33341 29.6668H16.6667C18.1334 29.6668 19.3334 28.4668 19.3334 27.0002V3.00016C19.3334 1.5335 18.1334 0.346829 16.6667 0.346829ZM16.6667 24.3335H3.33341V5.66683H16.6667V24.3335Z"
        fill="currentColor"
      />
    </svg>
  );
}
