import { ReactNode } from "react";

import { IHttpClient } from "core/protocols/http_client";

import CheckImeiUsecaseImpl from "./domain/usecases/check_imei_usecase";
import CheckImeiContext from "./imei_check_context";

interface ImeiCheckBindingsProps {
  children: ReactNode;
  httpClient: IHttpClient;
}

export default function ImeiCheckBindings({
  children,
  httpClient,
}: ImeiCheckBindingsProps) {
  return (
    <>
      <CheckImeiContext.Provider value={new CheckImeiUsecaseImpl(httpClient)}>
        {children}
      </CheckImeiContext.Provider>
    </>
  );
}
