import { useEffect, useState } from "react";

import styles from "./styles.module.css";

export default function SplashPage() {
  const [loadPage, setLoadPage] = useState(true);

  useEffect(() => {
    return setLoadPage(false);
  }, []);

  return <>{loadPage && <div className={styles.loading}>Loading</div>}</>;
}
