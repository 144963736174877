
import { ISignIn } from "core/authentication/sign_in";
import { Divider } from "gosafe/atoms";
import { IconSignInApple, IconSignInGoogle } from "gosafe/atoms/icons";
import { BackButton } from "gosafe/molecules/buttons";
import { ButtonOption, MyOptionsTitle } from "./my_options";
import { useTesteFoneController } from "./testefone_view_controller";

interface SignInPageProps {
  signWithGoogle: ISignIn;
  signWithApple: ISignIn;
  signWithEmailAndPassword: ISignIn;
}

function TesteFoneViwer({signWithGoogle, signWithApple, signWithEmailAndPassword}: SignInPageProps) {
  const {
    showBackButton,
    isLoginEmailPassword,
    setIsLoginEmailPassword,
    email,
    setEmail,
    password,
    setPassword,
    errorMessage,
    setErrorMessage,
    AuthGoogle,
    AuthApple,
    navigateTransferDevice,
    handleEmailLogin
  } = useTesteFoneController({signWithGoogle, signWithApple, signWithEmailAndPassword});

  return (
    <div className="content-center pb-16">
      <div className="hidden md:inline">
        <div className="py-10">
          {
            showBackButton && <BackButton
                  onClick={() => {
                    window.history.back();
                  }}
              />
          }
        </div>
      </div>
      <div className="mt-10 md:mt-0">
        <MyOptionsTitle/>
      </div>
      <Divider y={50} />
      {!isLoginEmailPassword &&
          <div className="flex justify-center">
              <div className="flex flex-col gap-[16px]">
                  <ButtonOption
                      id="btn-login-google"
                      onClick={AuthGoogle}
                      icon={<IconSignInGoogle/>}
                      text="Entrar com Google"
                  />
                  <ButtonOption
                      id="btn-login-apple"
                      onClick={AuthApple}
                      icon={<IconSignInApple/>}
                      text="Entrar com Apple"
                  />
                  <div className="flex justify-center">
                      <a type="button" onClick={navigateTransferDevice} className="text-primary underline cursor-pointer">Continuar em outro
                          aparelho</a>
                  </div>
              </div>
              <div className="col-span-1"/>
          </div>
      }

      {isLoginEmailPassword && (
        <div className="p-6 bg-dark rounded-lg shadow-md">
          <h2 className="backdrop text-xl font-bold mb-4">Login com Email</h2>
          <input
            id="email"
            data-test="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full p-2 mb-4 border rounded"
          />
          <input
            id="password"
            type="password"
            data-test="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Senha"
            className="w-full p-2 mb-4 border rounded"
          />

          {errorMessage && <p className="text-error p-3 ">{errorMessage}</p>}

          <button
            id="btn-login"
            onClick={handleEmailLogin}
            className="w-full p-2 bg-blue text-white rounded"
          >
            Login
          </button>
          <button
            id="btn-cancel"
            onClick={() => {
              setIsLoginEmailPassword(false);
              setErrorMessage("");
            }}
            className="w-full p-2 bg-grey text-white rounded"
          >
            Cancelar
          </button>
        </div>
      )}
    </div>
  );
}

export default TesteFoneViwer;
