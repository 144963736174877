import { Text } from "gosafe/atoms";

interface CardPostProps {
  state?: "default" | "houver";
  title?: string;
  image?: string;
  showBodyText?: boolean;
  bodyText?: string;
  showAuthorName?: boolean;
  authorName?: string;
}

const CardPost = ({
  image = "",
  title = "Title",
  showBodyText = false,
  bodyText = "Description",
}: CardPostProps) => {
  const className = [
    "bg-paper",
    "max-w-sm",
    "rounded-3xl",
    "overflow-hidden",
    "shadow-lg",
    "shadow-tm-shadow-blur-neutral",
    "m-1",
    "p-tm-spacing-x6",
    "flex-grow",
  ];

  return (
    <div className={className.join(" ")}>
      {image && <img className="w-full" alt="" src={image} />}

      <div className="grid gap-3 p-4">
        <div className="w-full text-xl">
          <Text
            color="text-neutral-20"
            colorDark="text-neutral-80"
            fontWeight="font-medium"
          >
            {title}
          </Text>
        </div>
        {showBodyText && <p className="text-xs text-neutral-20">{bodyText}</p>}
      </div>
    </div>
  );
};

export default CardPost;
