import { createContext, useContext } from "react";
import { ICheckDetachedOrderUsecase } from "./domain/usecases/check_detached_order";


const CheckDetachedOrderContext = createContext<ICheckDetachedOrderUsecase | undefined>(undefined);

export function useCheckDetachedOrderUsecase(){
    const usecase = useContext(CheckDetachedOrderContext);
    if(!usecase){
        throw new Error("useCheckDetachedOrderUsecase must be used within a CheckDetachedOrderContext.Provider");
    }
    return usecase;
}

export default CheckDetachedOrderContext;