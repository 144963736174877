class UrlApps {
  playStore: string;
  appleStore: string;

  constructor(playStore: string, appleStore: string) {
    this.playStore = playStore;
    this.appleStore = appleStore;
  }
}

export default UrlApps;
