export default function IconPadLock() {
  return (
    <svg
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2666 17.0998C12.2666 17.9388 11.8107 18.6714 11.1332 19.0633V20.3665C11.1332 20.9924 10.6258 21.4998 9.99987 21.4998C9.37395 21.4998 8.86654 20.9924 8.86654 20.3665V19.0632C8.18905 18.6713 7.73324 17.9388 7.73324 17.0998C7.73324 15.848 8.74806 14.8332 9.9999 14.8332C11.2517 14.8332 12.2666 15.848 12.2666 17.0998Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 0.166504C7.05432 0.166504 4.6665 2.55432 4.6665 5.49984V10.8642C2.41415 11.1282 0.666504 13.0432 0.666504 15.3665V22.2998C0.666504 24.8035 2.69615 26.8332 5.19984 26.8332H14.7998C17.3035 26.8332 19.3332 24.8035 19.3332 22.2998V15.3665C19.3332 13.0432 17.5855 11.1282 15.3332 10.8642V5.49984C15.3332 2.55432 12.9454 0.166504 9.99984 0.166504ZM13.0665 10.8332V5.49984C13.0665 3.80616 11.6935 2.43317 9.99984 2.43317C8.30616 2.43317 6.93317 3.80616 6.93317 5.49984V10.8332H13.0665ZM2.93317 15.3665C2.93317 14.1147 3.94799 13.0998 5.19984 13.0998H14.7998C16.0517 13.0998 17.0665 14.1147 17.0665 15.3665V22.2998C17.0665 23.5517 16.0517 24.5665 14.7998 24.5665H5.19984C3.94799 24.5665 2.93317 23.5517 2.93317 22.2998V15.3665Z"
        fill="#121212"
      />
    </svg>
  );
}
