function IconArrowLeftLigth() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39106 13.8047C5.0923 13.5444 5.0923 13.1223 5.39107 12.8619L10.9703 8.00011L5.39106 3.13807C5.0923 2.87771 5.09231 2.4556 5.39107 2.19526C5.68983 1.93491 6.17422 1.93492 6.47297 2.19527L12.0522 7.05732C12.6497 7.57802 12.6497 8.42224 12.0522 8.94293L6.47296 13.8047C6.1742 14.0651 5.68981 14.0651 5.39106 13.8047Z"
        fill="#004BB8"
      />
    </svg>
  );
}

export default IconArrowLeftLigth;
