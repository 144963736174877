import { useNavigate } from 'react-router-dom';

import { useHttpClient } from 'bindings/http_binding';
import { useHttpSalesClient } from 'bindings/http_sales_binding';
import { BrandAndModelFormSearch } from 'features';
import { Text } from 'gosafe/atoms';

const QuoteInsuranceForm = () => {
    const navigate = useNavigate();
    const httpClient = useHttpClient();
    const httpSalesClient = useHttpSalesClient();

    return (
        <div>
            <div className="rounded-xl bg-neutral-90 p-10">
                <img
                    className="content-center"
                    src="/images/quote_insurance_banner.svg"
                    alt=""
                />
                <div className="h-5" />
                <Text
                    color="text-primary"
                    fontWeight="font-medium"
                    fontSize="text-xl"
                >
                    Cote seu seguro em 3 passos. <br />É rápido, fácil e 100%
                    digital
                </Text>
                <div className="h-4" />
                <Text fontSize="text-sm" color="text-neutral-30">
                    Selecione a marca e o modelo do seu celular para continuar
                </Text>
                <div className="h-4" />
                <BrandAndModelFormSearch
                    httpClient={httpClient}
                    httpSalesClient={httpSalesClient}
                    onTap={(brand, model) => {
                        if (brand !== undefined && model !== undefined) {
                            const purchase = {
                                phone: model,
                                currentStep: 'CHOOSE_PRODUCT'
                            };
                            navigate('/purchase', { state: { purchase } });
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default QuoteInsuranceForm;
