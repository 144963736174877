import { createContext, useContext } from "react";

import { IUpdateProfileUsecase } from "./domain/usecases/update_profile_usecase";

const UpdateProfileContext = createContext<IUpdateProfileUsecase | undefined>(
  undefined
);

export function useUpdateProfileUsecase() {
  const context = useContext(UpdateProfileContext);
  if (context === undefined) {
    throw new Error("IUpdateProfileUsecase not found");
  }
  return context;
}

export default UpdateProfileContext;
