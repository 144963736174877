export class ShortUrlResponseEntity {
  shortUrl: string;
  hash: string;
  
  constructor(args: {
    hash_url: string;
    hash: string;
  }) {
    this.shortUrl = args.hash_url;
    this.hash = args.hash;
  }
}