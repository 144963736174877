export default function IconArrowLeft() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0235 2.56345C9.41087 2.1677 9.41087 1.52606 9.0235 1.13031C8.63613 0.734559 8.00808 0.734559 7.62071 1.13031L1.19653 7.69348C0.490153 8.41514 0.490153 9.58519 1.19653 10.3068L7.62071 16.87C8.00808 17.2658 8.63613 17.2658 9.0235 16.87C9.41087 16.4743 9.41087 15.8326 9.0235 15.4369L3.71502 10.0135L18.3415 10.0135C18.8893 10.0135 19.3334 9.55984 19.3334 9.00016C19.3334 8.44049 18.8893 7.98678 18.3415 7.98678L3.71502 7.98678L9.0235 2.56345Z"
        fill="currentColor"
      />
    </svg>
  );
}
