import {Text} from "gosafe/atoms";

export function QrCodeSubtitle() {
    return (
        <Text color="text-neutral-40" fontSize="text-base">
            <span className="block text-center text-2xl" data-testid="QrCodeSubTitle">
                Aponte a câmera do seu celular para ler o código abaixo
            </span>
        </Text>
    );
} 