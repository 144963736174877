import forge from 'node-forge';

export interface IEncryptRsaService {
    encrypt(data: string): string;
    decrypt(data: string): string;
}

class EncryptRsaServiceImpl implements IEncryptRsaService {
    private publicKeyPem = '';
    private privateKeyPem = '';
    private readonly encryptionScheme = 'RSA-OAEP';

    constructor(
        args: { publicKeyPem: string, privateKeyPem?: string }
    ) {
        this.publicKeyPem = args.publicKeyPem;

        if (args.privateKeyPem) {
            this.privateKeyPem = args.privateKeyPem;
        } else {
            this.privateKeyPem = args.publicKeyPem;
        }
    }

    private parsePublicKey() : forge.pki.rsa.PublicKey {
        return forge.pki.publicKeyFromPem(this.publicKeyPem);
    }

    private parsePrivateKey() : forge.pki.rsa.PrivateKey {
        return forge.pki.privateKeyFromPem(this.privateKeyPem);
    }

    encrypt(data: string): string {

        return forge.util.encode64(this.parsePublicKey().encrypt(data, this.encryptionScheme));
    }
    decrypt(data: string): string {
        return this.parsePrivateKey().decrypt(forge.util.decode64(data), this.encryptionScheme);
    }

}

export default EncryptRsaServiceImpl;