

class CreditCardEntity {
    private number: string;
    private name: string;
    private expirationDate: string;
    private cvv: string;

    constructor(args: {
        number: string,
        name: string,
        expirationDate: string,
        cvv: string
    }) {
        this.number = args.number;
        this.name = args.name;
        this.expirationDate = args.expirationDate;
        this.cvv = args.cvv;
    }

    toMap() {
        const [month, year] = this.expirationDate.split('/');
        return {
            cardNumber: this.number,
            name: this.name.trim(),
            month: month,
            year: new Date().getFullYear().toString().slice(0, 2) + year,
            cvv: this.cvv,
        };
    }
}

export default CreditCardEntity;