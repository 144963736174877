import { ReactNode } from "react";

import { IHttpClient } from "core/protocols/http_client";
import { GetAddressByCepUsecaseImpl } from "features/profile/domain/usecases/get_address_by_cep_usecase";
import GetAddressByCepContext from "features/profile/get_address_cep_context";

import UpdateProfileUsecaseImpl from "./domain/usecases/update_profile_usecase";
import UpdateProfileContext from "./update_profile_context";
import CreateProfileContext from "./create_profile_context";
import CreateProfileUsecaseImpl from "./domain/usecases/create_profile_usecase";
import SearchByCpfContext from "./search_by_cpf_context";
import SearchByCpfUsecaseImpl from "./domain/usecases/search_by_cpf_usecase";

interface ProfileBindingsProps {
  children: ReactNode;
  httpClient: IHttpClient;
}

export default function ProfileBindings({
  children,
  httpClient,
}: ProfileBindingsProps) {
  return (
    <>
      <GetAddressByCepContext.Provider
        value={new GetAddressByCepUsecaseImpl(httpClient)}
      >
        <UpdateProfileContext.Provider
          value={new UpdateProfileUsecaseImpl(httpClient)}
        >

          <CreateProfileContext.Provider value={new CreateProfileUsecaseImpl(httpClient)}>
            <SearchByCpfContext.Provider value={new SearchByCpfUsecaseImpl(httpClient)}>
              {children}
            </SearchByCpfContext.Provider>
          </CreateProfileContext.Provider>
        </UpdateProfileContext.Provider>
      </GetAddressByCepContext.Provider>
    </>
  );
}
