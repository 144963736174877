interface DividerProps {
  y?: number;
}

export default function Divider({ y = 2 }: DividerProps) {
  const style = {
    margin: `${y}px 0`,
  };

  return <div style={style} />;
}
