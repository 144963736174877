import QRCode from "react-qr-code";
import { BackButton, Divider } from "../../../../gosafe";
import { QrCodeSubtitle } from "./qr_code_subtitle";
import { QrCodeTitle } from "./qr_code_title";

function QrCodeView(props: { url: string }) {
  return (
    <div className="content-center pb-16">
      <div className="hidden md:inline">
        <div className="py-10">
          <BackButton data-testid="BackButton"
            onClick={() => {
              window.history.back();
            }}
          />
        </div>
      </div>
      <div className="mt-10 md:mt-0">
        <QrCodeTitle/>
      </div>
      <div className="mt-10 md:mt-0">
        <QrCodeSubtitle/>
      </div>
      <Divider y={50}/>
      <div className="flex justify-center" data-testid="QRCode">
        <QRCode data-testid="QRCodeSVG" value={props.url}/>
      </div>
    </div>
  );
}

export default QrCodeView;