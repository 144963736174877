function IconCheck() {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9998 27.3337C21.3636 27.3337 27.3332 21.3641 27.3332 14.0003C27.3332 6.63656 21.3636 0.666992 13.9998 0.666992C6.63607 0.666992 0.666504 6.63656 0.666504 14.0003C0.666504 21.3641 6.63607 27.3337 13.9998 27.3337ZM22.0553 10.2695C22.4824 9.81169 22.4576 9.09416 21.9998 8.66699C21.5421 8.23975 20.8248 8.26465 20.3977 8.72249L11.4048 18.378C11.3778 18.4069 11.3319 18.4062 11.3058 18.3765L6.65332 13.0569C6.24105 12.5855 5.5249 12.5378 5.05371 12.95C4.58252 13.3624 4.53467 14.0787 4.94694 14.55L9.59953 19.8696C10.5096 20.9102 12.1195 20.936 13.0625 19.9251L22.0553 10.2695Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconCheck;
