import { MouseEventHandler, ReactNode } from "react";

import { BackgroundColor, TextColor } from "gosafe/themes";

export interface ButtonProps {
  id?: string;
  backgroundColor?: BackgroundColor;
  disabledBackgroundColor?: BackgroundColor;
  color?: TextColor;
  disabledColor?: TextColor;
  text?: string;
  onClick?: MouseEventHandler;
  children?: ReactNode;
  infinity?: boolean;
  enabled?: boolean;
  showIconRight?: boolean;
  iconRight?: ReactNode;
  showIconLeft?: boolean;
  iconLeft?: ReactNode;
  hasBorder?: boolean;
  padding?: string;
  borderRadius?: "rounded-xl" | "rounded-2xl" | "rounded-3xl";
  aligment?: "justify-center" | "justify-between";
  fontFamily?: string;
  fontWeigth?: string;
  fontSize?: string;
  type?: "button" | "submit" | "reset";
}

function Button({
  id,
  backgroundColor = "bg-neutral-100",
  disabledBackgroundColor = "bg-neutral-70",
  color = "text-primary",
  disabledColor = "text-neutral-40",
  text,
  onClick,
  infinity = false,
  enabled = true,
  showIconRight = false,
  iconRight,
  showIconLeft = false,
  iconLeft,
  hasBorder = false,
  aligment = "justify-center",
  padding = "px-[24px] py-[10px]",
  borderRadius = "rounded-xl",
  fontFamily = "font-sans",
  fontWeigth = "font-medium",
  fontSize = "text-[.925rem]",
  type = "button",
}: ButtonProps) {
  const className = [
    "flex",
    "items-center",
    fontFamily,
    fontWeigth,
    aligment,
    "gap-[5px]",
    "y-[16px]",
    fontSize,
    "leading-[16px]",
    borderRadius,
    padding,
  ];

  if (infinity) {
    className.push("w-full");
  }

  if (hasBorder) {
    className.push("border-2");
  }

  if (enabled) {
    className.push(backgroundColor);
    className.push(color);
  } else {
    className.push(disabledBackgroundColor);
    className.push(disabledColor);
  }

  return (
    <button
      id={id}
      type={type}
      className={className.join(" ")}
      onClick={enabled ? onClick : undefined}
      title={text}
      data-testid={id}
    >
      {showIconLeft && iconLeft}
      {text}
      {showIconRight && iconRight}
    </button>
  );
}

export default Button;
