class PhoneInfoEntity {
    phone: string;
    imei: string;
    brand: string;
    model: string;

    constructor(phone: string, imei: string, brand: string, model: string) {
        this.phone = phone;
        this.imei = imei;
        this.brand = brand;
        this.model = model;
    }
}

export default PhoneInfoEntity;
