import { IHttpClient } from "core/protocols/http_client";
import * as Sentry from "@sentry/react";

export interface ICheckDetachedOrderUsecase {
    execute(cpf: string): Promise<boolean>;
}

export class CheckDetachedOrderUsecaseImpl implements ICheckDetachedOrderUsecase {
    private httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }

    async execute(cpf: string): Promise<boolean> {
        const url = `/orders/platform?customerId=${cpf}`;
        return this.httpClient.get<boolean>(url)
            .then((result) => {
                return result.data;
            })
            .catch(error => {
                Sentry.captureException(error, {
                    tags: {
                        'feature.cpf_capture': "CheckDetachedOrderUsecaseImpl",
                        'api.endpoint': url,
                    },
                });
                return false;
            });
    }
}