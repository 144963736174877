import { createContext, useContext } from 'react';
import { IGetLastPolicyUseCase } from './domain/usecases/get_last_policy_use_case';

const GetLastPolicyContext = createContext<IGetLastPolicyUseCase | undefined>(
    undefined
);
export function useGetLastPolicyContext() {
    const context = useContext(GetLastPolicyContext);
    if (context === undefined) {
        throw new Error('GetLastPolicyContext not found');
    }
    return context;
}

export default GetLastPolicyContext;
