import { FloatingButton, IconChatBubble } from "gosafe";
import { useState } from "react";

interface ChatbotProps {
    fullScreen?: boolean,
    primary?: boolean
}

function Chatbot({ fullScreen = false, primary = true }: ChatbotProps) {

    const [open, setOpen] = useState(false);

    if (fullScreen) {
        return (
            <iframe
                id="chatbot"
                title="Chatbot"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none"
                }}
                src="https://fs-chatbot-orquestrador.web.app?p=testefone"
            ></iframe>
        );
    }

    if (open) {
        return (<div className="fixed z-10 bottom-[10px] right-[10px] w-[400px] h-[665px] bg-neutral-100 rounded-xl shadow-2xl">

            <div className="flex justify-end px-4 pt-2"><button className="font-thin text-xs"
                onClick={() => {
                    setOpen(false);
                }}>FECHAR</button></div>
            <iframe
                id="chatbot"
                className="rounded-2xl"
                title="Chatbot"
                width="100%"
                height="97%"
                src="https://fs-chatbot-orquestrador.web.app?p=testefone"
            ></iframe>
        </div>);
    }

    return (<FloatingButton
        id="btn-chatbot"
        color={primary ? "bg-secondary" : "bg-tertiary"}
        onClick={() => {
            setOpen(true);
        }} ><div className={primary ? "text-primary" : "text-neutral-100"}><IconChatBubble /></div></FloatingButton>);
}

export default Chatbot;