import { createContext, useContext } from "react";
import { IValidadeCodeUsecase } from "./domain/usecases/validate_code_usecase";


const ValidateCodeContext = createContext<IValidadeCodeUsecase|undefined>(undefined);

export function useValidateCodeUsecase(): IValidadeCodeUsecase {
    const context = useContext(ValidateCodeContext);
    if (context === undefined) {
        throw new Error("ValidateCodeUsecase not found int context");
    }
    return context;
}

export default ValidateCodeContext;