export default function IconMastercard() {
    return (
        <svg
            width="40"
            height="24"
            viewBox="0 0 40 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1529 20.7681C18.1102 22.5247 15.4605 23.5852 12.5651 23.5852C6.10451 23.5852 0.867188 18.3055 0.867188 11.7926C0.867188 5.27973 6.10451 0 12.5651 0C15.4605 0 18.1102 1.06045 20.1529 2.81709C22.1956 1.06045 24.8453 0 27.7407 0C34.2013 0 39.4386 5.27973 39.4386 11.7926C39.4386 18.3055 34.2013 23.5852 27.7407 23.5852C24.8453 23.5852 22.1956 22.5247 20.1529 20.7681Z"
                fill="#ED0006"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1523 20.7681C22.6675 18.6051 24.2624 15.3866 24.2624 11.7926C24.2624 8.19858 22.6675 4.98007 20.1523 2.81709C22.195 1.06045 24.8448 0 27.7402 0C34.2007 0 39.4381 5.27973 39.4381 11.7926C39.4381 18.3055 34.2007 23.5852 27.7402 23.5852C24.8448 23.5852 22.195 22.5247 20.1523 20.7681Z"
                fill="#F9A000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.153 20.7683C22.6682 18.6053 24.263 15.3868 24.263 11.7928C24.263 8.19885 22.6682 4.98036 20.153 2.81738C17.6378 4.98036 16.043 8.19885 16.043 11.7928C16.043 15.3868 17.6378 18.6053 20.153 20.7683Z"
                fill="#FF5E00"
            />
        </svg>
    );
}
