import BrandEntity from "./brand_entity";

class PhoneEntity {
  brand: BrandEntity;
  deviceValue: number;
  id: number;
  identifiers: string[];
  name: string;
  storage: string;

  constructor(
    brand: BrandEntity,
    deviceValue: number,
    id: number,
    identifiers: string[],
    name: string,
    storage: string
  ) {
    this.brand = brand;
    this.deviceValue = deviceValue;
    this.id = id;
    this.identifiers = identifiers;
    this.name = name;
    this.storage = storage;
  }
}

export default PhoneEntity;
