export default function IconLockOpen() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.268 19.6003C18.268 20.4393 17.8122 21.1719 17.1347 21.5638V22.867C17.1347 23.4929 16.6273 24.0003 16.0013 24.0003C15.3754 24.0003 14.868 23.4929 14.868 22.867V21.5637C14.1905 21.1718 13.7347 20.4393 13.7347 19.6003C13.7347 18.3485 14.7495 17.3337 16.0014 17.3337C17.2532 17.3337 18.268 18.3485 18.268 19.6003Z"
                fill="#004BC1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3346 13.3647C23.587 13.6287 25.3346 15.5437 25.3346 17.867V24.8003C25.3346 27.304 23.305 29.3337 20.8013 29.3337H11.2013C8.69761 29.3337 6.66797 27.304 6.66797 24.8003V17.867C6.66797 15.3633 8.69761 13.3337 11.2013 13.3337H19.068V8.00033C19.068 6.30665 17.695 4.93366 16.0013 4.93366C14.3076 4.93366 12.9346 6.30665 12.9346 8.00033V9.33366H10.668V8.00033C10.668 5.05481 13.0558 2.66699 16.0013 2.66699C18.9468 2.66699 21.3346 5.05481 21.3346 8.00033V13.3647ZM8.93464 17.867C8.93464 16.6151 9.94946 15.6003 11.2013 15.6003H20.8013C22.0531 15.6003 23.068 16.6151 23.068 17.867V24.8003C23.068 26.0522 22.0531 27.067 20.8013 27.067H11.2013C9.94946 27.067 8.93464 26.0522 8.93464 24.8003V17.867Z"
                fill="#004BC1"
            />
        </svg>
    );
}
