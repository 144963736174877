import { useHttpClient } from 'bindings/http_binding';
import { useHttpSalesClient } from 'bindings/http_sales_binding';
import { DrawerProvider } from 'gosafe';
import PurchasePage from './presenter/purchase_page';
import PurchaseBinding from './purchase_binding';

function Purchase() {
    const httpClient = useHttpClient();
    const httpSalesClient = useHttpSalesClient();

    return (
        <PurchaseBinding
            httpClient={httpClient}
            httpSalesClient={httpSalesClient}
        >
            <DrawerProvider>
                <PurchasePage />
            </DrawerProvider>
        </PurchaseBinding>
    );
}

export default Purchase;
