import { PhoneEntity } from 'features/brand_and_model_search';
import { CardWrapper, Divider, ListTile, Text } from 'gosafe/atoms';
import {
    IconCheck,
    IconClose,
    IconPadlock,
    IconPhone
} from 'gosafe/atoms/icons';
import { Button } from 'gosafe/molecules/buttons';
import { ProductModel } from 'models';
import PurchaseStepType from '../purchase_step_type';

interface MyOptionsProps {
    product: ProductModel | undefined;
    phone: PhoneEntity | undefined;
    onPhoneTap?: () => void;
    onProductTap?: () => void;
    currentStep?: PurchaseStepType;
}

export function MyOptionsTitle({
    currentStep = 'CHOOSE_PHONE'
}: {
    currentStep?: PurchaseStepType;
}) {
    switch (currentStep) {
        case 'SET_CREDIT_CARD':
            return (
                <Text fontWeight="font-medium" color="text-blue-dark">
                    <span className="text-4xl">
                        Preencha as informações de pagamento para finalizar a
                        contratação
                    </span>
                </Text>
            );
        case 'SET_IMEI':
            return (
                <Text fontWeight="font-medium" color="text-blue-dark">
                    <span className="text-4xl">
                        Para seguir, precisamos do número IMEI do seu celular
                    </span>
                </Text>
            );
        case 'SET_PROFILE':
            return (
                <Text fontWeight="font-medium" color="text-blue-dark">
                    <span className="text-4xl">
                        Antes de finalizar a contratação, precisamos de algumas
                        informações
                    </span>
                </Text>
            );
        case 'CHOOSE_PHONE':
            return (
                <Text fontWeight="font-medium" color="text-blue-dark">
                    <span className="text-4xl">
                        Para iniciar a cotação, insira a marca e o modelo do seu
                        celular
                    </span>
                </Text>
            );
        default:
            return (
                <Text fontWeight="font-medium" color="text-blue-dark">
                    <span className="text-4xl">
                        Estas são as melhores ofertas de seguro para o seu
                        celular
                    </span>
                </Text>
            );
    }
}

export default function MyOptions({
    product,
    phone,
    onPhoneTap,
    onProductTap,
    currentStep
}: MyOptionsProps) {
    return (
        <div data-testid="MyOptions">
            <div className="hidden md:inline">
                <MyOptionsTitle currentStep={currentStep} />
            </div>
            <div>
                <Divider y={18} />
                {currentStep === 'CHOOSE_PHONE' && (
                    <Text color="text-neutral-40" fontSize="text-base">
                        Precisamos destas informações para oferecer as melhores
                        ofertas para você.
                    </Text>
                )}
                {currentStep === 'CHOOSE_PRODUCT' && (
                    <Text color="text-neutral-40" fontSize="text-base">
                        Selecione ao lado a oferta que mais combina com a
                        proteção que você precisa
                    </Text>
                )}
            </div>
            <Divider y={18} />
            {(product || phone) && <p>Detalhes do seu plano:</p>}
            <Divider y={12} />
            <div>
                {phone && (
                    <div className="flex">
                        <CardWrapper>
                            <div className="py-4 pl-6 pr-2">
                                <ListTile
                                    trealing={<IconPhone />}
                                    leading={
                                        <Button
                                            id="btn-change-phone"
                                            onClick={onPhoneTap}
                                            showIconRight={true}
                                            fontFamily="font-serif"
                                            fontWeigth="font-bold"
                                            iconRight={<IconClose />}
                                            text="Alterar"
                                        />
                                    }
                                >
                                    <div>
                                        <Text
                                            id="txt-phone-name"
                                            fontFamily="font-serif"
                                            fontSize="text-base"
                                            fontWeight="font-bold"
                                            color="text-neutral-0"
                                        >
                                            {phone.name}
                                        </Text>
                                    </div>
                                    <div>
                                        <Text
                                            id="txt-phone-brand-name"
                                            fontFamily="font-serif"
                                            fontSize="text-sm"
                                            color="text-neutral-0"
                                            colorDark="text-neutral-80"
                                        >
                                            {phone.brand.name}
                                        </Text>
                                    </div>
                                </ListTile>
                            </div>
                        </CardWrapper>
                    </div>
                )}
                <Divider y={16} />
                {product && (
                    <div className="flex flex-col gap-16">
                        <div className="flex">
                            <CardWrapper>
                                <div className="py-4 pl-6 pr-2">
                                    <ListTile
                                        trealing={<IconPadlock />}
                                        leading={
                                            <Button
                                                id="btn-change-product"
                                                onClick={onProductTap}
                                                showIconRight={true}
                                                fontFamily="font-serif"
                                                fontWeigth="font-bold"
                                                iconRight={<IconClose />}
                                                text="Alterar"
                                            />
                                        }
                                    >
                                        <div>
                                            <Text
                                                id="txt-product-price"
                                                fontFamily="font-serif"
                                                fontSize="text-base"
                                                fontWeight="font-bold"
                                                color="text-neutral-0"
                                                colorDark="text-neutral-90"
                                            >
                                                {product.price
                                                    ? new Intl.NumberFormat(
                                                          'pt-BR',
                                                          {
                                                              style: 'currency',
                                                              currency: 'BRL'
                                                          }
                                                      ).format(product.price)
                                                    : 'R$ 0,00'}{' '}
                                                ao mês
                                            </Text>
                                        </div>
                                        <div>
                                            <Text
                                                id="txt-product-name"
                                                fontFamily="font-serif"
                                                fontSize="text-sm"
                                                color="text-neutral-0"
                                                colorDark="text-neutral-90"
                                            >
                                                {product.plan.name}
                                            </Text>
                                        </div>
                                    </ListTile>
                                </div>
                            </CardWrapper>
                        </div>
                        <div className="flex flex-col gap-[24px] text-neutral-50">
                            <div className="flex gap-[14px]">
                                <div className="text-neutral-80">
                                    <IconCheck />
                                </div>
                                Analisamos o perfil pelo CPF de quem usa o
                                celular. Com isso garantimos segurança e ofertas
                                justas para os membros da EXA.
                            </div>

                            <div className="flex gap-[14px]">
                                <div className="text-neutral-80">
                                    <IconCheck />
                                </div>
                                O reembolso é pago em conta bancária com
                                titularidade do mesmo CPF.
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
