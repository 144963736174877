import { ExaLoading, Text } from "gosafe";
import { useEffect, useState } from "react";

interface PaymentLoadingProps {
  initialState?: "loading" | "success" | "error";
  onSuccessCallback?: () => void;
  onErrorCallback?: () => void;
}

const PaymentLoading = ({
  initialState = "loading",
  onSuccessCallback,
  onErrorCallback,
}: PaymentLoadingProps) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (state) {
      const timer = setTimeout(() => {
        if (state === "success" && onSuccessCallback) {
          onSuccessCallback();
        } else if (state === "error" && onErrorCallback) {
          onErrorCallback();
        }
        setState("loading");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [state, onSuccessCallback, onErrorCallback]);

  return (
    <div className="content-center py-20">
      <div className="text-center">
        <Text
          fontSize="text-2xl"
          fontWeight="font-medium"
          color="text-neutral-0">
          Seu pagamento está sendo processado...
        </Text>
      </div>

      <div className="flex justify-center pt-10">
        <ExaLoading state={state} />
      </div>
    </div>
  );
};

export default PaymentLoading;
