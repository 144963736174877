import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import { InfinityLoading } from "gosafe/atoms";
import { IconArrowDown, IconCloseFilled } from "gosafe/atoms/icons";
import { Fragment, useEffect, useMemo, useState } from "react";
import DropdownFieldItem from "./dropdown_field_item";

export interface DropdownFieldProps {
  id?: string;
  name: string;
  textLabel?: string;
  value?: DropdownFieldItem | undefined;
  textHelper?: string;
  hintText?: string;
  items?: Array<DropdownFieldItem>;
  disabled?: boolean;
  loading?: boolean;
  hasError?: boolean;
  errorText?: string;
  onChanged?: (value: DropdownFieldItem | undefined) => void;
}

const DropdownField = ({
  id,
  name,
  textLabel,
  value = undefined,
  hintText,
  items = [],
  disabled = false,
  loading = false,
  hasError = false,
  onChanged,
}: DropdownFieldProps) => {
  const [selected, setSelected] = useState<DropdownFieldItem | null>();
  const [query, setQuery] = useState("");
  const [isHover, setIsHover] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    setSelected(value ?? null);
  }, [value]);

  useEffect(() => {
    onChanged?.(selected ?? undefined);
  }, [selected]);

  const filteredItems = useMemo(
    () => items.filter(item => item.label.toLowerCase().includes(query.toLowerCase())),
    [items, query]
  );

  const className = [
    "text-gray-900 w-full rounded-xl border-[2px] py-4 pl-4 pr-10 text-base leading-5 ",
    'focus:border-blue-cyan focus:outline-none focus:ring-4 focus:ring-neutral-90',
    disabled ? 'bg-neutral-90 border-neutral-80' : hasError ? hasFocus ? "border-error" : 'border-error/50' : hasFocus ? "text-primary" : "text-neutral-40"
  ];

  if (disabled) {
    className.push("bg-neutral-90 border-neutral-80");
  }else if (hasError) {
    className.push(`border-error`);
  } else  {
    className.push(`bg-white ${hasFocus ? "text-primary" : "text-neutral-40"}`);
  }

  return (
    <div data-testid={`DropdownField-${id}`} className="relative" key={id}>
      {textLabel && (
        <div className={`ml-4 text-xs ${disabled ? "text-neutral-70" : "text-neutral-20"}`}>
          {textLabel}
        </div>
      )}
      <Combobox name={name} value={selected} onChange={setSelected} disabled={disabled}>
        <div
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          className="relative w-full"
        >
          <ComboboxInput
            data-testid={`DropdownInput`}
            className={[
              " text-gray-900 w-full rounded-xl border-[2px] py-4 pl-4 pr-10 text-base leading-5 focus:border-blue-cyan focus:outline-none focus:ring-4 focus:ring-neutral-90",
              disabled ? "bg-neutral-90" : "bg-white",
              disabled
                ? "border-neutral-80"
                : isHover
                  ? "border-neutral-60"
                  : "border-neutral-70",
            ].join(" ")}
            displayValue={item => {
              if (item !== null) {
                return (item as DropdownFieldItem).label;
              }
              return "";
            }}
            onKeyDown={event => {
              if (event.key === "Escape") {
                if (query.length === 0) {
                  setSelected(null);
                }
              }
            }}
            onChange={event => {
              setQuery(event.target.value);
            }}
            onFocus={() => {
              setHasFocus(true);
            }}
            onBlur={() => {
              setHasFocus(false);
            }}
            readOnly={disabled}
            placeholder={hintText}
          />
          <ComboboxButton
            as='div'
            className={[
              "absolute inset-y-0 w-full h-full flex items-center justify-end",
              disabled
                ? "text-neutral-80"
                : hasFocus
                  ? "text-primary"
                  : "text-neutral-40",
            ].join(" ")}
          >
            <div className="pr-4" onClick={() => {
              if (selected) {
                setSelected(null);
              }
            }} >
              {!selected && <IconArrowDown />}
              {selected && <IconCloseFilled />}
            </div>
          </ComboboxButton>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <ComboboxOptions
            data-testid="DropdownOption"
            className="ring-black/5 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-neutral-100 py-1 text-base shadow-lg ring-1 focus:outline-none sm:text-sm"
          >
            {loading ? (
              <div className="flex items-center gap-4 px-4 py-2">
                <InfinityLoading width={32} strokeColor="text-primary" />
                <span>Carregando...</span>
              </div>
            ) : (
              <>
                {filteredItems.length === 0 && !loading ? (
                  <div className="p-4 text-neutral-70 ">Nenhuma opção disponível</div>
                ) : (
                  filteredItems.map((item, index) => (
                    <ComboboxOption
                      key={index}
                      value={item}
                      className={({ active, selected }) =>
                        [
                          active ? "bg-neutral-90 text-neutral-0 dark:bg-neutral-80 dark:text-neutral-100" : "bg-transparent text-neutral-40 dark:text-neutral-60 ",
                          selected ? "font-bold" : "font-normal",
                          "cursor-default select-none px-4 py-2",
                        ].join(" ")
                      }
                    >
                      {item.label}
                    </ComboboxOption>
                  ))
                )}
              </>
            )}
          </ComboboxOptions>
        </Transition>
      </Combobox>
    </div>
  );
};



export default DropdownField;
