export default function IconCloseFilled() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM6.25389 6.25389C6.59241 5.91537 7.14127 5.91537 7.47979 6.25389L10 8.7741L12.5202 6.25389C12.8587 5.91537 13.4076 5.91537 13.7461 6.25389C14.0846 6.59241 14.0846 7.14127 13.7461 7.47979L11.2259 10L13.7461 12.5202C14.0846 12.8587 14.0846 13.4076 13.7461 13.7461C13.4076 14.0846 12.8587 14.0846 12.5202 13.7461L10 11.2259L7.47979 13.7461C7.14127 14.0846 6.59241 14.0846 6.25389 13.7461C5.91537 13.4076 5.91537 12.8587 6.25389 12.5202L8.7741 10L6.25389 7.47979C5.91537 7.14127 5.91537 6.59241 6.25389 6.25389Z"
        fill="currentColor"
      />
    </svg>
  );
}
