import { HttpResponse, IHttpClient } from 'core/protocols/http_client';
import OrderEntity, { OrdersResponse } from '../entities/order_entity';
import NoPoliciesError from '../errors/orders_errors';

export interface IGetLastPolicyUseCase {
    execute(): Promise<OrderEntity>;
}

export class GetLastPolicyUseCase implements IGetLastPolicyUseCase {
    private httpClient: IHttpClient;
    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }
    async execute(): Promise<OrderEntity> {
        try {
            const {
                data: { data }
            }: HttpResponse<OrdersResponse> =
                await this.httpClient.get<OrdersResponse>(`/orders?last=true`);
            if (data.length == 0) {
                throw new NoPoliciesError('No policies to show');
            }
            return data[0];
        } catch (error) {
            throw new NoPoliciesError(
                `Error while trying to obtain the user's latest policy`
            );
        }
    }
}
