import { DrawerPanel } from 'gosafe';
import InsuranceCardDrawer, {
    InsuranceCardDrawerProps
} from './insurance_card_drawer';

function InsuranceDrawer({ order }: InsuranceCardDrawerProps) {
    return (
        <DrawerPanel
            name="Seu Seguro"
            showBackButton={false}
            showCloseButton={false}
        >
            <InsuranceCardDrawer order={order} />
        </DrawerPanel>
    );
}

export default InsuranceDrawer;
