import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { usePurchaseState } from '../purchase_state_context';

export function useProfilePhoneInfoController() {
    const { purchase } = usePurchaseState();

    const form = useFormik({
        initialValues: {
            phone: '',
            imei: '',
            brand: '',
            model: ''
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string(),
            imei: Yup.string(),
            brand: Yup.string(),
            model: Yup.string()
        }),
        onSubmit: () => {}
    });

    useEffect(() => {
        if (purchase.phoneInfo) form.setValues(purchase.phoneInfo);
    }, [purchase.phoneInfo]);

    return {
        form
    };
}
