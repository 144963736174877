import style from './style.module.css'
import CIRCLE1 from './images/circle-1.png'
import CIRCLE2 from './images/circle-2.png'
import PHONE from './images/phone.png'

const Loader = () => {
    return (
        <>
            <div className={style.contentLoader}>
                <p>Seu pagamento está sendo processado</p>

                <div className={style.content_loader}>
                    <img src={CIRCLE1} alt="circle1" className={style.rotating} />
                    <img src={CIRCLE2} alt="circle2" className={style.rotating_reverse} />
                    <img src={PHONE} alt="PHONE" />
                </div>
            </div>
        </>
    )
}

export default Loader