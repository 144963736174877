import { createContext, useContext } from "react";
import { ISendCodeUsecase } from "./domain/usecases/send_code_usecase";


const SendCodeContext = createContext<ISendCodeUsecase | undefined>(undefined);

export function useSendCodeUsecase(): ISendCodeUsecase {
    const context = useContext(SendCodeContext);
    if (context === undefined) {
        throw new Error("SendCodeUsecase not found int context");
    }
    return context;
}

export default SendCodeContext;