import cpfValidator from "core/validators/cpf_validator";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useHasOrderByCpfUsecase } from "../has_order_by_cpf_context";
import { useState } from "react";

interface CPFCaptureType {
    cpf: string;
}

function useCPFCaptureController() {

    const navigate = useNavigate();
    const hasOrderByCpfUsecase = useHasOrderByCpfUsecase();
    const [requesting, setRequesting] = useState(false);
    const [showInfo, setShowInfo] = useState(false);

    const form = useFormik<CPFCaptureType>({
        initialValues: {
            cpf: ''
        },
        validationSchema: Yup.object().shape({
            cpf: Yup.string()
                .required("O CPF é obrigatório!")
                .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido!")
                .test("cpf", "CPF inválido!", value => {
                    const onlyNumbers = value.replace(/\D/g, "");
                    return cpfValidator(onlyNumbers);
                }),
        }),
        validateOnChange: true,
        onSubmit: async (values) => {
            form.setTouched({
                'cpf': true
            });

            if (form.isValid) {
                const onlyNumbers = values.cpf.replace(/\D/g, "");
                setRequesting(true);
                setShowInfo(false);
                try {
                    await hasOrderByCpfUsecase.execute(onlyNumbers);
                    setRequesting(false);
                    navigate('/email-check');
                } catch (e) {
                    setShowInfo(true);
                    setRequesting(false);
                }
            }
        }
    });

    function goToPurchase() {
        navigate(`/purchase?cpf=${form.values.cpf.replace(/\D/g, "")}`);
    }

    return {
        form,
        requesting,
        showInfo,
        goToPurchase
    };
}

export default useCPFCaptureController;