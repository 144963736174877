function cpfValidator(cpf: string): boolean {
    let sun;
    let mod;
    sun = 0;
    if (cpf == "00000000000") return false;

    for (let i = 1; i <= 9; i++) sun = sun + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    mod = (sun * 10) % 11;

    if ((mod == 10) || (mod == 11)) mod = 0;
    if (mod != parseInt(cpf.substring(9, 10))) return false;

    sun = 0;
    for (let i = 1; i <= 10; i++) sun = sun + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    mod = (sun * 10) % 11;

    if ((mod == 10) || (mod == 11)) mod = 0;
    if (mod != parseInt(cpf.substring(10, 11))) return false;
    return true;
}


export default cpfValidator;
