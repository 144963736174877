import ImeiCheck, { ImeiCheckResultEntity } from "features/imei_check/";
import { IHttpClient } from "core/protocols/http_client";
import { PhoneEntity } from "features/brand_and_model_search";

interface StepIMEICheckProps {
    httpClient: IHttpClient;
    phone?: PhoneEntity;
    onNextStep: (result: ImeiCheckResultEntity) => void;
}

function StepIMEICheck({ httpClient, phone, onNextStep }: StepIMEICheckProps) {
    return (
        <div className="rounded-2xl border-[1px] border-neutral-90 bg-neutral-100 p-10 shadow">
            <ImeiCheck httpClient={httpClient} phone={phone} onTap={onNextStep} />
        </div>
    );
}
export default StepIMEICheck;