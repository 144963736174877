import { jwtDecode } from "jwt-decode";

function Decode() {

  const dataUrl = () => {
    // Captura o parâmetro data da URL
    let url_string = window.location.href;
    let url = new URL(url_string);
    const param = url.searchParams.get("data")

    return param !== null ? param : false
  }

  if (!dataUrl()) {
    // Verifica a existência do parâmetro
    window.location.href = "/";
    return console.warn(' Não foi identificado o parâmetro responsável pelo token!');
  }

  try {
    const token = dataUrl();
    const decoded = jwtDecode(token);
    const validateToken = () => {
      // Valida se o token expirou ou não
      const header = decoded;
      const now = Math.floor(Date.now() / 1000);
      return header && header.exp > now;
    };

    if (validateToken()) {
      return decoded;
    } else {
      console.warn('O token expirou!');
      return {
        data: 'Tempo expirado!',
        details: 'Desculpe, o tempo limite para efetuar o pagamento foi atingido. Por favor, retorne ao app e realize um novo processo de compra.',
        status: false,
        error: true,
        repeat_process: false
      };
    }
  } catch (error) {
    console.warn('O token passado está incorreto!');
    return {
      data: 'Não foi possível carregar a página de pagamento.',
      details: 'Houve um erro ao processar o fluxo de pagamento. Tente novamente mais tarde.',
      status: false,
      error: true,
      repeat_process: false
    };
  }
}

export default Decode;