import { ISignIn } from "core/authentication/sign_in";
import { UserInterface } from "core/authentication/users_interface";
import { FirebaseApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import * as Sentry from "@sentry/react";

class SignInWithGoogleUsecaseImpl implements ISignIn {
  private app: FirebaseApp;

  constructor(app: FirebaseApp) {
    this.app = app;
  }

  async execute(): Promise<UserInterface> {
    try {
      const auth = getAuth(this.app);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const firebaseUser = result.user;
      const mappedUser: UserInterface = {
        uid: firebaseUser.uid || "",
        email: firebaseUser.email || "",
        emailVerified: firebaseUser.emailVerified || false,
        displayName: firebaseUser.displayName || "",
        isAnonymous: firebaseUser.isAnonymous || false,
        photoURL: firebaseUser.photoURL || "",
        providerData: firebaseUser.providerData
          ? firebaseUser.providerData.map(provider => ({
              uid: provider.uid || "",
              displayName: provider.displayName || "",
              photoURL: provider.photoURL || "",
              email: provider.email || "",
              providerId: provider.providerId || "",
              phoneNumber: "",
            }))
          : [],
        stsTokenManager: {
          refreshToken: firebaseUser.refreshToken || "",
          accessToken: "",
          expirationTime: 0,
        },
        createdAt: "",
        lastLoginAt: "",
        apiKey: "",
        appName: "",
        authMethod: "google",
      };
      return mappedUser;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
            'feature.authentication': "SignInWithGoogleUsecaseImpl",
        }});
      throw error;
    }
  }
}

export default SignInWithGoogleUsecaseImpl;
