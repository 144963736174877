import { BackButton } from "gosafe/molecules/buttons";

export default function TestefoneHeaderThin({ isLogged = false }: { isLogged?: boolean }) {

    return (
        <div className={`flex justify-between items-center ${isLogged ? 'bg-neutral-100' : 'bg-primary'} px-4 py-4`}>
            <div className="inline md:hidden">
                <BackButton color="text-neutral-100" onClick={() => {
                    window.history.back();
                }} padding='16px 16px' />
            </div>
            <a href="/">
                {isLogged && <img src="/images/logo-testefone-primary.png" alt="Logo testefone" width={160} />}
                {!isLogged && <img src="/images/logo-testefone.png" alt="Logo testefone" width={160} />}
            </a>
        </div>
    );
}