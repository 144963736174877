import { IGetFaqUsecase } from "features/faq/domain/usecases/get_faq_usecase";
import { createContext, useContext } from "react";

const GetFaqContext = createContext<IGetFaqUsecase|undefined>(undefined);

export function useGetFaqUsecase() {
  const context = useContext(GetFaqContext);

  if (context === undefined) {
    throw new Error('IGetFaqContext not found');
  }

  return context;
}

export default GetFaqContext;