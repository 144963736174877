class ImeiCheckEntity {
  imei: string;
  brand: string;
  identifiers: string[];
  name: string;

  constructor(
    imei: string,
    brand: string,
    identifiers: string[],
    name: string
  ) {
    this.imei = imei;
    this.brand = brand;
    this.identifiers = identifiers;
    this.name = name;
  }
}

export default ImeiCheckEntity;
