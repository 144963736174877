import { Button, Text } from "gosafe";

interface TryAgainProps {
    onClick: VoidFunction,
    title: string,
    description: string
}

export default function TryAgain({ onClick, title, description }: TryAgainProps) {

    return (
        <div className="py-10 md:py-0">
            <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
                <div className="flex-1 bg-blue-500">
                    <div className="content-center p-0">
                        <header className='pb-5'>
                            <Text fontSize='text-2xl' fontWeight='font-medium' colorDark='text-neutral-100'>{title}</Text>
                        </header>
                        <Text fontSize='text-lg' color='text-neutral-10' colorDark='text-neutral-100' >{description}</Text>
                    </div>
                </div>
                <div className="flex-1 flex items-center justify-center">
                    <img className="w-64 h-64 md:w-64 md:h-64" alt="Error" src="/images/attention.png" />
                </div>
            </div>

            <div className='flex items-center justify-center py-8'>
                <div>
                    <Button
                        id="btn-payment-try-again"
                        text="Tentar novamente"
                        color='text-neutral-100'
                        onClick={onClick}
                        backgroundColor='bg-primary'
                    />
                </div>
            </div>
        </div>
    );
}