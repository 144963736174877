import FaqEntity from "features/faq/domain/entities/faq_entity";
import { useGetFaqUsecase } from "features/faq/get_faq_context";
import { useEffect, useState } from "react";

import * as Sentry from "@sentry/react";
import { useSearchParams } from "react-router-dom";

export default function useFaqController() {

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [faqs, setFaqs] = useState<FaqEntity[]>([]);

  const getFaqUsecase = useGetFaqUsecase();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    getFaqs();
  }, []);

  useEffect(() => {
    if (!loading) {
      const details = searchParams.get('details');
      if (details) {
        openDetail(parseInt(details) - 1);
      } else {
        setOpen(true);
      }
    }
  }, [loading]);

  function openDetail(id: number) {
    const details = document.getElementsByTagName('details')[id];

    const isOpen = details.hasAttribute('open');

    if (!isOpen) details.toggleAttribute('open');

    window.scrollTo({
      top: details.offsetTop - 20,
      behavior: 'smooth'
    });
  }

  function getFaqs() {
    return getFaqUsecase.execute()
      .then(faqs => {
        setFaqs(faqs.sort((f1, f2) => f1.order - f2.order));
      })
      .catch(error => {
        Sentry.captureException(error, {
          tags: {
            'feature.faq': "useFaqController",
          }
        });
      }).finally(() => {
        setLoading(false);
      });
  }

  return {
    loading,
    faqs,
    open
  };
}