import { Text } from "gosafe";
import "./style.css";

import UrlApps from "../pages/landing_page/url_apps";

interface TryNowProps {
  urlApps: UrlApps;
  title?: string;
  active?: boolean;
}

const TryNowSection = ({
  urlApps,
  title = "Faça os testes no seu aparelho em apenas 3 passos",
  active = false
}: TryNowProps) => {
  return (
    <section className="steps-solution bg-neutral-100">
      <div className="content-center">
        <header>
          <Text fontSize="text-3xl" fontWeight="font-medium">
            {title}
          </Text>
        </header>

        <div className="steps">
          <div className="btn-apps">
            <ul>
              <li>
                <a href={urlApps.appleStore} target="_blank" rel="noreferrer">
                  <img
                    src="/images/button-apple-store.png"
                    alt="AppleStore"
                    width={220}
                    height={80}
                  />
                </a>
              </li>
              <li>
                <a href={urlApps.playStore} target="_blank" rel="noreferrer">
                  <img
                    src="/images/button-play-store.png"
                    alt="PlayStore"
                    width={220}
                    height={80}
                  />
                </a>
              </li>
            </ul>
          </div>

          <div className="list-steps">
            <ul>
              <li>
                <h3>
                  01 <br /> Baixe o app
                </h3>
                <p className="text-xs">
                  Disponível na Apple Store ou Play Store.
                </p>
              </li>

              <li>
                <h3>
                  02 <br /> {active ? "Faça login" : "Cadastre-se"}
                </h3>
                <p className="text-xs">
                    {active ? "É só seguir o passo a passo do aplicativo." : "É só se cadastrar com seu e-mail e seguir o passo a passo do aplicativo."}
                </p>
              </li>

              <li>
                <h3>
                  03 <br /> Pronto!
                </h3>
                <p className="text-xs"> {active ? "Você pode acessar seu certificado quando e como quiser." : "Agora você pode selecionar diversos tipos de testes para conhecer mais sobre a saúde e o estado do seu aparelho e escolher o seguro que mais se encaixa nas suas necessidades."}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TryNowSection;
