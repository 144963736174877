import { IHttpClient } from "core/protocols/http_client";
import { PhoneEntity } from "features/brand_and_model_search";

import ImeiCheckResultEntity from "./domain/entities/imei_check_result_entity";
import ImeiCheckBindings from "./imei_check_bindings";
import ImeiCheckForm from "./presenter/imei_check_form";

export { ImeiCheckResultEntity };

interface ImeiCheckProps {
  httpClient: IHttpClient;
  phone: PhoneEntity | undefined;
  onTap?: (imeiCheckResultEntity: ImeiCheckResultEntity) => void;
}

export default function ImeiCheck({
  httpClient,
  phone,
  onTap,
}: ImeiCheckProps) {
  return (
    <ImeiCheckBindings httpClient={httpClient}>
      <ImeiCheckForm phone={phone} onTap={onTap} />
    </ImeiCheckBindings>
  );
}
