import { createContext, useContext } from "react";
import { ICreateProfileUsecase } from "./domain/usecases/create_profile_usecase";

const CreateProfileContext = createContext<ICreateProfileUsecase | undefined>(
  undefined
);

export function useCreateProfileUsecase() {
  const context = useContext(CreateProfileContext);
  if (context === undefined) {
    throw new Error("ICreateProfileUsecase not found");
  }
  return context;
}

export default CreateProfileContext;
