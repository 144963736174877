

interface IconExclamationMarkProps {
    color?: string,
    width?: number
    height?: number
}

function IconExclamationMark( {color = 'white', width = 8, height = 29 } :IconExclamationMarkProps ) {
    return (<svg width={width} height={height} viewBox="0 0 8 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.75 14.5V4C7.75 3.07174 7.38125 2.1815 6.72487 1.52513C6.06849 0.86875 5.17826 0.5 4.25 0.5C3.32174 0.5 2.4315 0.86875 1.77513 1.52513C1.11875 2.1815 0.75 3.07174 0.75 4V14.5C0.75 15.4283 1.11875 16.3185 1.77513 16.9749C2.4315 17.6312 3.32174 18 4.25 18C5.17826 18 6.06849 17.6312 6.72487 16.9749C7.38125 16.3185 7.75 15.4283 7.75 14.5Z" fill={color}/>
    <path d="M6.72487 27.4749C7.38125 26.8185 7.75 25.9283 7.75 25C7.75 24.0717 7.38125 23.1815 6.72487 22.5251C6.06849 21.8687 5.17826 21.5 4.25 21.5C3.32174 21.5 2.4315 21.8687 1.77513 22.5251C1.11875 23.1815 0.75 24.0717 0.75 25C0.75 25.9283 1.11875 26.8185 1.77513 27.4749C2.4315 28.1313 3.32174 28.5 4.25 28.5C5.17826 28.5 6.06849 28.1313 6.72487 27.4749Z" fill="white"/>
    </svg>
    );
}

export default IconExclamationMark;