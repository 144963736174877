import { Button, Text } from "gosafe";

interface TryAgainProps {
  onClick: () => void;
}

const TrayAgainSection = ({ onClick }: TryAgainProps) => {
  return (
    <div className="py-10">
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="flex-1 bg-blue-500">
          <div className="content-center p-0">
            <header className="pb-5">
              <Text
                fontSize="text-2xl"
                fontWeight="font-medium"
                colorDark="text-neutral-100">
                Não foi possível processar o seu pagamento!
              </Text>
            </header>
            <Text
              fontSize="text-lg"
              color="text-neutral-10"
              colorDark="text-neutral-100">
              Houve um erro ao processar a operação e sua assinatura não foi
              realizada. Tente novamente para finalizar o processo de proteção
              do seu celular.
            </Text>
          </div>
        </div>
        <div className="flex-1 flex items-center justify-center py-10">
          <img
            className="w-64 h-64 md:w-64 md:h-64"
            alt="Error"
            src="/images/attention.png"
          />
        </div>
      </div>

      <div className="flex items-center justify-center mt-4 md:mt-0">
        <div>
          <Button
            id="btn-payment-try-again"
            text="Tentar novamente"
            color="text-neutral-100"
            onClick={onClick}
            backgroundColor="bg-primary"
          />
        </div>
      </div>
    </div>
  );
};

export default TrayAgainSection;
