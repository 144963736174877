import { DrawerPanel, useDrawer } from 'gosafe';
import { DrawerPanelProps } from 'gosafe/molecules/drawer/drawer_panel';
import { useState } from 'react';
import CertificatesHistoryDrawer from './certificates_history_drawer';
import PolicyCardDrawer from './policy_card_drawer';
import ProfileCertificates from './profile_certificates/profile_certificates';

export interface PolicyDrawerProps {
    openStep: number;
}

function PolicyDrawer({ openStep }: PolicyDrawerProps) {
    const { closeDrawer } = useDrawer();
    const [opened, setOpened] = useState(false);
    const [step, setStep] = useState(openStep!);

    function handleToggle(value: boolean) {
        setOpened(value);
    }
    const optionsPanel: DrawerPanelProps[] = [
        {
            step: 0,
            name: 'Seu seguro',
            showBackButton: true,
            showCloseButton: true,

            onBackButton: () => setStep(1),
            onCloseButton: closeDrawer,
            children: <PolicyCardDrawer />
        },
        {
            step: 1,
            name: 'Detalhes do seguro',
            showBackButton: false,
            showCloseButton: true,
            onBackButton: closeDrawer,
            onCloseButton: closeDrawer,
            children: (
                <ProfileCertificates
                    openDetailsDrawer={() => setStep(0)}
                    openCertificatesDrawer={() => setStep(2)}
                />
            )
        },
        {
            step: 2,
            name: 'Certificados de seguro',
            showBackButton: true,
            showCloseButton: true,
            onBackButton: () => setStep(1),
            onCloseButton: closeDrawer,
            children: <CertificatesHistoryDrawer />
        }
    ];
    return (
        <>
            {step < optionsPanel.length ? (
                optionsPanel.map(
                    (item: DrawerPanelProps) =>
                        step === item.step && (
                            <DrawerPanel
                                name={item.name}
                                key={item.step}
                                showBackButton={item.showBackButton}
                                showCloseButton={item.showCloseButton}
                                onBackButton={item.onBackButton}
                                onCloseButton={item.onCloseButton}
                            >
                                {item.children}
                            </DrawerPanel>
                        )
                )
            ) : (
                <DrawerPanel
                    name="Não Encontrado"
                    showBackButton={true}
                    showCloseButton={false}
                    onBackButton={() => setStep(0)}
                    onCloseButton={() => handleToggle(!opened)}
                />
            )}
        </>
    );
}

export default PolicyDrawer;
