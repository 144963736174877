import { ListTile, ListView, Text } from "gosafe/atoms";
import { IconCheck } from "gosafe/atoms/icons";

import QuoteInsuranceForm from "./quote_insurance_form";

const SecurityPhoneExaSection = () => {
  return (
    <section className="bg-blue-dark flex justify-center">
      <div className="flex max-w-[1100px] flex-col md:flex-row">
        <div className="flex basis-1/2 flex-col gap-10 p-10 md:justify-center">
          <div>
            <Text
              color="text-secondary"
              fontWeight="font-normal"
              fontSize="text-4xl"
            >
              Coberturas
            </Text>
            <div className="h-4" />
            <Text
              fontWeight="font-thin"
              color="text-neutral-100"
              fontSize="text-base"
            >
              Escolha o plano ideal para o seu aparelho
            </Text>
            <div className="h-4" />
            <ListView>
              <ListTile
                trealing={
                  <div className="text-secondary">
                    <IconCheck />
                  </div>
                }
              >
                <Text fontSize="text-base" color="text-neutral-100">
                  Quebra acidental
                </Text>
              </ListTile>
              <ListTile
                trealing={
                  <div className="text-secondary">
                    <IconCheck />
                  </div>
                }
              >
                <Text fontSize="text-base" color="text-neutral-100">
                  Roubo e quebra acidental
                </Text>
              </ListTile>
              <ListTile
                trealing={
                  <div className="text-secondary">
                    <IconCheck />
                  </div>
                }
              >
                <Text fontSize="text-base" color="text-neutral-100">
                  Roubo ou furto
                </Text>
              </ListTile>
              <ListTile
                trealing={
                  <div className="text-secondary">
                    <IconCheck />
                  </div>
                }
              >
                <Text fontSize="text-base" color="text-neutral-100">
                  Pagamento via cartão de crédito
                </Text>
              </ListTile>
            </ListView>
          </div>

          <div className="flex items-center gap-3">
            <div className="max-w-[100px]">
              <img src="/images/susep_logo.png" alt="" width={180} />
            </div>
            <Text fontSize="text-xs" color="text-neutral-100">
              Nosso seguro é regulado pela SUSEP, orgão <br className="hidden md:inline"/>
              fiscalizador da industria de seguros brasileira
            </Text>
          </div>
        </div>

        <div className="basis-1/2 p-10">
          <div>
            <QuoteInsuranceForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecurityPhoneExaSection;
