import {
    Button,
    CardWrapper,
    ExaLoading,
    IconArrowRightLight,
    IconLock,
    IconLockOpen,
    ListTile,
    Text,
    useDrawer
} from 'gosafe';
import OutlinedButton from 'gosafe/molecules/buttons/outlined_button';
import { useProfileCertificateController } from './profile_certificates_controller';
import { usePurchaseState } from '../../purchase_state_context';

export interface ProfileCertificatesProps {
    openDetailsDrawer: () => void;
    openCertificatesDrawer: () => void;
}
export default function ProfileCertificates({
    openDetailsDrawer,
    openCertificatesDrawer
}: ProfileCertificatesProps) {
    const { isLoading, certificates } = useProfileCertificateController();
    const { dispatch } = usePurchaseState();
    const { closeDrawer } = useDrawer();

    return (
        <div id="certificates-drawer" className="flex flex-col flex-grow">
            <p>
                Visualize aqui seu status de proteção e mais informações sobre
                seu serviço
            </p>
            {isLoading ? (
                <div className="flex items-center justify-center p-12">
                    <ExaLoading />
                </div>
            ) : (
                certificates !== null &&
                certificates !== undefined && (
                    <>

                        <CardWrapper className="p-4 mt-4 flex flex-col">
                            <div
                                className="pb-4 items-center border-md border-b-[.1px]"
                                onClick={openDetailsDrawer}
                            >
                                <ListTile
                                    trealing={
                                        certificates[0].state === 'active' ? (
                                            <IconLock />
                                        ) : (
                                            <IconLockOpen />
                                        )
                                    }
                                    leading={<IconArrowRightLight />}
                                >
                                    <div className="flex flex-col pr-4">
                                        <Text
                                            color="text-neutral-10"
                                            fontSize="text-base"
                                            fontFamily="font-sans"
                                            fontWeight="font-medium"
                                        >
                                            {certificates[0].planName ?? ''}
                                        </Text>
                                        <Text
                                            color="text-neutral-10"
                                            fontSize="text-sm"
                                            fontFamily="font-serif"
                                            fontWeight="font-normal"
                                        >
                                            Nº da apólice:{' '}
                                            {certificates[0].policyNumber}
                                        </Text>
                                    </div>
                                </ListTile>
                            </div>
                            <div>
                                <div
                                    className={`${certificates[0].state === 'active' ? 'tag' : 'tag-error'}`}
                                >
                                    <Text
                                        fontFamily="font-serif"
                                        color="text-neutral-10"
                                        fontSize="text-xs"
                                        fontWeight="font-bold"
                                    >
                                        {certificates[0].state === 'active'
                                            ? 'Ativo'
                                            : 'Inativo'}
                                    </Text>
                                </div>
                            </div>
                        </CardWrapper>
                        <CardWrapper className="p-4 mt-4 flex flex-col">
                            <div
                                className="flex flex-row justify-between"
                                onClick={openCertificatesDrawer}
                            >
                                <div className="flex flex-col">
                                    <Text
                                        color="text-neutral-10"
                                        fontSize="text-base"
                                        fontFamily="font-sans"
                                        fontWeight="font-medium"
                                    >
                                        Histórico de certificados
                                    </Text>
                                    <Text
                                        fontFamily="font-serif"
                                        color="text-neutral-10"
                                        fontSize="text-xs"
                                        fontWeight="font-medium"
                                    >
                                        Certificados de seguro
                                    </Text>
                                </div>

                                <div className="flex flex-col justify-center">
                                    <IconArrowRightLight />
                                </div>
                            </div>
                        </CardWrapper>
                        <div className="py-6">
                            {certificates[0].state === 'active' ? (
                                <></>
                            ) : (
                                <div className="mb-4">
                                    <OutlinedButton
                                        text="Ativar seguro"
                                        onClick={() => {
                                            dispatch({
                                                type: 'CLEAN_PHONE'
                                            });
                                            closeDrawer();
                                        }}
                                    />
                                </div>
                            )}

                            <Button
                                text="Solicitar sinistro"
                                type="button"
                                infinity={true}
                                enabled={false}
                                backgroundColor="bg-primary"
                                color="text-neutral-100"
                                padding="py-5 px-6"
                                onClick={() => { }}
                            />
                        </div>
                    </>
                )
            )
            }
        </div >
    );
}
