import './style.css';

import { IconArrowRight } from 'gosafe/atoms/icons';
import { Button, SecondaryButton } from "gosafe/molecules/buttons";
import { useAuthentication } from 'features/authentication/authentication_bindings';
import { InfinityLoading } from 'gosafe';
import { useNavigate } from 'react-router-dom';

function HeaderSection() {

    const { isLogged, isLoading } = useAuthentication();
    const navigate = useNavigate();

    return (
        <section className="top-header" style={{ backgroundImage: `url(/images/vetor-header.png)` }}>
            <div className="content-center">
                <nav>
                    <a href="/">
                        <div className='w-45 h-216'><img src='/images/logo-testefone.svg' alt=''></img></div>
                    </a>

                    {isLoading && <InfinityLoading strokeColor='text-neutral-100' />}
                    {!isLoading && !isLogged &&
                        <Button id='btn-entrar' onClick={() => {
                            navigate("/signin");
                        }} showIconRight={true} iconRight={<IconArrowRight />} text='Entrar'></Button>}

                </nav>

                <div className="content">
                    <section className="text">
                        <header>
                            <h1>Seguro celular do<br /> seu jeito</h1>

                            <p>Cobertura nacional, sem carência e assinatura 100% digital. Aqui você encontra os melhores planos para proteger o seu aparelho. Sem letras miúdas ou complicações.</p>

                            <div className='hidden md:inline'>
                                <div><SecondaryButton
                                    id='btn-header-cote-agora-1'
                                    onClick={() => {
                                        navigate("/purchase");
                                    }}
                                    text='Cote agora' showIconRight iconRight={<IconArrowRight />} /></div>
                                <div className='h-10'></div>
                                <div>
                                    <a href="/">
                                        <img src={"/images/logo-exa-header.png"} alt="Logo EXA" width={206} />
                                    </a>
                                </div>
                            </div>
                        </header>
                    </section>
                    <div className="image-mockup">
                        <img src={"/images/mockup-iphone13.png"} alt="Mockup Iphone 13" width={588} height={1098} />
                    </div>
                    <div className='flex flex-col items-center gap-10 pb-10 md:hidden'>
                        <div><SecondaryButton
                            id='btn-header-cote-agora-2'
                            onClick={() => {
                                navigate("/purchase");
                            }}
                            text='Cote agora' showIconRight iconRight={<IconArrowRight />} /></div>
                        <div>
                            <a href="/">
                                <img src={"/images/logo-exa-header.png"} alt="Logo EXA" width={206} />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default HeaderSection;
