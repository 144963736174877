import OrderEntity from 'features/purchase/domain/entities/order_entity';
import { CardWrapper, ExaLoading, IconChevronRight } from 'gosafe';
import { Key } from 'react';
import { transformDate } from 'utils/transformDate';
import { useProfileCertificateController } from './profile_certificates/profile_certificates_controller';

function CertificatesHistoryDrawer() {
    const { isLoading, certificates } = useProfileCertificateController();

    return (
        <div id="certificates-history-drawer" className="p-2">
            <p>
                Visualize aqui seus certificados. Eles validam a atividade do
                seu serviço contratado.
            </p>
            {isLoading ? (
                <div className="flex items-center justify-center p-12">
                    <ExaLoading />
                </div>
            ) : (
                certificates !== null &&
                certificates !== undefined && (
                    <ul className="flex flex-col gap-3 mt-5">
                        {certificates.map((cert: OrderEntity, index: Key) => (
                            <CardWrapper key={index}>
                                <a
                                    href={
                                        cert.state === 'active'
                                            ? cert.policyUrl
                                            : undefined
                                    }
                                    target="_blank"
                                    className="group flex w-full cursor-pointer items-center justify-between rounded-lg px-5 py-4"
                                    rel="noreferrer"
                                >
                                    <div className="flex flex-col gap-1">
                                        <p
                                            className={`text-sm font-bold ${cert.state === 'active' ? 'text-neutral-200' : 'text-neutral-50'}`}
                                        >
                                            Certificado {cert.policyNumber}
                                        </p>
                                        <p
                                            className={`text-xs font-normal ${cert.state === 'active' ? 'text-neutral-200' : 'text-neutral-50'}`}
                                        >
                                            {cert.state === 'active'
                                                ? 'Ativo'
                                                : 'Expirado'}{' '}
                                            em{' '}
                                            {cert.state === 'active'
                                                ? transformDate(
                                                      cert.createdAt.toString()
                                                  )
                                                : transformDate(
                                                      cert.endValidity.toString()
                                                  )}
                                        </p>
                                    </div>
                                    {cert.state === 'active' && (
                                        <IconChevronRight />
                                    )}
                                </a>
                            </CardWrapper>
                        ))}
                    </ul>
                )
            )}
        </div>
    );
}

export default CertificatesHistoryDrawer;
