import { ReactNode, createContext, useContext } from "react";

import { FirebaseApp, initializeApp } from "firebase/app";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG ?? "{}") as {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
};

const FirebaseContext = createContext<FirebaseApp | undefined>(undefined);

export function useFirebase(): FirebaseApp {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error("Firebase not found in context");
  }
  return context;
}

interface FirebaseBindingProps {
  children: ReactNode;
}

export default function FirebaseBinding({ children }: FirebaseBindingProps) {
  return (
    <FirebaseContext.Provider value={initializeApp(firebaseConfig)}>
      {children}
    </FirebaseContext.Provider>
  );
}
