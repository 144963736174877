const notFound = () => {
  return (
    <div
      className="App"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <header className="App-header">
        <small className="text-neutral-20">Ops...</small>
        <h1 className="color-neutral-20 text-[30px]">Página não encontrada!</h1>
      </header>
    </div>
  );
};

export default notFound;
