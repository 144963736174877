import React, { HTMLAttributeAnchorTarget, MouseEvent, ReactNode } from "react";
import { Link } from "react-router-dom";

interface TextLinkProps {
  label: string;
  linkTo?: string;
  url?: string;
  icon?: ReactNode;
  target?: HTMLAttributeAnchorTarget;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}
const TextLink: React.FC<TextLinkProps> = ({
  label,
  linkTo = "/",
  url,
  onClick,
  target = "_blank",
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      className={`inline-block cursor-pointer border-b-[1px] border-primary`}
      onClick={handleOnClick}
    >
      <div className="m-0 p-0 text-primary">
        {url ? (
          <a href={url} target={target} rel="noreferrer">
            {label}
          </a>
        ) : (
          <div className="m-[8px]">
            <Link to={linkTo}>{label}</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextLink;
