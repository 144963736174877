import Checkbox from 'gosafe/atoms/checkbox';

interface CheckboxFieldProps {
    id: string;
    textLabel?: string;
    showLabel: boolean;
    value: boolean;
    disabled?: boolean;
    onChange: (value: boolean) => void;
}

export default function CheckboxField({
    id,
    textLabel,
    showLabel = true,
    value,
    disabled = false,
    onChange
}: CheckboxFieldProps) {
    function handleCheck() {
        onChange(!value);
    }

    return (
        <div
            data-testid={`CheckboxField-${id}`}
            className="ml-4 flex items-center space-x-2"
        >
            <Checkbox
                data-testeid={`CheckboxField-${id}-checkbox`}
                onTap={handleCheck}
                key="check"
                value={disabled === false && value}
            />

            {showLabel && (
                <p
                    onClick={handleCheck}
                    data-testeid={`CheckboxField-${id}-label`}
                    className="text-xs font-[--font-family_secondary] text-neutral-30"
                >
                    {textLabel}
                </p>
            )}
        </div>
    );
}
