import isEquals from 'core/utils/is_equals';
import zipCodeValidator from 'core/validators/zip_code_validator';
import { useAuthentication } from 'features/authentication/authentication_bindings';
import { CustomerAddressEntity } from 'features/purchase/domain/entities/customer_entity';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { usePurchaseState } from '../purchase_state_context';
import { useUpdateUserUsecase } from 'features/purchase/update_user_context';
import { useGetAddressByCepUsecase } from 'features/profile/get_address_cep_context';

export interface IProfilePersonalInfoController {
    onBack?: () => void;
}

interface ProfileFormValueType {
    fullname: string;
    cpf: string;
    phone: string;
    email: string;
    zip_code: string;
    address: string;
    number: string | undefined;
    complement: string | undefined;
    neighborhood: string;
    city: string;
    uf: string;
}

export default function useProfilePersonalInfoController({
    onBack
}: IProfilePersonalInfoController) {
    const { purchase, dispatch } = usePurchaseState();
    const updateUserUsecase = useUpdateUserUsecase();
    const getAddressByCep = useGetAddressByCepUsecase();
    const { currentUser, authMethod } = useAuthentication();

    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [initialValue, setInitialValue] = useState<
        ProfileFormValueType | undefined
    >(undefined);
    const [initializing, setInitializing] = useState(true);
    const [withoutNumber, setWithoutNumber] = useState(false);
    const [withoutComplement, setWithoutComplement] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const form = useFormik<ProfileFormValueType>({
        initialValues: {
            fullname: '',
            cpf: '',
            phone: '',
            email: '',
            zip_code: '',
            address: '',
            number: undefined,
            complement: undefined,
            neighborhood: '',
            city: '',
            uf: ''
        },
        validationSchema: Yup.object().shape({
            fullname: Yup.string().optional(),
            cpf: Yup.string().optional(),
            phone: Yup.string().optional(),
            email: Yup.string().optional(),
            zip_code: Yup.string()
                .required('O CEP é obrigatório!')
                .test('zip_code', 'CEP inválido!', (value) => {
                    const onlyNumbers = value.replace(/\D/g, '');
                    return zipCodeValidator(onlyNumbers);
                }),
            address: Yup.string().required('Endereço é obrigatório!'),
            number: Yup.number()
                .positive('O número tem que ser positivo')
                .test('number', 'Número é obrigatório!', (value) => {
                    if (withoutNumber) return true;
                    return value !== undefined;
                }),
            complement: Yup.string().test(
                'complement',
                'Complemento é obrigatório!',
                (value) => {
                    if (withoutComplement) return true;
                    return value !== undefined && value !== '';
                }
            ),
            neighborhood: Yup.string().required('Bairro é obrigatório!'),
            city: Yup.string().required('Cidade é obrigatório!'),
            uf: Yup.string().required('UF é obrigatório!')
        }),
        onSubmit: async (values) => {
            setLoadingUpdate(true);
            const success = await updateProfile(values);
            if (success) {
                const customer = {
                    cpf: purchase.customer?.cpf ?? '',
                    phone: purchase.customer?.phone ?? '',
                    ...purchase.customer,
                    address: new CustomerAddressEntity({
                        city: values.city,
                        complement: values.complement,
                        name: values.address,
                        neighborhood: values.neighborhood,
                        number: values.number,
                        zipcode: values.zip_code,
                        uf: values.uf
                    })
                };
                dispatch({ type: 'LOAD_CUSTOMER', customer: customer });
                onBack?.();
            }
            setLoadingUpdate(false);
        }
    });

    async function updateProfile(values: any): Promise<boolean> {
        const payload = {
            authUid: currentUser?.uid ?? '',
            authMethod: authMethod ?? '',
            email: currentUser?.email ?? '',
            fullname: values.fullname ?? '',
            profile: {
                cpf: values.cpf,
                phone: values.phone,
                address: {
                    cep: values.zip_code,
                    logradouro: values.address,
                    numero: values.number,
                    complemento: values.complement,
                    bairro: values.neighborhood,
                    localidade: values.city,
                    uf: values.uf
                }
            }
        };
        return updateUserUsecase
            .execute(payload)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    useEffect(() => {
        if (withoutComplement) {
            form.setFieldValue('complement', '');
            form.setTouched({
                ...form.touched,
                complement: true
            });
        }
        if (withoutNumber) {
            form.setFieldValue('number', '');
            form.setTouched({
                ...form.touched,
                number: true
            });
        }
    }, [withoutComplement, withoutNumber]);

    useEffect(() => {
        const user = purchase.customer;
        setInitializing(true);
        try {
            const userData = {
                fullname: user?.fullname || '',
                cpf: user?.cpf || '',
                email: currentUser?.email || '',
                phone: user?.phone || '',
                zip_code: user?.address?.zipcode || '',
                address: user?.address?.name || '',
                number: user?.address?.number || undefined,
                complement: user?.address?.complement || '',
                neighborhood: user?.address?.neighborhood || '',
                city: user?.address?.city || '',
                uf: user?.address?.uf || ''
            };
            setWithoutComplement(
                userData.complement === undefined || userData.complement === ''
            );
            setWithoutNumber(
                userData.number === undefined || userData.number === ''
            );
            setInitialValue(userData);
            form.setValues(userData, true);
        } finally {
            setInitializing(false);
        }
    }, [currentUser]);

    useEffect(() => {
        if (initialValue !== undefined) {
            setHasChanges(!isEquals(form.values, initialValue!));
        }
    }, [form.values]);

    async function onZipcode(zipCode: string) {
        if (zipCodeValidator(zipCode)) {
            try {
                const address = await getAddress(zipCode.replace(/\D/g, ''));
                form.setValues({
                    ...form.values,
                    zip_code: zipCode,
                    address: address.logradouro ?? '',
                    city: address.localidade ?? '',
                    complement:
                        zipCode === initialValue?.zip_code
                            ? initialValue.complement
                            : (address.complemento ?? ''),
                    neighborhood: address.bairro ?? '',
                    uf: address.uf ?? '',
                    number:
                        zipCode === initialValue?.zip_code
                            ? initialValue.number
                            : ''
                });

                if (zipCode === initialValue?.zip_code) {
                    setWithoutComplement(
                        initialValue?.complement === undefined ||
                            initialValue?.complement === ''
                    );
                    setWithoutNumber(
                        initialValue?.number === undefined ||
                            initialValue?.number === ''
                    );
                } else {
                    setWithoutComplement(false);
                    setWithoutNumber(false);
                }
            } catch {
                form.setValues({
                    ...form.values,
                    zip_code: zipCode,
                    address: '',
                    city: '',
                    number: '',
                    complement: '',
                    neighborhood: '',
                    uf: ''
                });
            }
        }
    }

    function getAddress(query: string) {
        return getAddressByCep
            .execute(query)
            .then((item: any) => ({
                cep: item.cep,
                logradouro: item.logradouro,
                bairro: item.bairro,
                complemento: item.complemento,
                localidade: item.localidade,
                uf: item.uf
            }))
            .catch((error) => {
                throw error;
            });
    }

    return {
        form,
        withoutNumber,
        setWithoutNumber,
        withoutComplement,
        setWithoutComplement,
        initializing,
        loadingUpdate,
        hasChanges,
        onZipcode
    };
}
