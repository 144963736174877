import { ReactNode, createContext, useContext } from "react";

import EncryptServiceImpl, { IEncryptService } from "core/service/encrypt.service";
import EncryptAesServiceImpl from "core/service/encrypt_aes.service";
import EncryptRsaServiceImpl from "core/service/encrypt_rsa.service";

const EncryptContext = createContext<IEncryptService | undefined>(undefined);

interface EncryptBindingProps {
  children: ReactNode;
}

export function useEncryptService(): IEncryptService {
  const context = useContext(EncryptContext);
  if (context === undefined) {
    throw new Error("EncryptService not found int context");
  }
  return context;
}

export default function EncryptBinding({ children }: EncryptBindingProps) {
  const encryptAesService = new EncryptAesServiceImpl();
  const encryptRsaService = new EncryptRsaServiceImpl({publicKeyPem: process.env.REACT_APP_PUBLIC_KEY ?? ''} );

  const encrypService = new EncryptServiceImpl(encryptRsaService, encryptAesService);
  
  return (
    <EncryptContext.Provider
      value={encrypService}
    >
      {children}
    </EncryptContext.Provider>
  );
}
