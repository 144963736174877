import Checkout from "features/checkout/";
import { ProductModel } from "models";

interface StepCreditCardFormProps {
    phoneNumber?: string;
    product?: ProductModel;
    imei?: string;
    onSuccess: () => void;
    onError: () => void;
}

function StepCreditCardForm({ phoneNumber, product, imei, onSuccess, onError }: StepCreditCardFormProps) {

    return (
        <Checkout
            phoneNumber={phoneNumber}
            product={product}
            imei={imei}
            onSuccess={onSuccess}
            onError={onError} />
    );
}
export default StepCreditCardForm;