import { IconFillCrown, IconOutArticle, IconOutLogout } from 'gosafe';
import { BackButton } from 'gosafe/molecules/buttons';
import { useDrawer } from 'gosafe/molecules/drawer/drawer_provider';
import DropdownMenu, { OptionMenuProps } from 'gosafe/molecules/dropdown_menu';
import PolicyDrawer from './policy_drawer';
import ProfileDrawer from './profile_drawer';
import { usePurchaseState } from '../purchase_state_context';

interface TestefoneHeaderThinProps {
    authenticated?: boolean;
    onSignOut?: () => void;
    onBack?: () => void;
}

export default function TestefoneHeaderThin({
    authenticated = true,
    onSignOut,
    onBack
}: TestefoneHeaderThinProps) {
    const { openDrawer } = useDrawer();
    const { purchase } = usePurchaseState();
    
    const className = ['flex justify-between items-center p-4'];

    if (authenticated) {
        className.push('bg-neutral-100');
    } else {
        className.push('bg-primary');
    }

    const optionsMenu: OptionMenuProps[] = [
        {
            title: 'Perfil',
            icon: <IconFillCrown />,
            iconPosition: 'left',
            enabled: true,
            onTap: () => {
                openDrawer(<ProfileDrawer />);
            }
        },
        {
            title: 'Detalhes do seguro',
            icon: <IconOutArticle />,
            iconPosition: 'left',
            enabled: purchase.lastOrder !== undefined,
            onTap: () => {
                openDrawer(<PolicyDrawer openStep={1} />);
            }
        },
        {
            title: 'Sair',
            icon: <IconOutLogout />,
            enabled: true,
            iconPosition: 'right',
            onTap: () => {
                onSignOut?.();
            }
        }
    ];

    return (
        <div data-testid="TestefoneHeaderThin" className={className.join(' ')}>
            <div className="inline md:hidden">
                <BackButton
                    color={authenticated ? 'text-primary' : 'text-neutral-100'}
                    onClick={onBack}
                    padding="16px 16px"
                />
            </div>
            <div>
                <a href="/">
                    <img
                        src={`/images/${authenticated ? 'logo-testefone-light' : 'logo-testefone'}.png`}
                        alt="Logo testefone"
                        width={160}
                    />
                </a>
            </div>
            {authenticated && <DropdownMenu optionsMenu={optionsMenu} />}
        </div>
    );
}
