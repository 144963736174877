import * as Sentry from "@sentry/react";
import { IHttpClient } from "core/protocols/http_client";

export interface IValidadeCodeUsecase {
    execute: (phoneNumber: string, code: string) => Promise<boolean>;
}

class ValidateCodeUsecaseImpl implements IValidadeCodeUsecase {
    private httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }

    async execute(phoneNumber: string, code: string): Promise<boolean> {
        return this.httpClient.post<any>(`/bff/user/sms/validate`, {
            msisdn: phoneNumber.replace(/\D/g, ""),
            code: parseInt(code),
        }).then(async response => {
            return true;
        }).catch(error => {
            Sentry.captureException(error, {
                tags: {
                    'feature.ownership_validation': true,
                    'api.endpoint': '/bff/user/sms/validate',
                },
                extra: {
                    phoneNumber: phoneNumber,
                    code: code,
                    info: 'Error in ValidateCodeUsecaseImpl execute method',
                },
            });
            return false;
        });
    }
}


export default ValidateCodeUsecaseImpl;
