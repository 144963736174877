import React from "react";
import ReactDOM from "react-dom/client";

import { RecoilRoot } from "recoil";
import { SplashPage } from "./pages/splash";

import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";

import * as Sentry from "@sentry/react";
import { SentryContext } from "monitoring/sentry_context";
import "./assets/css/import.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <SentryContext.Provider value={Sentry}>
    <RecoilRoot>
      <React.StrictMode>
        <SplashPage />
        <Routes />
      </React.StrictMode>
    </RecoilRoot>
  </SentryContext.Provider>,
);

reportWebVitals();
