import { cn } from "core/utils/cn";
import { MouseEventHandler } from "react";

interface CardWrapperProps {
  children?: React.ReactNode;
  onTap?: MouseEventHandler<HTMLDivElement>;
  selected?: boolean;
  className?: string; // Add custom class names here if needed.
}

export default function CardWrapper({
  children,
  selected,
  onTap,
  className,
}: CardWrapperProps) {

  return (
    <div data-testid='card-wrapper' className={cn(`bg-neutral-100 rounded-3xl shadow-md ${onTap && 'cursor-pointer'} ${selected && 'ring-4 ring-blue-light'}`, className)} onClick={onTap}>
      {children}
    </div>
  );
}
