export default function IconArrowRightLight() {
    return (
        <svg
            width="6"
            height="10"
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.487646 9.48824C0.270844 9.26005 0.281936 8.90106 0.51242 8.68641L4.47299 4.99804L0.513546 1.31409C0.282964 1.09955 0.271707 0.740565 0.488403 0.512278C0.705099 0.28399 1.06769 0.272845 1.29827 0.487384L5.33608 4.24425C5.77499 4.65262 5.77515 5.34287 5.33642 5.75144L1.29753 9.51276C1.06704 9.72741 0.704448 9.71642 0.487646 9.48824Z"
                fill="#004BC1"
            ></path>
        </svg>
    );
}
