interface ListViewProps {
  children: any;
}

const ListView = ({ children }: ListViewProps) => {
  const className = ["flex", "flex-col", `gap-4`];

  return <div className={className.join(" ")}>{children}</div>;
};

export default ListView;
