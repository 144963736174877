import { createContext, useContext } from "react";
import { ISendCodeByEmailUsecase } from "./domain/usecases/send_code_by_email";

const SendCodeByEmailContext = createContext<ISendCodeByEmailUsecase | undefined>(undefined);

export function useSendCodeByEmailUsecase() {
    const context = useContext(SendCodeByEmailContext);
    if (!context) {
        throw new Error('ISendCodeByEmailUsecase not found');
    }
    return context;
}

export default SendCodeByEmailContext;