function IconArrowRight() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6975 14.5975C10.4208 14.8802 10.4208 15.3385 10.6975 15.6212C10.9742 15.9038 11.4228 15.9038 11.6995 15.6212L16.2882 10.9332C16.7928 10.4177 16.7928 9.58196 16.2882 9.06649L11.6995 4.37851C11.4228 4.09583 10.9742 4.09583 10.6975 4.37851C10.4208 4.66119 10.4208 5.1195 10.6975 5.40218L14.4893 9.27599L4.04183 9.27599C3.65053 9.27599 3.33331 9.60007 3.33331 9.99984C3.33331 10.3996 3.65053 10.7237 4.04183 10.7237L14.4893 10.7237L10.6975 14.5975Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconArrowRight;
