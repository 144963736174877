import * as Sentry from "@sentry/react";
import React from "react";

Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.5,
    sampleRate: 0.1,
  });

export const SentryContext = React.createContext(Sentry);
