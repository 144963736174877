import { HttpResponse, IHttpClient } from 'core/protocols/http_client';
import { OrdersResponse } from '../entities/order_entity';

export interface IGetAllOrdersUseCase {
    execute(): Promise<OrdersResponse>;
}

export default class GetAllOrdersUseCaseImpl implements IGetAllOrdersUseCase {
    private httpClient: IHttpClient;
    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }
    async execute(): Promise<OrdersResponse> {
        try {
            const { data }: HttpResponse<OrdersResponse> =
                await this.httpClient.get<OrdersResponse>(`/orders`);
            if (data.data.length == 0) {
                throw new Error('No policies to show');
            }
            return data;
        } catch (error) {
            console.error(error);
            throw new Error(
                `Error while trying to obtain the user's latest policy`
            );
        }
    }
}
