import { Button } from "@headlessui/react";
import { IconArrowLeft, IconOutClose, Text } from "gosafe/atoms";

interface DrawerHeaderProps {
  showBackButton?: boolean;
  showCloseButton?: boolean;
  title?: string;
  onBackButton?: () => void;
  onClose?: () => void;
}

export default function DrawerHeader({
  showBackButton,
  showCloseButton = true,
  onBackButton,
  title,
  onClose,
}: DrawerHeaderProps) {
  return (
    <div className="flex w-full py-5 bg-neutral-90">
      <div className="flex w-1/6">
        {showBackButton && (
          <Button
            onClick={onBackButton}
            className="flex items-center justify-center rounded px-3 py-2 text-sm data-[active]:bg-neutral-70 data-[hover]:bg-neutral-80"
          >
            <IconArrowLeft />
          </Button>
        )}
      </div>
      <div className="flex w-4/6 items-center justify-center">
        {title && (
          <Text
            color="text-neutral-40"
            fontWeight="font-semibold"
            fontSize="text-lg"
          >
            {title}
          </Text>
        )}
      </div>
      <div className="flex w-1/6 justify-end pr-3">
        {showCloseButton && (
          <Button
            onClick={onClose}
            className="flex items-center justify-center rounded px-2 py-2 text-sm data-[active]:bg-neutral-70 data-[hover]:bg-neutral-80"
          >
            <IconOutClose />
          </Button>
        )}
      </div>
    </div>
  );
}
