import ERROR from './images/error.png'
import style from './style.module.css'

const Failed = ({ details = '', repeat_process = true }) => {
    return (
        <>
            <div className={style.content_center}>
                <img src={ERROR} alt="Imagem error" />
                <p>{details === '' ? 'Tivemos um problema com o processamento do seu cartão. Acesse o aplicativo do seu banco para mais detalhes e tente novamente mais tarde.' : details}</p>
                {repeat_process && <button>Tentar novamente</button>}
            </div>
        </>
    )
}

export default Failed