import { MouseEventHandler } from "react";

interface CheckboxProps {
  key?: string;
  onTap?: MouseEventHandler<HTMLDivElement>;
  value?: boolean;
}

export default function Checkbox({ key, value = false, onTap }: CheckboxProps) {
  const className = ["w-4 h-4 rounded cursor-pointer"];

  if (value) {
    className.push("bg-blue-dark");
  } else {
    className.push("border-2 border-neutral-80");
  }

  return (
    <div key={key} className={className.join(" ")} onClick={onTap}>
      {value && (
        <div className="flex h-4 w-4 items-center justify-center">
          <svg
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.68102 0.309983C8.07154 0.700507 8.07154 1.33367 7.68102 1.7242L3.84079 5.56443C3.28288 6.12234 2.37834 6.12235 1.82041 5.56445L0.318788 4.06289C-0.071745 3.67238 -0.0717593 3.03921 0.318756 2.64868C0.709272 2.25815 1.34244 2.25813 1.73297 2.64865L2.83058 3.74621L6.26681 0.309983C6.65733 -0.0805413 7.2905 -0.0805413 7.68102 0.309983Z"
              fill="#FDFDFD"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
