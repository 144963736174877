import { IHttpClient } from "core/protocols/http_client";
import { CustomerEntity } from "../entities/customer_entity";
import * as Sentry from "@sentry/react";

export interface IGetUserUsecase {
  execute(uid: string): Promise<CustomerEntity>;
}


export default class GetUserUsecaseImpl implements IGetUserUsecase {
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  async execute(uid: string): Promise<CustomerEntity> {
    return this.httpClient.get<any>(`/user/${uid}`).then((result) => {

      const user = result.data;
      const customer = user.customer;
      const address = customer.address;
      let msisdns = [];
      let emails = [];

      if (user.msisdnList) {
        msisdns = user.msisdnList.map((msisdn: any) => {
          return {
            msisdn: msisdn.msisdn,
            validated: msisdn.validated,
            validatedAt: msisdn.validated_at
          };
        });
      }

      if (user.emailList) {
        emails = user.emailList.map((email: any) => {
          return {
            email: email.email,
            validated: email.validated
          };
        });
      }

      return new CustomerEntity({
        birthday: customer.birthday,
        cpf: customer.cpf ?? user.cpf,
        phone: customer.phoneNumber,
        msisdns: msisdns,
        emails: emails,
        have_ticket: user.haveTicket,
        fullname: customer.name ?? user.fullName,
        address: address ? {
          city: address.city,
          complement: address.complement,
          name: address.street,
          neighborhood: address.neighborhood,
          number: address.number,
          zipcode: address.zipcode,
          uf: address.state
        } : undefined
      });
    }).catch((error) => {
      Sentry.captureException(error, {
        tags: {
          'feature.purchase': "GetUserUsecaseImpl",
          'api.endpoint': `/bff/v1/user`,
        },
      });
      throw error;
    });
  }
}