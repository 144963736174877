class PaymentOutputEntity {
    policyNumber?: string;
    policyUrl?: string;
    state?: string;
    imei?: string;
    createdAt?: string;
    endValidity?: string;
    startValidity?: string;

    constructor(args: {
        createdAt?: string,
        endValidity?: string,
        policyNumber?: string,
        policyUrl?: string,
        productName?: string,
        startValidity?: string,
        state?: string,
        imei?: string,
    }) {
        this.createdAt = args.createdAt;
        this.endValidity = args.endValidity;
        this.policyNumber = args.policyNumber;
        this.policyUrl = args.policyUrl;
        this.startValidity = args.startValidity;
        this.state = args.state;
        this.imei = args.imei;
    }

    static fromJson(json: any) {
        return new PaymentOutputEntity({
            imei: json.imei,
            createdAt: json.createdAt,
            endValidity: json.endValidity,
            policyNumber: json.policyNumber,
            policyUrl: json.policyUrl,
            startValidity: json.startValidity,
            state: json.state,
        });
    }
 }

export default PaymentOutputEntity;