export default function IconFillCrown() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6654 10.4C13.546 11.1745 13.332 11.8 12.332 11.8H3.66538C2.66538 11.8 2.45143 11.1745 2.33205 10.4L1.34311 4.2594C1.22975 3.55229 2.01194 3.09086 2.50482 3.57414L4.63466 5.6626C4.96437 5.98592 5.48716 5.89954 5.71152 5.48466L7.39349 2.37451C7.52854 2.12484 7.76361 2 7.99871 2C8.23382 2 8.46889 2.12484 8.60394 2.37451L10.2859 5.48466C10.5103 5.89954 11.0331 5.98592 11.3628 5.6626L13.4926 3.57414C13.9855 3.09086 14.7677 3.55229 14.6543 4.2594L13.6654 10.4Z"
        fill="#004BC1"
      />
      <path
        d="M3.23203 12.8667C2.91908 12.8667 2.66538 13.1204 2.66538 13.4333C2.66538 13.7463 2.91908 14 3.23203 14H12.7654C13.0783 14 13.332 13.7463 13.332 13.4333C13.332 13.1204 13.0783 12.8667 12.7654 12.8667H3.23203Z"
        fill="#004BC1"
      />
    </svg>
  );
}
