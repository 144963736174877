export default function IconChevronRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.89203 13.8047C4.59328 13.5444 4.59328 13.1223 4.89204 12.8619L10.4713 8.00011L4.89203 3.13807C4.59328 2.87771 4.59328 2.4556 4.89205 2.19526C5.19081 1.93491 5.67519 1.93492 5.97395 2.19527L11.5532 7.05732C12.1507 7.57802 12.1507 8.42224 11.5532 8.94293L5.97394 13.8047C5.67517 14.0651 5.19079 14.0651 4.89203 13.8047Z"
        fill="#004BB8"
      />
    </svg>
  );
}
