import { FooterSection, TryAgain } from "components";
import { BackButton, ButtonResendOption, Card, ExaLoading, IconArrowRight, InfinityLoading, SecondaryButton, TextFieldCode } from "gosafe";
import { BasePage } from "gosafe/templates";
import useEmailCheckController from "./email_check_controller";
import { FormikProvider } from "formik";
import TestefoneHeaderThin from "components/testefone_header_thin";
import Chatbot from "features/chatbot";

function EmailCheckPage() {
    const { form, count, loading, maskedEmail, verifing, sendCode, hasError } = useEmailCheckController();

    return (
        <BasePage
            footer={<FooterSection />}
            header={<TestefoneHeaderThin />}
        >
            <div className="content-center pb-8">
                <div className="hidden md:inline">
                    <div className="py-10">
                        <BackButton
                            onClick={() => { window.history.back(); }}
                        />
                    </div>
                </div>
                {loading ?
                    <div className='flex flex-col gap-4 justify-center items-center h-[500px] text-primary'>
                        <ExaLoading />
                    </div> : hasError ? <TryAgain
                        onClick={() => {
                            sendCode();
                        }}
                        title="Tentar novamente"
                        description="Não foi possível enviar o código de verificação. Tente novamente."
                    /> :
                        <div className="flex max-w-[1100px] flex-col gap-0 md:flex-row md:gap-40">
                            <div className="basis-1/2">
                                <div className="flex flex-col gap-4">
                                    <div className="text-blue-dark text-4xl font-medium">
                                        Tudo certo!<br></br>
                                        Identificamos sua conta
                                    </div>
                                    <div className="text-neutral-40 text-base">
                                        Você tem uma apólice associada ao e-mail:
                                    </div>
                                    <div>{maskedEmail}</div>
                                    <div className="text-neutral-40 text-base">Digite o código que enviamos para este e-mail para acessar seus detalhes de conta.</div>
                                    <div className="mt-4">
                                        <ButtonResendOption
                                            id="btn-reenviar-codigo"
                                            onClick={() => {
                                                if (count === 0) {
                                                    sendCode();
                                                }
                                            }}
                                            text="Reenviar código"
                                            icon={<img src="/images/icon-phone.svg" />} />
                                    </div>
                                    <div className="text-neutral-40 text-sm">Você pode solicitar um novo código em 90 segundos.</div>
                                </div>
                            </div>

                            <div className="basis-1/2">
                                <Card>
                                    <FormikProvider value={form}>
                                        <form>
                                            <TextFieldCode
                                                id='email-code'
                                                textLabel="Código de verificação"
                                                onCodeChange={(code) => {
                                                    form.setFieldValue('code', code.join(''));
                                                }}
                                                hintText="0"
                                                textHelper={form.errors.code}
                                                showHelper={form.errors.code !== undefined}
                                            ></TextFieldCode>
                                            <div className="py-4">
                                                <span className="text-neutral-30 text-xs ">Seu código vai expirar em {count} segundos</span>
                                            </div>
                                            <SecondaryButton
                                                showIconRight
                                                infinity
                                                enabled={form.isValid}
                                                iconRight={verifing ? <InfinityLoading
                                                    width={24}
                                                    strokeColor="text-primary"
                                                /> : <IconArrowRight />}
                                                text="Continuar"
                                                onClick={() => {
                                                    form.submitForm();
                                                }}
                                            ></SecondaryButton>
                                        </form>
                                    </FormikProvider>
                                </Card>
                            </div>
                        </div>}
            </div>
            <Chatbot primary={false} />
        </BasePage>
    );
}

export default EmailCheckPage;