import CertificateActive from 'features/checkout/presenter/componentes/certificate_ative';
import OrderEntity from 'features/purchase/domain/entities/order_entity';
export interface CertificateActiveProps {
    order: OrderEntity;
}

function StepCertificateActive({ order }: CertificateActiveProps) {
    return <CertificateActive order={order} />;
}

export default StepCertificateActive;
