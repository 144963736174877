import TrayAgainSection from 'features/checkout/presenter/componentes/try-again-section';
interface TryAgainProps {
    onClick: () => void;
}

function StepAwaitPayment({ onClick }: TryAgainProps) {
    return (
        <div className="content-center pb-8">
            <div className="flex max-w-[1100px] flex-col gap-0 md:flex-row md:gap-40">
                <TrayAgainSection onClick={onClick} />
            </div>
        </div>
    );
}

export default StepAwaitPayment;
