import {
    createContext,
    Dispatch,
    ReactNode,
    useContext,
    useReducer
} from 'react';
import purchaseReducer, {
    PurchaseState,
    PurchaseStateAction
} from './purchase_state';

const PurchaseStateContext = createContext<
    | { purchase: PurchaseState; dispatch: Dispatch<PurchaseStateAction> }
    | undefined
>(undefined);

export function usePurchaseState() {
    const context = useContext(PurchaseStateContext);
    if (context === undefined) {
        throw new Error('PurchaseStateContext not found');
    }
    return context;
}

export function PurchaseStateProvider({ children }: { children: ReactNode }) {
    const [purchase, dispatch] = useReducer(
        purchaseReducer,
        history.state?.usr?.purchase ?? new PurchaseState({})
    );

    return (
        <PurchaseStateContext.Provider value={{ purchase, dispatch }}>
            {children}
        </PurchaseStateContext.Provider>
    );
}
