import { createContext, useContext } from "react";
import { ISendPaymentUseCase } from "./domain/usecases/send_payment_usecase";
import * as Sentry from "@sentry/react";

const SendPaymentContext = createContext<ISendPaymentUseCase | undefined>(undefined);

export function useSendPaymentUsecase() {
    const context = useContext(SendPaymentContext);
    if (context === undefined) {
        const error = new Error('ISendPaymentUseCase not found');
        Sentry.captureException(error, {
            tags: {
                'feature.checkout': "useSendPaymentUsecase",
                "error.type": error.name
            }
        });
        console.error(error);
        throw error;
    }
    return context;
}

export default SendPaymentContext;