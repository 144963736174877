import { Button, Divider, Text } from 'gosafe';

interface PaymentInProcessingSectionProps {
    onRefresh: () => void;
}

const PaymentInProcessingSection = ({
    onRefresh
}: PaymentInProcessingSectionProps) => {
    return (
        <div className="py-10">
            <div className="flex flex-col md:flex-row items-center justify-center">
                <div className="flex-1 bg-blue-500 p-4 ">
                    <div className="content-center p-0 columns-1">
                        <header className="pb-5">
                            <Text fontSize="text-3xl" fontWeight="font-medium">
                                Aguarde.
                            </Text>
                            <Divider />
                            <Text fontSize="text-3xl" fontWeight="font-medium">
                                O pagamento está sendo processado.
                            </Text>
                        </header>
                        <Text fontSize="text-lg" color="text-neutral-10">
                            Assim que o pagamento for confirmado, você terá
                            acesso ao certificado que garante a segurança do seu
                            celular
                        </Text>
                    </div>
                </div>
                <div className="flex-1 pt-10">
                    <div className="flex flex-row items-center justify-center">
                        <img
                            className=""
                            width={300}
                            alt=""
                            src="/images/woman-talking-about-security.png"
                        />
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center mt-4 md:mt-0 pt-20">
                <div>
                    <Button
                        text="Atualizar"
                        color="text-neutral-100"
                        onClick={onRefresh}
                        backgroundColor="bg-primary"
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentInProcessingSection;
