import { ReactNode, createContext, useContext, useEffect, useState } from "react";

import { IHttpClient } from "core/protocols/http_client";
import { HttpClient } from "core/protocols/http_client_impl";
import { useAuthentication } from "features/authentication/authentication_bindings";
import { useFeatureToggle } from "features/feature_toogle/feature_toggle_bindings";
import { useEncryptService } from "./encrypt_binding";

const HttpContext = createContext<IHttpClient | undefined>(undefined);

export function useHttpClient(): IHttpClient {
  const context = useContext(HttpContext);
  if (context === undefined) {
    throw new Error("HttpClient not found int context");
  }
  return context;
}

interface HttpBindingProps {
  children: ReactNode;
}

function httpClientBuilder(baseUrl: string): IHttpClient | null {
  let { accessToken } = useAuthentication();
  accessToken = accessToken ?? undefined;

  const featureToggle = useFeatureToggle();
  const encryptService = useEncryptService();

  const [useEncryption, setUseEncryption] = useState<boolean | null>(null); // Inicialmente null para indicar carregamento

  useEffect(() => {
    const fetchFeatureToggle = async () => {
      const isEnabled = await featureToggle.isEnabled("encrypt_body");
      setUseEncryption(isEnabled);
    };

    fetchFeatureToggle();
  }, [featureToggle]);


  if (useEncryption === null) {
    return null;
  }

  return new HttpClient({
    baseUrl: baseUrl, //process.env.REACT_APP_BASE_URL as string,
    accessToken: accessToken,
    useEncryption: useEncryption,
    encryptService: encryptService
  });
}

export default function HttpBinding({ children }: HttpBindingProps) {
  const httpClient = httpClientBuilder(process.env.REACT_APP_BASE_URL as string);

  if (httpClient == null) {
    return null;
  }

  return (
    <HttpContext.Provider value={httpClient}>
      {children}
    </ HttpContext.Provider>
  );
}