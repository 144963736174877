function IconAmex() {
    return (
        <svg
            width="39"
            height="10"
            viewBox="0 0 39 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29095 0.0862122L0 9.58194H5.13687L5.77369 8.06793H7.22933L7.86615 9.58194H13.5204V8.4264L14.0242 9.58194H16.949L17.4529 8.40198V9.58194H29.2122L30.6421 8.10725L31.9809 9.58194L38.0208 9.59416L33.7163 4.86059L38.0208 0.0862122H32.0746L30.6827 1.53366L29.386 0.0862122H16.5934L15.4949 2.53714L14.3706 0.0862122H9.24449V1.20244L8.67424 0.0862122H4.29095ZM21.8511 1.43463H28.6038L30.6692 3.66559L32.8011 1.43463H34.8664L31.7284 4.85926L34.8664 8.24442H32.7074L30.6421 5.98754L28.4993 8.24442H21.8511V1.43463ZM23.5186 4.08927V2.84541V2.84422H27.7321L29.5707 4.83347L27.6507 6.83359H23.5186V5.47565H27.2025V4.08927H23.5186ZM5.2849 1.43463H7.78885L10.635 7.87373V1.43463H13.378L15.5764 6.05144L17.6024 1.43463H20.3317V8.24844H18.671L18.6574 2.90918L16.2363 8.24844H14.7507L12.316 2.90918V8.24844H8.89957L8.25187 6.72087H4.75263L4.10628 8.2471H2.27579L5.2849 1.43463ZM5.35015 5.30873L6.50301 2.5875L7.65453 5.30873H5.35015Z"
                fill="white"
            />
        </svg>
    );
}

export default IconAmex;
