import { PhoneEntity } from 'features/brand_and_model_search';
import { GetProductsUsecaseImpl } from 'features/purchase/domain/usecases/get_products_usecase';
import { ProductModel } from 'models';
import ProductList from '../product_list';

interface StepChooseProductProps {
    phone?: PhoneEntity;
    product?: ProductModel;
    onNext: (product: ProductModel) => void;
    setProduct: (product: ProductModel) => void;
}

function StepChooseProduct({
    phone,
    product,
    onNext,
    setProduct
}: StepChooseProductProps) {
    return (
        <ProductList
            phone={phone}
            getProducts={new GetProductsUsecaseImpl()}
            product={product}
            setProduct={setProduct}
            onTap={(product) => {
                onNext(product);
            }}
            data-testid="ProductList"
        />
    );
}

export default StepChooseProduct;
