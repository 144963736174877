export default function IconClose() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99992 13.6668C10.6818 13.6668 13.6666 10.6821 13.6666 7.00016C13.6666 3.31826 10.6818 0.333496 6.99992 0.333496C3.31802 0.333496 0.333252 3.31826 0.333252 7.00016C0.333252 10.6821 3.31802 13.6668 6.99992 13.6668ZM4.50251 4.50276C4.72819 4.27708 5.0941 4.27708 5.31978 4.50276L6.99992 6.1829L8.68006 4.50276C8.90574 4.27708 9.27164 4.27708 9.49732 4.50276C9.72301 4.72844 9.72301 5.09434 9.49732 5.32002L7.81718 7.00016L9.49732 8.6803C9.72301 8.90598 9.72301 9.27189 9.49732 9.49757C9.27164 9.72325 8.90574 9.72325 8.68006 9.49757L6.99992 7.81743L5.31978 9.49757C5.0941 9.72325 4.72819 9.72325 4.50251 9.49757C4.27683 9.27189 4.27683 8.90598 4.50251 8.6803L6.18265 7.00016L4.50251 5.32002C4.27683 5.09434 4.27683 4.72844 4.50251 4.50276Z"
        fill="currentColor"
      />
    </svg>
  );
}
