import { IHttpClient } from "core/protocols/http_client";
import { BrandAndModelFormSearch } from "features";
import { BrandEntity, PhoneEntity } from "features/brand_and_model_search";

interface StepChoosePhoneProps {
    httpClient: IHttpClient;
    httpSalesClient: IHttpClient;
    onNext: (brand: BrandEntity, phone: PhoneEntity) => void;
}

function StepChoosePhone({ httpClient, httpSalesClient, onNext }: StepChoosePhoneProps) {

    return (
        <div data-testid='form-search' className="rounded-2xl border-[1px] border-neutral-90 bg-neutral-100 p-10 shadow">
            <BrandAndModelFormSearch
                httpClient={httpClient}
                httpSalesClient={httpSalesClient}
                onTap={onNext}
            />
        </div>
    );
}

export default StepChoosePhone;