import { FooterSection } from "components";
import { BackButton, Card, IconArrowRight, InfinityLoading, SecondaryButton, TextField } from "gosafe";
import { BasePage } from "gosafe/templates";
import useCPFCaptureController from "./cpf_capture_controller";
import { Form, FormikProvider } from "formik";
import TestefoneHeaderThin from "components/testefone_header_thin";
import Chatbot from "features/chatbot";

const CPFCapturePage = () => {

    const { form, requesting, showInfo, goToPurchase } = useCPFCaptureController();

    return (
        <BasePage
            footer={<FooterSection />}
            header={<TestefoneHeaderThin />}
        >
            <div className="content-center pb-8">
                <div className="hidden md:inline">
                    <div className="py-10">
                        <BackButton
                            onClick={() => { window.history.back(); }}
                        />
                    </div>
                </div>
                <div className="flex max-w-[1100px] flex-col gap-0 md:flex-row md:gap-40">
                    <div className="basis-1/2">
                        <div className="flex flex-col gap-4">
                            <div className="font-medium text-blue-dark text-4xl">
                                Quase lá! Agora vamos configurar seu acesso.
                            </div>
                            <div className="text-neutral-40 text-base">
                                Insira seu CPF para que possamos localizar sua assinatura e personalizar ainda mais sua experiência.
                            </div>
                        </div>
                    </div>

                    <div className="basis-1/2">
                        <Card>
                            <FormikProvider value={form}>
                                <Form>
                                    <TextField
                                        textLabel="CPF"
                                        hintText="Digite seu CPF"
                                        name="cpf"
                                        onChange={form.handleChange}
                                        value={form.values.cpf}
                                        mask={"999.999.999-99"}
                                        showErros={form.touched.cpf}
                                        errorMessage={form.errors.cpf}
                                    ></TextField>
                                    <div className="text-neutral-40 text-sm py-4">
                                        {showInfo &&
                                            <span>
                                                Não existe nenhuma apólice associada a este CPF.<br></br>
                                                Faça uma cotação para proteger seu celular agora.
                                            </span>
                                        }
                                    </div>
                                    <SecondaryButton
                                        showIconRight
                                        infinity
                                        enabled={form.isValid}
                                        iconRight={requesting ? <InfinityLoading
                                            width={24}
                                            strokeColor="text-primary"
                                        /> : <IconArrowRight />}
                                        text={showInfo ? "Cotar agora" : "Continuar"}
                                        onClick={() => {
                                            if (showInfo) {
                                                goToPurchase();
                                            } else {
                                                form.submitForm();
                                            }
                                        }}
                                    ></SecondaryButton>
                                </Form>
                            </FormikProvider>
                        </Card>
                    </div>
                </div>
            </div>
            <Chatbot primary={false} />
        </BasePage>
    );
};

export default CPFCapturePage;