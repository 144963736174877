import { useHttpSalesClient } from 'bindings/http_sales_binding';
import { getAuth } from 'firebase/auth';
import { decompressFromEncodedURIComponent } from 'lz-string';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFirebase } from '../../../bindings/firebase_binding';
import { useHttpClient } from '../../../bindings/http_binding';
import { useAuthentication } from '../../authentication/authentication_bindings';
import { CustomerEntity, Email } from '../domain/entities/customer_entity';
import PolicyEntity from '../domain/entities/order_entity';
import { useGetLastPolicyContext } from '../get_last_policy_context';
import { PurchaseState } from './purchase_state';
import { usePurchaseState } from './purchase_state_context';
import { useGetUserUsecase } from '../get_user_context';
import { useCreateUserUsecase } from '../create_user_context';
import { useCheckDetachedOrderUsecase } from '../check_detached_order_context';
import { CreateUserInput } from '../domain/usecases/create_user_usecase';
import * as Sentry from '@sentry/react';

function setChooseProductStep(): void {
    const state = history.state.usr?.purchase as PurchaseState;
    history.state.usr.purchase = new PurchaseState({
        ...state,
        currentStep: 'CHOOSE_PRODUCT'
    });
}

function setAppState(): void {
    const { appState } = useParams();

    if (appState) {
        const decompressedAppState =
            decompressFromEncodedURIComponent(appState);
        history.replaceState(
            JSON.parse(decompressedAppState),
            '',
            `${window.location.origin}/purchase`
        );
        setChooseProductStep();
    }
}

export function usePurchasePageController() {
    const app = useFirebase();
    const auth = getAuth(app);
    const [searchParams] = useSearchParams();
    const getUserUsecase = useGetUserUsecase();
    const getLastPolicyUseCase = useGetLastPolicyContext();
    const createUser = useCreateUserUsecase();
    const checkDetachedOrder = useCheckDetachedOrderUsecase();
    const { currentUser, isLogged, authMethod } = useAuthentication();
    const [loading, setLoading] = useState(true);
    const [showDialog, setShowDialog] = useState(searchParams.get('info') === 'dialog');
    setAppState();
    const { purchase, dispatch } = usePurchaseState();

    const navigate = useNavigate();
    const httpClient = useHttpClient();
    const httpSalesClient = useHttpSalesClient();

    useEffect(() => {
        navigate('/purchase', { state: { purchase } });
    }, [purchase]);

    const getUser = async (uid: string): Promise<CustomerEntity> => {
        try {
            const user = await getUserUsecase.execute(uid);
            return user;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    const getLastPolicy = async (): Promise<PolicyEntity | undefined> => {
        try {
            const policy: PolicyEntity = await getLastPolicyUseCase.execute();
            return policy;
        } catch (e) {
            return undefined;
        }

    };

    const hasInvalidEmail = (emails: Email[]) => {
        return emails.some((email) => !email.validated);
    };

    const fetchData = async () => {
        setLoading(true);

        if (!isLogged) {
            setLoading(false);
            return;
        }
        try {
            const user: CustomerEntity = await getUser(currentUser!.uid);
            const cpfFromUrlParam = searchParams.get('cpf');
            const cpf = user.cpf ?? cpfFromUrlParam;
            if (!cpf) {
                navigate('/cpf-capture');
                return;
            }
            if (!hasInvalidEmail(user.emails ?? [])) {
                const hasDetachedOrder = await checkDetachedOrder.execute(cpf.replaceAll('.', '').replace('-', ''));
                const updatedUser = { ...user, cpf: cpfFromUrlParam ? cpfFromUrlParam : user.cpf };
                if (!hasDetachedOrder) {
                    const lastPolicy = await getLastPolicy();
                    if (!lastPolicy) {
                        dispatch({ type: 'LOAD_CUSTOMER', customer: updatedUser });
                        return;
                    }
                    if (lastPolicy.state === 'suspended') {
                        dispatch({
                            type: 'SET_TICKET_INACTIVE',
                            customer: updatedUser,
                            lastOrder: lastPolicy
                        });
                        return;
                    }
                    if (lastPolicy.state === 'active') {
                        dispatch({
                            type: 'SET_TICKET_ENABLED',
                            customer: updatedUser,
                            lastOrder: lastPolicy
                        });
                        return;
                    }
                    if (lastPolicy.state === 'payment_pending') {
                        dispatch({ type: 'SET_PAYMENT_SUCCESS', customer: updatedUser });
                        return;
                    }
                    dispatch({ type: 'LOAD_CUSTOMER', customer: updatedUser });
                }
                navigate('/cpf-capture');
            } else {
                navigate('/email-check');
            }
        } catch (error) {
            await createUser.execute(new CreateUserInput({
                authMethod: authMethod ?? '',
                authUid: currentUser?.uid ?? '',
                email: currentUser?.email ?? '',
            })).then(() => {
                navigate('/cpf-capture');
            }).catch((e) => {
                Sentry.captureException(e, {
                    tags: {
                        'feature.purchase': "PurchasePageController",
                        'component': 'usePurchasePageController',
                    },
                });
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [isLogged]);

    const currentStep = purchase.currentStep;

    return {
        purchase,
        loading,
        currentStep,
        dispatch,
        httpClient,
        httpSalesClient,
        auth,
        navigate,
        fetchData,
        isLogged,
        showDialog,
        setShowDialog,
        email: currentUser?.email,
    };
}
