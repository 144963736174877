import Chatbot from "features/chatbot";
import { FooterSection, TryNowSection } from "components";
import { ISignIn } from "core/authentication/sign_in";
import { BasePage } from "gosafe/templates";
import UrlApps from "pages/landing_page/url_apps";
import TestefoneHeaderThin from "./components/testefone_header_thin";
import CustomerPageProvider from "./sign_in_page_provider";
import TesteFoneViwer from "./components/testefone_view";

interface SignInPageProps {
  signWithGoogle: ISignIn;
  signWithApple: ISignIn;
  signWithEmailAndPassword: ISignIn;
}

function SignInPage({ signWithGoogle, signWithApple, signWithEmailAndPassword  }: SignInPageProps) {
  return (
    <CustomerPageProvider data-testid="CustomerPageProvider">
      <BasePage
        header={<TestefoneHeaderThin data-testid="TestefoneHeaderThin" />}
        data-testid="BasePage"
      >
        <TesteFoneViwer signWithGoogle={signWithGoogle} signWithApple={signWithApple} signWithEmailAndPassword={signWithEmailAndPassword} />
        <TryNowSection
          title="Baixe o app e receba ofertas especiais"
          urlApps={
            new UrlApps(
              "https://play.google.com/store/apps/details?id=br.com.exa.testefone.testefone",
              "https://apps.apple.com/br/app/testefone-avalie-seu-celular/id6459995038?l=en-GB"
            )
          }
        />
        <FooterSection />
        <Chatbot primary={false} />
      </BasePage>
    </CustomerPageProvider>
  );
}

export default SignInPage;
