import {createContext, useContext} from "react";
import {IGetShortUrlUseCase} from "./domain/usecases/get_short_url_usecase";

export const GetShortUrlContext = createContext<IGetShortUrlUseCase | undefined>(undefined);

export function useGetShortUrlUseCase(){
    const useCase = useContext(GetShortUrlContext);
    if(!useCase){
        throw new Error("useGetShortUrlUseCase must be used within a GetShortUrlContext.Provider");
    }
    return useCase;
}