interface BasePageProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
}

export default function BasePage({ header, footer, children }: BasePageProps) {
  return (
    <div className="flex min-h-screen flex-col">
      <header className="bg-gray-800 text-white">{header}</header>
      <main
        id="base-page-container"
        className="flex-grow bg-neutral-90"
      >
        {children}
      </main>
      <footer className="bg-gray-800 text-white">{footer}</footer>
    </div>
  );
}
