import { ReactNode } from "react";

interface ListTileProps {
  id?: string;
  trealing?: ReactNode;
  leading?: ReactNode;
  children?: ReactNode;
  crossAxisAlignment?: "items-start" | "items-center" | "items-end";
  padding?: string;
}

const ListTile = ({
  id,
  trealing,
  leading,
  children,
  crossAxisAlignment = "items-center",
  padding = "p-0",
}: ListTileProps) => {
  return (
    <div
      id={id}
      data-testid="ProductListItem"
      className={`flex flex-row gap-[11px] ${crossAxisAlignment} ${padding}`}
    >
      {trealing && <div>{trealing}</div>}
      <div className="grow">{children}</div>
      <div>{leading}</div>
    </div>
  );
};

export default ListTile;
