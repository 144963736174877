import { useFirebase } from "bindings/firebase_binding";

import SignInWithAppleUsecase from "./domain/usecases/signin_with_apple_usecase";
import SignInWithGoogleUsecase from "./domain/usecases/signin_with_google_usecase";
import SignInWithEmailAndPasswordUsecase from "./domain/usecases/signin_with_email_and_password_usecase";
import SignInPage from "./presenter/sign_in_page";

export { default as AuthenticationBindings } from "./authentication_bindings";
export { default as AuthenticationMidleware } from "./authentication_midleware";

function Authentication() {
  const app = useFirebase();
  return (
    <SignInPage
      signWithApple={new SignInWithAppleUsecase(app)}
      signWithGoogle={new SignInWithGoogleUsecase(app)}
      signWithEmailAndPassword={new SignInWithEmailAndPasswordUsecase(app)}
    />
  );
}

export default Authentication;
