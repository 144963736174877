import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSendCodeByEmailUsecase } from "../send_code_by_email_context";
import { useVerifyEmailCodeUsecase } from "../verify_email_code_context";
import { useAuthentication } from "features/authentication/authentication_bindings";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";

interface EmailCheckFormType {
    code: string;
}

function useEmailCheckController() {

    const sendCodeByEmailUsecase = useSendCodeByEmailUsecase();
    const verifyEmailCodeUsecase = useVerifyEmailCodeUsecase();
    const navigate = useNavigate();
    const { currentUser } = useAuthentication();
    const [count, setCount] = useState(90);
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [verifing, setVerifing] = useState(false);
    const [maskedEmail, setMaskedEmail] = useState('');

    const form = useFormik<EmailCheckFormType>({
        initialValues: {
            code: ''
        },
        validationSchema: Yup.object().shape({
            code: Yup.string().required('O código é obrigatório!')
                .length(6, 'O código deve ter 6 dígitos!')
        }),
        onSubmit: async (values) => {
            const userId = currentUser?.uid;
            setVerifing(true);
            verifyEmailCodeUsecase.execute(userId ?? '', maskedEmail, values.code).then(() => {
                navigate('/purchase?info=dialog');
            }).catch((error) => {
                const status = error.response?.status;
                switch (status) {
                    case 401:
                        form.setFieldError('code', 'O código está incorreto');
                        break;
                    case 400:
                        form.setFieldError('code', 'Código expirado. Clique em Reenviar código para receber um novo.');
                        break;
                    default:
                        Sentry.captureException(error, {
                            tags: {
                                'feature.email_check': "EmailCheckController",
                                'component': 'useEmailCheckController',
                            },
                        });
                }
            }).finally(() => {
                setVerifing(false);
            });
        }
    });

    function sendCode() {
        setLoading(true);
        sendCodeByEmailUsecase.execute().then((maskedEmail) => {
            setMaskedEmail(maskedEmail);
            setLoading(false);
            setCount(90);
            countdown();
        }).catch(() => {
            setLoading(false);
            setHasError(true);
        });
    }

    useEffect(() => {
        sendCode();
    }, []);

    const countdown = () => {
        const timer = setInterval(() => {
            setCount((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return timer;
    };

    return {
        form,
        count,
        loading,
        maskedEmail,
        verifing,
        sendCode,
        hasError
    };
}

export default useEmailCheckController;