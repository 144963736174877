import { Text } from "gosafe/atoms";
import CardPost from "gosafe/molecules/cards/card_post";

const WhyHire = () => {
  const cards = [
    {
      title: "Sem Carência",
      image: "/images/without_waiting_insurance.png",
      showBodyText: true,
      bodyText: "Cobertura imediata após a confirmação da assinatura",
    },
    {
      title: "Sem multa",
      image: "/images/people_with_stakeboards_banner.png",
      showBodyText: true,
      bodyText: "Você não paga nada para cancelar sua assinatura",
    },
    {
      title: "100% online",
      image: "/images/woman_verified_devices_banner.png",
      showBodyText: true,
      bodyText: "Mais comodidade pra você. Processo rápido e fácil de fazer",
    },
    {
      title: "Pagamento recorrente",
      image: "/images/man_protected_money_banner.png",
      showBodyText: true,
      bodyText: "Sem comprometer o limite do seu cartão de crédito",
    },
  ];

  return (
    <div className="content-center">
      <div className="mt-24 py-16 text-center text-4xl ">
        <Text
          color="text-neutral-40"
          fontSize="text-4xl"
          fontWeight="font-medium"
        >
          Por que contratar?
        </Text>
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {cards.map((card, index) => (
          <div key={`reason-${index}`} className="flex">
            <CardPost {...card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyHire;
