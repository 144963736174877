export default function IconOutLogout() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3682 1.33317C2.96079 1.33317 2.61203 1.47823 2.3219 1.76835C2.03178 2.05848 1.88672 2.40724 1.88672 2.81465L1.88672 13.185C1.88672 13.5924 2.03178 13.9412 2.3219 14.2313C2.61203 14.5214 2.96079 14.6665 3.3682 14.6665H7.81264C8.22174 14.6665 8.55339 14.3349 8.55339 13.9258C8.55339 13.5167 8.22175 13.185 7.81264 13.185H3.3682L3.3682 2.81465H7.81264C8.22174 2.81465 8.55339 2.48301 8.55339 2.07391C8.55339 1.66481 8.22175 1.33317 7.81264 1.33317H3.3682ZM12.0536 4.83335C11.7592 4.53899 11.28 4.54535 10.9936 4.84742C10.7177 5.13837 10.7238 5.59613 11.0073 5.87965L12.3867 7.2591H7.0719C6.6628 7.2591 6.33116 7.59074 6.33116 7.99984C6.33116 8.40894 6.6628 8.74058 7.0719 8.74058H12.3867L11.0073 10.12C10.7238 10.4035 10.7177 10.8613 10.9936 11.1523C11.28 11.4543 11.7592 11.4607 12.0536 11.1663L14.2772 8.94265C14.7979 8.42195 14.7979 7.57773 14.2772 7.05703L12.0536 4.83335Z"
        fill="#004BC1"
      />
    </svg>
  );
}
