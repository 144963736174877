import { createContext, useContext } from "react";

import { IGetBrandsByNameUsecase } from "./domain";

const GetBrandsByNameContext = createContext<
  IGetBrandsByNameUsecase | undefined
>(undefined);

export function useGetBrandsByNameUsecase() {
  const context = useContext(GetBrandsByNameContext);
  if (context === undefined) {
    throw new Error("IGetBrandsByNameUsecase not found");
  }
  return context;
}

export default GetBrandsByNameContext;
