function isEquals(a: object, b: object): boolean {
    const keysA = Object.keys(a) as Array<keyof typeof a>;
    const keysB = Object.keys(b) as Array<keyof typeof b>;

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (const key of keysA) {
        if (a[key] !== b[key]) {
            return false;
        }
    }

    return true;
}

export default isEquals;
