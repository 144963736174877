export default class PlanModel {
  id: number;
  code: string;
  name: string;
  description: string;

  constructor(
    code: string,
    id: number,
    name: string,
    description: string
    
  ) {
    this.code = code || '';
    this.id = id || 0;
    this.name = name || '';
    this.description = description || '';
  }
}
