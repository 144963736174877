import { ReactNode } from "react";

function Card({ children }: { children: ReactNode }) {

    return (
        <div className="rounded-2xl border-[1px] border-neutral-90 bg-neutral-100 p-10 shadow">
            {children}
        </div>
    );
}

export default Card;