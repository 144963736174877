import { IHttpClient } from "core/protocols/http_client";
import * as Sentry from "@sentry/react";


export interface ISendCodeByEmailUsecase {
    execute(): Promise<string>
}

export class SendCodeByEmailUsecaseImpl implements ISendCodeByEmailUsecase {
    private httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }

    async execute(): Promise<string> {
        const endpoint = '/otp/send-otp';
        return this.httpClient.post<{ maskedEmail: string }>(endpoint).then((response) => {
            const { maskedEmail } = response.data;
            return maskedEmail;
        }).catch((error) => {
            Sentry.captureException(error, {
                tags: {
                    'feature.email_check': "SendCodeByEmailUsecase",
                    'api.endpoint': endpoint,
                },
            });
            throw error;
        });
    }
}