import { IHttpClient } from 'core/protocols/http_client';

import * as Sentry from '@sentry/react';
import AddressEntity from '../entities/address_entity';

export interface IGetAddressByCepUsecase {
    execute(cep: string): Promise<AddressEntity>;
}

export class GetAddressByCepUsecaseImpl implements IGetAddressByCepUsecase {
    private httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }

    async execute(cep: string): Promise<AddressEntity> {
        return this.httpClient
            .get<AddressEntity>(`/bff/v1/cep/${cep}`)
            .then(async (response) => {
                return response.data;
            })
            .catch((error) => {
                Sentry.captureException(error, {
                    tags: {
                        'feature.profile': 'GetAddressByCepUsecaseImplPurchase',
                        'api.endpoint': `/bff/v1/cep/${cep}`
                    }
                });
                throw error;
            });
    }
}
