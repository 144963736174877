import { Transition, TransitionChild } from "@headlessui/react";
import { ReactNode, useEffect } from "react";

export default function Drawer({ open, onClose, children }: {
    open: boolean,
    onClose: () => void,
    children: ReactNode
}) {

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    });

    return (
        <Transition
            show={open}

        >
            <TransitionChild
                enter="transition ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={`absolute top-[76px] md:top-[68px] left-0 w-full h-[calc(100vh-76px)] md:h-[calc(100vh-68px)]`}>
                    <div className="flex bg-neutral-20/50">
                        <div className="h-[calc(100vh-76px)] md:h-[calc(100vh-68px)]" />
                    </div>
                </div>
            </TransitionChild>
            <TransitionChild
                enter="transition ease-in-out duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300"
                leaveFrom="translate-x-0" leaveTo="translate-x-full"
            >
                <div className={`absolute top-[76px] md:top-[68px] left-0 w-full h-[calc(100vh-76px)] md:h-[calc(100vh-68px)]`}>
                    <div className="flex">
                        <div className="grow" onClick={onClose} />
                        <div className="h-[calc(100vh-76px)] md:h-[calc(100vh-68px)] max-sm:w-3/4 max-md:w-2/4 md:w-1/4 bg-neutral-90 overflow-y-auto">
                            {children}
                        </div>
                    </div>
                </div>
            </TransitionChild>
        </Transition>
    );
}
