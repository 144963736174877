import { ReactNode, createContext, useContext } from "react";

import AuthState, { IAuthState } from "core/authentication/auth_state";

const AuthenticationContext = createContext<IAuthState | undefined>(undefined);

export function useAuthentication(): IAuthState {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error("Authentication not found in context");
  }
  return context;
}

interface AuthenticationBindingsProps {
  children: ReactNode;
}

function AuthenticationBindings({ children }: AuthenticationBindingsProps) {
  return (
    <AuthenticationContext.Provider value={AuthState({ loading: true })}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationBindings;
