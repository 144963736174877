import { IHttpClient } from 'core/protocols/http_client';
import { Profile } from 'features';
import ProfileOutputType from 'features/profile/domain/types/profile_output_type';
import { CustomerEntity } from 'features/purchase/domain/entities/customer_entity';

interface StepProfileFormProps {
    httpClient: IHttpClient;
    customer?: CustomerEntity;
    onNextStep: (profile: ProfileOutputType) => void;
}

function StepProfileForm({
    httpClient,
    onNextStep,
    customer
}: StepProfileFormProps) {
    return (
        <Profile
            httpClient={httpClient}
            onNextStep={onNextStep}
            profile={
                customer
                    ? {
                          cpf: customer?.cpf ?? '',
                          phone: customer?.phone ?? '',
                          birthday: customer?.birthday ?? '',
                          fullname: customer?.fullname ?? '',
                          address: {
                              cep: customer?.address?.zipcode ?? '',
                              logradouro: customer?.address?.name ?? '',
                              numero: customer?.address?.number ?? '',
                              complemento: customer?.address?.complement ?? '',
                              bairro: customer?.address?.neighborhood ?? '',
                              localidade: customer?.address?.city ?? '',
                              uf: customer?.address?.uf ?? ''
                          }
                      }
                    : undefined
            }
        />
    );
}

export default StepProfileForm;
