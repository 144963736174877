import { IGetFeatureToggle } from "core/feature_toggle/get_feature_toggle_interface";
import { ReactNode, createContext, useContext } from "react";
import GetFeatureToggleImpl from "./domain/usecases/get_feature_toggle";
import { useFirebase } from "bindings/firebase_binding";
import FeatureToggleFirestoreDatasourceImpl from "./data/feature_toggle_firestore_datasource";
import { getFirestore } from "firebase/firestore";

const FeatureToggleContext = createContext<IGetFeatureToggle | undefined>(undefined);

export function useFeatureToggle(): IGetFeatureToggle {
  const context = useContext(FeatureToggleContext);
  if (context === undefined) {
    throw new Error("Feature Toggle not found in context");
  }
  return context;
}

interface FeatureToggleBindingProps {
  children: ReactNode;
}

export default function FeatureToggleBindings({ children }: FeatureToggleBindingProps) {
  const firebase = useFirebase();
  const firestore = getFirestore(firebase);
  const datasource = FeatureToggleFirestoreDatasourceImpl.getInstance(firestore);

  return (
    <FeatureToggleContext.Provider value={new GetFeatureToggleImpl(datasource)}>
      {children}
    </FeatureToggleContext.Provider>
  );
}
