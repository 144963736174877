export default function IconLock() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.268 19.5998C18.268 20.4388 17.8122 21.1714 17.1347 21.5633V22.8665C17.1347 23.4924 16.6273 23.9998 16.0013 23.9998C15.3754 23.9998 14.868 23.4924 14.868 22.8665V21.5632C14.1905 21.1713 13.7347 20.4388 13.7347 19.5998C13.7347 18.348 14.7495 17.3332 16.0014 17.3332C17.2532 17.3332 18.268 18.348 18.268 19.5998Z"
                fill="#004BC1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0013 2.6665C13.0558 2.6665 10.668 5.05432 10.668 7.99984V13.3642C8.41561 13.6282 6.66797 15.5432 6.66797 17.8665V24.7998C6.66797 27.3035 8.69761 29.3332 11.2013 29.3332H20.8013C23.305 29.3332 25.3346 27.3035 25.3346 24.7998V17.8665C25.3346 15.5432 23.587 13.6282 21.3346 13.3642V7.99984C21.3346 5.05432 18.9468 2.6665 16.0013 2.6665ZM19.068 13.3332V7.99984C19.068 6.30616 17.695 4.93317 16.0013 4.93317C14.3076 4.93317 12.9346 6.30616 12.9346 7.99984V13.3332H19.068ZM8.93464 17.8665C8.93464 16.6147 9.94946 15.5998 11.2013 15.5998H20.8013C22.0531 15.5998 23.068 16.6147 23.068 17.8665V24.7998C23.068 26.0517 22.0531 27.0665 20.8013 27.0665H11.2013C9.94946 27.0665 8.93464 26.0517 8.93464 24.7998V17.8665Z"
                fill="#004BC1"
            />
        </svg>
    );
}
