import { IHttpClient } from "core/protocols/http_client";

import { endpoint } from "constants/endpoints";
import PhoneEntity from "../entities/phone_entity";
import * as Sentry from "@sentry/react";

export interface IGetModelsByBrandAndNameUsecase {
  execute(brandId: number, name: string): Promise<PhoneEntity[]>;
}

export default class GetModelsByBrandAndNameUsecaseImpl
  implements IGetModelsByBrandAndNameUsecase
{
  private httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

    execute(brandId: number, name: string): Promise<PhoneEntity[]> {
        return this.httpClient.get<[]>(endpoint.brands + `${brandId}/models/?q=${name}`).then(async response => {
            return response.data.map((item: any) => new PhoneEntity(
                item.brand,
                item.device_value,
                item.id,
                item.identifiers,
                item.name,
                item.storage
            ));
        }).catch(error => {
          Sentry.captureException(error, {
            tags: {
                'feature.brand_and_model_search': "GetModelsByBrandAndNameUsecaseImpl",
                'api.endpoint': `${brandId}/models/?q=${name}`,
            }
          });
            throw error;
        });
    }
}
