import PlanModel from "./plan_model";

export default class ProductModel {
  price: number;
  brandId: number;
  providerId: string;
  transactionId: string;
  modelId: number;
  plan: PlanModel;
  model: string;

  constructor(
    price: number,
    brandId: number,
    providerId: string,
    transactionId: string,
    modelId: number,
    plan: PlanModel,
    model: string
  ) {
    this.price = price;
    this.plan = plan;
    this.model = model;
    this.brandId = brandId;
    this.providerId = providerId;
    this.transactionId = transactionId;
    this.modelId = modelId;
  }
}
