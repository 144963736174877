import ProductModel from 'models/product_model';
import PhoneEntity from '../../brand_and_model_search/domain/entities/phone_entity';
import { CustomerEntity } from '../domain/entities/customer_entity';
import { default as OrderEntity } from '../domain/entities/order_entity';
import PhoneInfoEntity from '../domain/entities/phone_info_entity';
import checkPhoneAction from './actions/check_phone_action';
import PurchaseStepType from './purchase_step_type';

export class PurchaseState {
    product?: ProductModel | undefined;
    phone?: PhoneEntity | undefined;
    customer?: CustomerEntity | undefined;
    currentStep: PurchaseStepType;
    imei?: string | undefined;
    lastOrder?: OrderEntity;
    phoneInfo?: PhoneInfoEntity;

    constructor(args: {
        product?: ProductModel;
        phone?: PhoneEntity;
        currentStep?: PurchaseStepType;
        customer?: CustomerEntity;
        imei?: string;
        lastOrder?: OrderEntity;
        phoneInfo?: PhoneInfoEntity;
    }) {
        this.product = args.product;
        this.phone = args.phone;
        this.currentStep = args.currentStep ?? 'CHOOSE_PHONE';
        this.customer = args.customer;
        this.imei = args.imei;
        this.lastOrder = args.lastOrder;
        this.phoneInfo = args.phoneInfo;
    }
}

export type PurchaseActionType =
    | 'SET_PRODUCT'
    | 'SELECT_PRODUCT'
    | 'CLEAN_PRODUCT'
    | 'SET_PHONE'
    | 'CLEAN_PHONE'
    | 'LOAD_CUSTOMER'
    | 'CLEAN_CUSTOMER'
    | 'SET_PROFILE'
    | 'SET_PHONE_CHECKED'
    | 'SET_IMEI'
    | 'SET_PAYMENT_SUCCESS'
    | 'SET_PAYMENT_ERROR'
    | 'SET_TICKET_ENABLED'
    | 'SET_TICKET_INACTIVE'
    | 'SET_CREDIT_CARD'
    | 'LOGOUT'
    | 'ROLLBACK'
    | 'REQUESTING_PAYMENT'
    | 'SET_LAST_ORDER';

export type PurchaseStateAction = {
    type: PurchaseActionType;
    phone?: PhoneEntity;
    product?: ProductModel;
    customer?: CustomerEntity;
    imei?: string;
    lastOrder?: OrderEntity;
    phoneInfo?: PhoneInfoEntity;
};

function rollback(state: PurchaseState): PurchaseState {
    switch (state.currentStep) {
        case 'CHOOSE_PRODUCT':
            return new PurchaseState({
                ...state,
                phone: undefined,
                product: undefined,
                currentStep: 'CHOOSE_PHONE'
            });
        case 'SET_PROFILE':
            return new PurchaseState({
                ...state,
                product: undefined,
                currentStep: 'CHOOSE_PRODUCT'
            });
        case 'CONFIRM_PHONE_NUMBER':
            return new PurchaseState({
                ...state,
                currentStep: 'SET_PROFILE'
            });
        case 'SET_IMEI':
            return new PurchaseState({
                ...state,
                currentStep: checkPhoneAction(state.customer)
                    ? 'SET_PROFILE'
                    : 'CONFIRM_PHONE_NUMBER'
            });
        case 'SET_CREDIT_CARD':
            return new PurchaseState({
                ...state,
                currentStep: 'SET_IMEI'
            });
        case 'PAYMENT_ERROR':
            return new PurchaseState({
                ...state,
                currentStep: 'SET_CREDIT_CARD'
            });
        default:
            return state;
    }
}

function purchaseReducer(
    state: PurchaseState,
    action: PurchaseStateAction
): PurchaseState {
    switch (action.type) {
        case 'SET_PHONE':
            return new PurchaseState({
                ...state,
                phone: action.phone,
                currentStep: 'CHOOSE_PRODUCT'
            });
        case 'CLEAN_PHONE':
            return new PurchaseState({
                ...state,
                phone: undefined,
                product: undefined,
                currentStep: 'CHOOSE_PHONE'
            });
        case 'SELECT_PRODUCT':
            return new PurchaseState({
                ...state,
                product: action.product
            });
        case 'SET_PRODUCT':
            return new PurchaseState({
                ...state,
                product: action.product,
                currentStep: 'SET_PROFILE'
            });
        case 'CLEAN_PRODUCT':
            return new PurchaseState({
                ...state,
                product: undefined,
                currentStep: 'CHOOSE_PRODUCT'
            });
        case 'LOAD_CUSTOMER':
            return new PurchaseState({
                ...state,
                customer: action.customer
            });

        case 'CLEAN_CUSTOMER':
            return new PurchaseState({
                ...state,
                customer: undefined
            });
        case 'SET_PROFILE':
            return new PurchaseState({
                ...state,
                customer: action.customer,
                currentStep: checkPhoneAction(action.customer)
                    ? 'SET_IMEI'
                    : 'CONFIRM_PHONE_NUMBER'
            });
        case 'SET_PHONE_CHECKED':
            return new PurchaseState({
                ...state,
                customer: new CustomerEntity({
                    cpf: state.customer?.cpf ?? '',
                    phone: state.customer?.phone ?? '',
                    ...state.customer,
                    msisdns: [
                        ...(state.customer?.msisdns ?? []),
                        {
                            msisdn: state.customer?.phone,
                            validated: true
                        }
                    ]
                }),
                currentStep: 'SET_IMEI'
            });
        case 'SET_IMEI':
            return new PurchaseState({
                ...state,
                imei: action.imei,
                currentStep: 'SET_CREDIT_CARD'
            });
        case 'SET_PAYMENT_SUCCESS':
            return new PurchaseState({
                ...state,
                currentStep: 'PAYMENT_SUCCESS'
            });
        case 'SET_PAYMENT_ERROR':
            return new PurchaseState({
                ...state,
                currentStep: 'PAYMENT_ERROR'
            });
        case 'LOGOUT':
            return new PurchaseState({
                lastOrder: undefined,
                phone: undefined,
                phoneInfo: undefined,
                product: undefined,
                customer: undefined,
                imei: undefined,
                currentStep: state.phone ? 'CHOOSE_PRODUCT' : 'CHOOSE_PHONE'
            });
        case 'SET_TICKET_ENABLED':
            return new PurchaseState({
                ...state,
                lastOrder: action.lastOrder,
                customer: action.customer,
                phoneInfo: new PhoneInfoEntity(
                    action.customer?.phone ?? '',
                    action.lastOrder?.imei ?? '',
                    action.lastOrder?.brandName ?? '',
                    action.lastOrder?.modelName ?? ''
                ),
                currentStep: 'TICKET_ENABLED'
            });
        case 'SET_TICKET_INACTIVE':
            return new PurchaseState({
                ...state,
                lastOrder: action.lastOrder,
                customer: action.customer,
                currentStep: 'TICKET_INACTIVE',
                phoneInfo: new PhoneInfoEntity(
                    action.customer?.phone ?? '',
                    action.lastOrder?.imei ?? '',
                    action.lastOrder?.brandName ?? '',
                    action.lastOrder?.modelName ?? ''
                ),
            });
        case 'ROLLBACK':
            return rollback(state);
        case 'SET_LAST_ORDER':
            return new PurchaseState({
                ...state,
                lastOrder: action.lastOrder,
                phoneInfo: action.phoneInfo,
            });
        default:
            return state;
    }
}

export default purchaseReducer;
