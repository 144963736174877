import { IconArrowRight } from "gosafe/atoms/icons";
import { Button } from "gosafe/molecules/buttons";
import "./style.css";
import { useNavigate } from "react-router-dom";

function CardsSection() {

  const navigate = useNavigate();

  return (
    <section className="controls">
      <div className="content-center">
        <header>
          <h2>Vantagens de contratar o seguro celular na Exa</h2>
        </header>

        <ul className="list-controls">
          <li>
            <h3>Segurança ao seu alcançe</h3>
            <p>
              Facilidade e rapidez na<br /> contratação, além de planos<br /> acessíveis para todos.
            </p>
          </li>

          <li>
            <h3>Sem carência</h3>
            <p>
              Você aciona ou cancela o <br />seguro a qualquer momento,<br /> sem multas ou surpresas .
            </p>
          </li>

          <li>
            <h3>Zero<br /> complicações</h3>
            <p>
              O reembolso é rápido e o<br /> dinheiro cai direto na sua<br /> conta em até 5 dias úteis.
            </p>
          </li>

          <li>
            <h3>Facilidade na<br /> assinatura</h3>
            <p>
              Pagamento com cartão de<br /> crédito sem comprometer<br /> com o limite do seu cartão.
            </p>
          </li>
        </ul>

        <Button
          onClick={() => {
            navigate("/purchase");
          }}
          backgroundColor="bg-secondary"
          color="text-primary"
          hasBorder={false}
          showIconRight={true}
          iconRight={<IconArrowRight />}
          text="Cote agora"
        />
      </div>
    </section>
  );
}

export default CardsSection;
