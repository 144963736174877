import { createContext, useContext } from "react";

import { IGetAddressByCepUsecase } from "features/profile/domain/usecases/get_address_by_cep_usecase";

const GetAddressByCepContext = createContext<
  IGetAddressByCepUsecase | undefined
>(undefined);

export function useGetAddressByCepUsecase() {
  const context = useContext(GetAddressByCepContext);
  if (context === undefined) {
    throw new Error("IGetAddressByCepUsecase not found");
  }
  return context;
}

export default GetAddressByCepContext;
