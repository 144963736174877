export default function IconOutArticle({ width = 16, height = 20 }: { width?: number; height?: number }) {
  return (
    <svg width={width} height={width} viewBox={`0 0 ${width} ${height}`} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.85001 5C4.38055 5 4 5.38055 4 5.85001C4 6.31944 4.38055 6.7 4.85001 6.7H9.14999C9.61945 6.7 10 6.31944 10 5.85001C10 5.38055 9.61945 5 9.14999 5H4.85001Z" fill="currentColor" />
      <path d="M4 10C4 9.53058 4.38055 9.15002 4.85001 9.15002H11.15C11.6194 9.15002 12 9.53058 12 10C12 10.4695 11.6194 10.85 11.15 10.85H4.85001C4.38055 10.85 4 10.4695 4 10Z" fill="currentColor" />
      <path d="M4.85001 13.3C4.38055 13.3 4 13.6805 4 14.15C4 14.6194 4.38055 15 4.85001 15H6.14999C6.61945 15 7 14.6194 7 14.15C7 13.6805 6.61945 13.3 6.14999 13.3H4.85001Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 3.39999C0 1.52223 1.52223 0 3.39999 0H12.6C14.4778 0 16 1.52223 16 3.39999V16.6C16 18.4778 14.4778 20 12.6 20H3.39999C1.52223 20 0 18.4778 0 16.6V3.39999ZM3.39999 1.7H12.6C13.5389 1.7 14.3 2.46112 14.3 3.39999V16.6C14.3 17.5389 13.5389 18.3 12.6 18.3H3.39999C2.46112 18.3 1.7 17.5389 1.7 16.6V3.39999C1.7 2.46112 2.46112 1.7 3.39999 1.7Z" fill="currentColor" />
    </svg>
  );
}
