export default function ImeiDefinition() {
  return (
    <div className="gap-y-4 pt-9 pb-32 md:pb-0">
      <div className="flex w-full rounded-xl justify-center ">
        <img src={"/images/imei.png"} alt="Logo EXA" width={206} />
      </div>
      <div className="flex flex-col gap-2 my-5">
        <p className="text-sm">O IMEI do celular é um código único de identificação do aparelho.</p>
        <p className="text-sm">É como se fosse a impressão digital do seu celular, ou seja, não há mais de um aparelho com o mesmo código IMEI.</p>
      </div>
      <h3 className="text-xl font-medium">E por quê o pedimos?</h3>
      <div className="flex flex-col gap-6 mt-5">
        <div className="space-x-5 flex items-center">
          <div className="w-10 h-10 p-6 flex justify-center items-center bg-neutral-100 rounded-full">1</div>
          <p className="text-sm">Garantir que somente você consiga executar os comandos de privacidade a distancia no seu celular.</p>
        </div>
        <div className="space-x-5 flex items-center">
          <div className="w-10 h-10 p-6 flex justify-center items-center bg-neutral-100 rounded-full">2</div>
          <p className="text-sm">Garantir o processo de reembolso em caso de transações financeiras indevidas.</p>
        </div>
      </div>
    </div>
  );
}
