import QrCodePage from "./presenter/qr_code_page";
import QrCodeBinding from "./presenter/components/qr_code_binding";

function ContinueAnotherDevice() {
  return (
      <QrCodeBinding>
        <QrCodePage/>
      </QrCodeBinding>
  );
}

export default ContinueAnotherDevice;