import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import cpfValidator from 'core/validators/cpf_validator';
import dddValidator from 'core/validators/ddd_validator';
import { useAuthentication } from 'features/authentication/authentication_bindings';
import { useCreateProfileUsecase } from '../create_profile_context';
import AddressEntity from '../domain/entities/address_entity';
import ProfileEntity from '../domain/entities/profile_entity';
import ProfileOutputType from '../domain/types/profile_output_type';
import { useGetAddressByCepUsecase } from '../get_address_cep_context';
import { useSearchByCpfUsecase } from '../search_by_cpf_context';
import { useUpdateProfileUsecase } from '../update_profile_context';
import initialValueEqualToOutputValue from './utils/initial_value_equal_to_output_value';

export function useProfileFormController({
    onNextStep,
    onFailure,
    initialValue
}: {
    onNextStep?: (profile: ProfileOutputType) => void;
    onFailure?: (error: any) => void;
    initialValue?: ProfileEntity;
}) {
    const updateProfileUsecase = useUpdateProfileUsecase();
    const createProfileUsecase = useCreateProfileUsecase();
    const searchByCpfUsecase = useSearchByCpfUsecase();
    const { currentUser, authMethod } = useAuthentication();

    function validateZipCode(zipCode: string) {
        return /^\d{5}-\d{3}$/.test(zipCode);
    }

    function formatCpf(cpf: string) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    function formatCep(cep: string) {
        return cep.replace(/(\d{5})(\d{3})/, '$1-$2');
    }

    function formatPhone(phone: string) {
        return phone.replace(/(\d{2})(\d{5})(\d{4})/, '$1 $2-$3');
    }

    const form = useFormik({
        initialValues: {
            cpf: initialValue?.cpf ? formatCpf(initialValue.cpf) : '',
            phone: initialValue?.phone ? formatPhone(initialValue.phone) : '',
            zip_code: initialValue?.address?.cep ? formatCep(initialValue?.address?.cep) : '',
            address: initialValue?.address?.logradouro ?? '',
            number: initialValue?.address?.numero ?? '',
            complement: initialValue?.address?.complemento ?? '',
            neighborhood: initialValue?.address?.bairro ?? '',
            city: initialValue?.address?.localidade ?? '',
            uf: initialValue?.address?.uf ?? ''
        },
        validationSchema: Yup.object().shape({
            cpf: Yup.string()
                .required('O CPF é obrigatório!')
                .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido!')
                .test('cpf', 'CPF inválido!', (value) => {
                    const onlyNumbers = value.replace(/\D/g, '');
                    return cpfValidator(onlyNumbers);
                }),
            phone: Yup.string()
                .required('O celular é obrigatório!')
                .matches(/^\d{2}\s\d{5}-\d{4}$/, 'Celular inválido!')
                .test('phone', 'DDD inválido!', (value) => {
                    return dddValidator(
                        value.replace(/\D/g, '').substring(0, 2)
                    );
                }),
            zip_code: Yup.string()
                .required('O CEP é obrigatório!')
                .test('zip_code', 'CEP inválido!', validateZipCode),
            address: Yup.string().required('Endereço é obrigatório!'),
            number: Yup.number()
                .positive('O número tem que ser positivo')
                .test('number', 'Número é obrigatório!', (value) => {
                    if (withoutNumber) return true;
                    return value !== undefined;
                }),
            complement: Yup.string().test(
                'complement',
                'Complemento é obrigatório!',
                (value) => {
                    if (withoutComplement) return true;
                    return value !== undefined;
                }
            ),
            neighborhood: Yup.string().required('Bairro é obrigatório!'),
            city: Yup.string().required('Cidade é obrigatório!'),
            uf: Yup.string()
        }),
        isInitialValid: false,
        validateOnChange: true,
        onSubmit: async (values) => {
            form.setTouched({
                cpf: true,
                phone: true,
                zip_code: true,
                address: true,
                number: true,
                complement: true,
                neighborhood: true,
                city: true,
                uf: true
            });
            const address = new AddressEntity({
                cep: values.zip_code,
                logradouro: values.address,
                numero: values.number,
                complemento: values.complement,
                bairro: values.neighborhood,
                localidade: values.city,
                uf: values.uf
            });

            const output = {
                cpf: values.cpf,
                phone: values.phone,
                birthday: initialValue?.birthday ?? '',
                fullname: initialValue?.fullname ?? '',
                address: {
                    city: address.localidade!,
                    complement: address.complemento,
                    name: address.logradouro!,
                    neighborhood: address.bairro!,
                    number: address.numero,
                    zipcode: address.cep!,
                    uf: address.uf!
                }
            };

            const payload = {
                authUid: currentUser?.uid ?? '',
                authMethod: authMethod ?? '',
                email: currentUser?.email ?? '',
                fullname: initialValue?.fullname ?? '',
                profile: {
                    birthday: output.birthday,
                    cpf: values.cpf,
                    phone: values.phone,
                    address: address
                }
            };

            setRequesting(true);

            if (
                !initialValue ||
                initialValue?.cpf.replaceAll(/\D/g, '') !==
                    values.cpf.replaceAll(/\D/g, '')
            ) {
                try {
                    const bigQuery = await searchByCpfUsecase.execute(
                        values.cpf.replace(/\D/g, '')
                    );
                    payload.profile.birthday = bigQuery.BirthDate;
                    payload.fullname = bigQuery.Name;
                    output.birthday = bigQuery.BirthDate;
                    output.fullname = bigQuery.Name;
                } catch (e) {
                    setRequesting(false);
                    onFailure?.(e);
                    return;
                }
            }

            if (!initialValue) {
                createProfileUsecase
                    .execute(payload)
                    .then((response) => {
                        setRequesting(false);
                        onNextStep?.(output);
                    })
                    .catch(() => {
                        setRequesting(false);
                    });
                return;
            } else {
                const hasChanged = !initialValueEqualToOutputValue(
                    initialValue,
                    output
                );
                if (hasChanged) {
                    updateProfileUsecase
                        .execute(payload)
                        .then((response) => {
                            setRequesting(false);
                            onNextStep?.(output);
                        })
                        .catch((err) => {
                            setRequesting(false);
                        });
                } else if (initialValue) {
                    setRequesting(false);
                    onNextStep?.(output);
                }
            }
        }
    });

    function initializeWithouNumber(): boolean {
        if (initialValue) {
            return (
                initialValue.address.numero === 'NA' ||
                initialValue.address.numero === ''
            );
        }
        return false;
    }

    function initializeWithouComplement(): boolean {
        if (initialValue) {
            return initialValue.address.complemento === '';
        }
        return false;
    }

    const [addressLoading, setAddressLoading] = useState(false);
    const [withoutNumber, setWithoutNumber] = useState(
        initializeWithouNumber()
    );
    const [withoutComplement, setWithoutComplement] = useState(
        initializeWithouComplement()
    );
    const [showFormAddress, setShowFormAddress] = useState(
        initialValue ? true : false
    );
    const [initializing, setInitializing] = useState(true);
    const [requesting, setRequesting] = useState(false);

    const getAddressByCep = useGetAddressByCepUsecase();

    const [isDisabled, setIsDisabled] = useState(!initialValue ? true : false);
    const isFormValid = form.isValid && form.dirty;

    useEffect(() => {
        setInitializing(false);
    }, []);

    useEffect(() => {
        if (!initializing) {
            if (validateZipCode(form.values.zip_code)) {
                if (showFormAddress) {
                    setShowFormAddress(true);
                }
                {
                    handleZipcode().then(() => {
                        setIsDisabled(false);
                    });
                }
            } else {
                setIsDisabled(true);
            }
        }
    }, [form.values.zip_code]);

    useEffect(() => {
        if (!initializing) {
            form.setFieldValue('number', '', true);
        }
    }, [withoutNumber]);

    useEffect(() => {
        if (!initializing) {
            form.setFieldValue('complement', '', true);
        }
    }, [withoutComplement]);

    function checkWithoutNumber(value: boolean) {
        setWithoutNumber(value);
    }

    function checkWithoutComplement(value: boolean) {
        setWithoutComplement(value);
    }

    async function handleZipcode() {
        const zipCode = form.values.zip_code;
        return getAddress(zipCode.replace(/\D/g, ''))
            .then((address) => {
                form.setValues({
                    ...form.values,
                    zip_code: zipCode,
                    address: address.logradouro ?? '',
                    city: address.localidade ?? '',
                    complement: address.complemento ?? '',
                    neighborhood: address.bairro ?? '',
                    uf: address.uf ?? ''
                });

                if (!showFormAddress) {
                    setShowFormAddress(true);
                }
            })
            .catch(() => {
                form.setValues({
                    ...form.values,
                    zip_code: zipCode,
                    address: '',
                    city: '',
                    complement: '',
                    neighborhood: '',
                    uf: ''
                });
                if (!showFormAddress) {
                    setShowFormAddress(true);
                }
            })
            .finally(() => {
                return;
            });
    }

    function getAddress(query: string): Promise<AddressEntity> {
        setAddressLoading(true);
        return getAddressByCep
            .execute(query)
            .then((item: AddressEntity) => {
                const addressResponse = new AddressEntity({
                    cep: item.cep,
                    logradouro: item.logradouro,
                    bairro: item.bairro,
                    complemento: item.complemento,
                    localidade: item.localidade,
                    uf: item.uf
                });
                setAddressLoading(false);
                return addressResponse;
            })
            .catch((error) => {
                setAddressLoading(false);
                throw error;
            });
    }

    return {
        form,
        isDisabled,
        isFormValid,
        updateProfileUsecase,
        withoutComplement,
        withoutNumber,
        addressLoading,
        showFormAddress,
        getAddress,
        checkWithoutNumber,
        checkWithoutComplement,
        handleZipcode,
        requesting
    };
}
