import { IHttpClient } from "core/protocols/http_client";
import GetUserUsecaseImpl from "features/purchase/domain/usecases/get_user_usecase";
import UpdateUserUsecaseImpl from "features/purchase/domain/usecases/update_user_usecase";
import UpdateUserContext from "features/purchase/update_user_context";
import { ReactNode } from "react";
import { GetUserContext } from "./get_user_context";
import { PurchaseStateProvider } from "./presenter/purchase_state_context";
import GetAddressByCepContext from "features/profile/get_address_cep_context";
import { GetAddressByCepUsecaseImpl } from "./domain/usecases/get_address_by_cep_usecase";
import { CreateUserContext } from "./create_user_context";
import CreateUserUsecaseImpl from "./domain/usecases/create_user_usecase";
import CheckDetachedOrderContext from "./check_detached_order_context";
import { CheckDetachedOrderUsecaseImpl } from "./domain/usecases/check_detached_order";
import GetLastPolicyContext from './get_last_policy_context';
import GetAllOrdersContext from "./get_all_orders_context";
import { GetLastPolicyUseCase } from "./domain/usecases/get_last_policy_use_case";
import GetAllOrdersUseCaseImpl from "./domain/usecases/get_all_orders_use_case";

interface PurchaseBindingsProps {
    children: ReactNode;
    httpClient: IHttpClient;
    httpSalesClient: IHttpClient;
}

export default function PurchaseBinding({ children, httpClient, httpSalesClient }: PurchaseBindingsProps) {
    return (
        <GetUserContext.Provider value={new GetUserUsecaseImpl(httpSalesClient)}>
            <GetLastPolicyContext.Provider value={new GetLastPolicyUseCase(httpSalesClient)}>
                <GetAllOrdersContext.Provider value={new GetAllOrdersUseCaseImpl(httpSalesClient)}>
                    <CreateUserContext.Provider value={new CreateUserUsecaseImpl(httpClient)}>
                        <UpdateUserContext.Provider value={new UpdateUserUsecaseImpl(httpClient)}>
                            <GetAddressByCepContext.Provider value={new GetAddressByCepUsecaseImpl(httpClient)}>
                                <CheckDetachedOrderContext.Provider value={new CheckDetachedOrderUsecaseImpl(httpSalesClient)}>
                                    <PurchaseStateProvider>
                                        {children}
                                    </PurchaseStateProvider>
                                </CheckDetachedOrderContext.Provider>
                            </GetAddressByCepContext.Provider>
                        </UpdateUserContext.Provider>
                    </CreateUserContext.Provider>
                </GetAllOrdersContext.Provider>
            </GetLastPolicyContext.Provider>
        </GetUserContext.Provider>
    );
}
