import { ReactNode, useEffect } from "react";

import { useFirebase } from "bindings/firebase_binding";
import { getAuth } from "firebase/auth";

import { useAuthentication } from "./authentication_bindings";

interface InitializerProps {
  children: ReactNode;
}

function AuthenticationMidleware({ children }: InitializerProps) {
  const app = useFirebase();
  const auth = getAuth(app);
  const authentication = useAuthentication();

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      authentication.setCurrentUser(user);
      authentication.setIsLoading(false);
      if (user) {
        user.getIdToken().then(token => {
          authentication.setAccessToken(token);
        });
      }
    });
  });

  return <div>{children}</div>;
}

export default AuthenticationMidleware;
