import InsuranceDrawer from 'features/purchase/presenter/components/insurance_drawer/policy_drawer_from_screen';
import { CertificateActiveProps } from 'features/purchase/presenter/components/steps/step_certificate_active';
import {
    CardWrapper,
    IconArrowRightLight,
    IconLock,
    ListTile,
    Text,
    useDrawer
} from 'gosafe';

function CertificateActive({ order }: CertificateActiveProps) {
    const { openDrawer } = useDrawer();
    return (
        <>
            <div
                id="active-policy"
                className="py-20 flex justify-center items-center"
            >
                <div className="flex flex-col md:flex-row md:space-x-32 space-y-4 md:space-y-0 p-4">
                    <div className="flex flex-col">
                        <span className="text-primary font-medium font-sans text-4xl mb-8">
                            Seu seguro está ativo!
                        </span>
                        <CardWrapper className="p-4 mt-4 flex flex-col">
                            <div
                                className="pb-4 items-center border-md border-b-[.1px]"
                                onClick={() => {
                                    openDrawer(
                                        <InsuranceDrawer order={order} />
                                    );
                                }}
                            >
                                <ListTile
                                    trealing={<IconLock />}
                                    leading={<IconArrowRightLight />}
                                >
                                    <div className="flex flex-col pr-4">
                                        <Text
                                            color="text-neutral-10"
                                            fontSize="text-base"
                                            fontFamily="font-sans"
                                            fontWeight="font-medium"
                                        >
                                            {order.planName ?? ''}
                                        </Text>
                                        <Text
                                            color="text-neutral-10"
                                            fontSize="text-sm"
                                            fontFamily="font-serif"
                                            fontWeight="font-normal"
                                        >
                                            Nº da apólice: {order.policyNumber}
                                        </Text>
                                    </div>
                                </ListTile>
                            </div>
                            <div>
                                <div className="tag">
                                    <Text
                                        fontFamily="font-serif"
                                        color="text-neutral-10"
                                        fontSize="text-xs"
                                        fontWeight="font-bold"
                                    >
                                        Ativo
                                    </Text>
                                </div>
                            </div>
                        </CardWrapper>
                    </div>
                    <div className="flex flex-row items-center justify-center">
                        <img
                            width={300}
                            alt=""
                            src="/images/woman-talking-about-security.png"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CertificateActive;
