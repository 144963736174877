import HasOrderByCpfContext from "./has_order_by_cpf_context";
import { HasOrderByCpfUsecaseImpl } from "./domain/usecases/has_order_by_cpf_usecase";
import CPFCapturePage from "./presenter/cpf_capture_page";
import { useHttpSalesClient } from "bindings/http_sales_binding";

function CPFCapture() {
    const httpClient = useHttpSalesClient();

    return (
        <HasOrderByCpfContext.Provider value={new HasOrderByCpfUsecaseImpl(httpClient)}>
            <CPFCapturePage />
        </HasOrderByCpfContext.Provider>
    );
}

export default CPFCapture;