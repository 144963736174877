import { createContext, useContext } from "react";
import { ISearchByCpfUsecase } from "./domain/usecases/search_by_cpf_usecase";


const SearchByCpfContext = createContext<ISearchByCpfUsecase | undefined>(undefined);

export function useSearchByCpfUsecase(): ISearchByCpfUsecase {
    const context = useContext(SearchByCpfContext);
    if (context === undefined) {
        throw new Error('useSearchByCpf must be used within a SearchByCpfProvider');
    }
    return context;
}

export default SearchByCpfContext;