function IconArrowDown() {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.767401 0.233422C1.10969 -0.0917802 1.64817 -0.0751431 1.97013 0.270582L7.5027 6.21143L13.0286 0.272271C13.3504 -0.0736031 13.8889 -0.0904887 14.2313 0.234556C14.5738 0.5596 14.5905 1.10349 14.2687 1.44936L8.63338 7.50607C8.02082 8.16444 6.98545 8.16468 6.37259 7.50659L0.730611 1.44825C0.408645 1.10252 0.425117 0.558625 0.767401 0.233422Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconArrowDown;
