interface RadioProps {
  value?: boolean;
}

export default function Radio({ value = false }: RadioProps) {
  const className = ["w-2 h-2 rounded-full cursor-pointer"];

  if (value) {
    className.push("bg-blue-dark ring-2 ring-primary ring-offset-2");
  } else {
    className.push("ring-2 ring-neutral-80 ring-offset-2");
  }

  return <div className={className.join(" ")} />;
}
