import { Form, FormikProvider } from 'formik';
import {
    ExaLoading,
    IconArrowRight,
    InfinityLoading,
    SecondaryButton,
    TextField
} from 'gosafe';
import CheckboxField from 'gosafe/molecules/checkbox_field';
import useProfilePersonalInfoController, {
    IProfilePersonalInfoController
} from './profile_personal_info_controller';

export default function ProfilePersonalInfoForm({
    onBack
}: IProfilePersonalInfoController) {
    const {
        form,
        withoutNumber,
        setWithoutNumber,
        withoutComplement,
        setWithoutComplement,
        initializing,
        hasChanges,
        loadingUpdate,
        onZipcode
    } = useProfilePersonalInfoController({ onBack });

    return (
        <div>
            {initializing ? (
                <div className="flex items-center justify-center p-12">
                    <ExaLoading />
                </div>
            ) : (
                <FormikProvider value={form}>
                    <Form>
                        <form className="flex flex-col gap-3">
                            <TextField
                                name="fullname"
                                textLabel="Nome Completo"
                                onChange={form.handleChange}
                                value={form.values.fullname}
                                hintText="Nome Completo"
                                showErros={form.touched.fullname}
                                errorMessage={form.errors.fullname}
                                disabled
                            />
                            <TextField
                                name="cpf"
                                textLabel="CPF"
                                hintText="000.000.000-00"
                                mask={'999.999.999-99'}
                                value={form.values.cpf}
                                onChange={form.handleChange}
                                showErros={form.touched.cpf}
                                errorMessage={form.errors.cpf}
                                disabled
                            />
                            <TextField
                                name="email"
                                textLabel="E-mail"
                                onChange={form.handleChange}
                                value={form.values.email}
                                showErros={form.touched.email}
                                errorMessage={form.errors.email}
                                disabled
                            />
                            <TextField
                                name="zip_code"
                                textLabel="CEP"
                                hintText="00000-000"
                                mask={'99999-999'}
                                value={form.values.zip_code}
                                onChange={(event) => {
                                    form.handleChange(event);
                                    onZipcode(event.target.value);
                                }}
                                showErros={form.touched.zip_code}
                                errorMessage={form.errors.zip_code}
                            />
                            <TextField
                                name="address"
                                textLabel="Endereço"
                                onChange={form.handleChange}
                                value={form.values.address}
                                hintText="Digite seu Endereço"
                                showErros={form.touched.address}
                                errorMessage={form.errors.address}
                            />
                            <div className="flex gap-3">
                                <div className="space-y-2">
                                    <TextField
                                        name="number"
                                        textLabel="Número"
                                        onChange={form.handleChange}
                                        value={form.values.number}
                                        hintText="Digite o número"
                                        showErros={form.touched.number}
                                        errorMessage={form.errors.number}
                                        disabled={withoutNumber}
                                    />
                                    <CheckboxField
                                        id="check-without-number"
                                        key={withoutNumber.toString()}
                                        textLabel="Sem número"
                                        showLabel
                                        value={withoutNumber}
                                        onChange={(value) =>
                                            setWithoutNumber(value)
                                        }
                                    />
                                </div>
                                <div className="space-y-2">
                                    <TextField
                                        name="complement"
                                        textLabel="Complemento"
                                        onChange={form.handleChange}
                                        value={form.values.complement}
                                        hintText="Digite o complemento"
                                        showErros={form.touched.complement}
                                        errorMessage={form.errors.complement}
                                        disabled={withoutComplement}
                                    />
                                    <CheckboxField
                                        id="check-withoutComplement"
                                        key={withoutComplement.toString()}
                                        textLabel="Sem complemento"
                                        showLabel
                                        value={withoutComplement}
                                        onChange={(value) => {
                                            setWithoutComplement(value);
                                        }}
                                    />
                                </div>
                            </div>
                            <TextField
                                name="neighborhood"
                                textLabel="Bairro"
                                onChange={form.handleChange}
                                value={form.values.neighborhood}
                                hintText="Digite o bairro"
                                showErros={form.touched.neighborhood}
                                errorMessage={form.errors.neighborhood}
                            />
                            <TextField
                                name="city"
                                textLabel="Cidade"
                                onChange={form.handleChange}
                                value={form.values.city}
                                hintText="Digite a cidade"
                                showErros={form.touched.city}
                                errorMessage={form.errors.city}
                                disabled
                            />
                            <TextField
                                name="uf"
                                textLabel="UF"
                                value={form.values.uf}
                                hintText="Selecione uma UF"
                                disabled
                            />
                            <div className="h-2" />
                            <SecondaryButton
                                id="btn-submit"
                                text="Salvar"
                                enabled={hasChanges}
                                onClick={() => {
                                    form.submitForm();
                                }}
                                showIconRight={loadingUpdate}
                                iconRight={
                                    loadingUpdate ? (
                                        <InfinityLoading
                                            width={24}
                                            strokeColor="text-primary"
                                        />
                                    ) : (
                                        <IconArrowRight />
                                    )
                                }
                            />
                            <div className="h-4" />
                        </form>
                    </Form>
                </FormikProvider>
            )}
        </div>
    );
}
