import { createContext, useContext } from "react";
import { ICreateUserUsecase } from "./domain/usecases/create_user_usecase";



export const CreateUserContext = createContext<ICreateUserUsecase | undefined>(undefined);

export function useCreateUserUsecase(){
    const usecase = useContext(CreateUserContext);
    if(!usecase){
        throw new Error("useCreateUserUsecase must be used within a CreateUserContext.Provider");
    }
    return usecase;
}