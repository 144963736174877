import * as Sentry from "@sentry/react";
import { IGetFeatureToggle } from "core/feature_toggle/get_feature_toggle_interface";
import { IFeatureToggleDatasource } from "../interfaces/feature_toggle_datasource";

class GetFeatureToggleImpl implements IGetFeatureToggle {
    private datasource: IFeatureToggleDatasource;

    constructor(datasource: IFeatureToggleDatasource) {
        this.datasource = datasource;
    }

    async isEnabled(featureKey: string): Promise<boolean> {
        try {
            return  this.datasource.getValue(featureKey);
        } catch (error) {
            Sentry.captureException(error, {
                tags: {
                    'feature.feature_toggle': "GetFeatureToggleImpl",
                }
            });

            return false;
        }
    }
}

export default GetFeatureToggleImpl;
