import { IconArrowLeftLigth, Text } from 'gosafe/atoms';
import { Button } from 'gosafe/molecules/buttons';
import React from 'react';

export function MyOptionsTitle() {
    return (
        <Text fontWeight="font-medium" color="text-blue-dark">
            <span className="block text-center text-4xl">
                Escolha uma forma de acesso para
            </span>
            <span className="block text-center text-4xl">continuar:</span>
        </Text>
    );
}
export function ButtonOption({
    id,
    onClick,
    text,
    icon
}: {
    id?: string;
    onClick: () => void;
    text: string;
    icon: React.ReactNode;
}) {
    return (
        <div className="min-w-[320px]">
            <Button
                id={id}
                onClick={onClick}
                color="text-neutral-0"
                aligment="justify-between"
                showIconRight={true}
                showIconLeft={true}
                iconRight={<IconArrowLeftLigth />}
                iconLeft={
                    <div className="flex items-center gap-2">
                        {icon} {text}
                    </div>
                }
                padding="px-[16px] py-[16px]"
                infinity={true}
                borderRadius="rounded-3xl"
            />
        </div>
    );
}
