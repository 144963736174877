import CreditCardEntity from "./credit_card_entity";

export default class PaymentEntity {

    private creditCard: CreditCardEntity;

    constructor(args: {
        creditCard: CreditCardEntity
    }) {
        this.creditCard = args.creditCard;
    }

    toMap() {
        return {
            creditCard: this.creditCard.toMap()
        };
    }
}