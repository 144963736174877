import {Text} from "gosafe/atoms";

export function QrCodeTitle() {
  return (
    <Text fontWeight="font-medium" color="text-blue-dark">
      <span className="block text-center text-4xl" data-testid="QrCodeTitle">
        Acesse o QR Code para continuar
      </span>
    </Text>
  );
}