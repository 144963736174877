import { TextColor } from "gosafe/themes";

interface InfinityLoadingProps {
  strokeWidth?: number;
  strokeColor?: TextColor;
  width?: number;
  height?: number;
}

export default function InfinityLoading({
  strokeWidth = 8,
  strokeColor = "text-primary",
  width = 32,
  height = 32,
}: InfinityLoadingProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width={width}
      height={height}
      className={strokeColor}
    >
      <g>
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeDasharray="169.34869262695312 87.24023559570313"
          d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
          strokeLinecap="round"
        >
          <animate
            attributeName="stroke-dashoffset"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;1"
            values="0;256.58892822265625"
          />
        </path>
        <g />
      </g>
    </svg>
  );
}
