import forge from 'node-forge';
import { IEncryptAesService } from './encrypt_aes.service';
import { IEncryptRsaService } from './encrypt_rsa.service';

export interface IEncryptService {
  encrypt(data: any): string;
  decrypt(data: string): any;
}

class EncryptServiceImpl implements IEncryptService {
  private encryptAesService: IEncryptAesService;
  private encryptRsaService: IEncryptRsaService;

  constructor(encryptRsaService: IEncryptRsaService, encryptAesService: IEncryptAesService) {
    this.encryptAesService = encryptAesService;
    this.encryptRsaService = encryptRsaService;
  }

  encrypt(object: any): string {

    const data = JSON.stringify(object);

    const { aesKey, initializationVector } = this.encryptAesService.generateKey();

    const encryptedData = this.encryptAesService.encrypt({ data, aesKey, initializationVector });
    const encryptedAesKey = this.encryptRsaService.encrypt(aesKey);
    const encryptedIv = this.encryptRsaService.encrypt(initializationVector);

    const json = JSON.stringify({
      data: encryptedData,
      key: encryptedAesKey,
      iv: encryptedIv
    });

    const jsonBase64 = forge.util.encode64(json);

    return jsonBase64;
  }

  decrypt(encrypted: string): any {
    const { data, key, iv } = JSON.parse(forge.util.decode64(encrypted));

    const decryptedAesKey = this.encryptRsaService.decrypt(key);
    const decryptedIv = this.encryptRsaService.decrypt(iv);
    const decryptedData =  this.encryptAesService.decryp({ encryptedData: data, aesKey: decryptedAesKey, initializationVector: decryptedIv });

    return JSON.parse(decryptedData);
  }
}

export default EncryptServiceImpl;