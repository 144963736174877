import { ReactNode } from "react";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { IconMenu } from "gosafe/atoms";

export interface OptionMenuProps {
  title: string;
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  onTap?: VoidFunction;
  enabled?: boolean;
}

interface IDropdownMenuProps {
  optionsMenu: OptionMenuProps[];
}

function DropdownMenu({ optionsMenu }: IDropdownMenuProps) {
  return (
    <>
      <Menu>
        <MenuButton data-testeid="menu-button" className="rounded-md bg-primary p-1 text-neutral-100">
          <IconMenu />
        </MenuButton>
        <Transition
          enter="transition ease-out duration-75"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <MenuItems
            anchor="bottom end"
            className="mt-6 w-60 origin-top-right rounded-xl bg-neutral-100 p-1.5 text-base/10 text-primary [--anchor-gap:var(--spacing-1)] focus:outline-none"
          >
            {optionsMenu.filter((item) => item.enabled).map((item: OptionMenuProps, index) => {
              const className = [
                "flex font-sans font-semibold w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-neutral-90",
              ];

              if (!item.enabled) {
                className.push("text-neutral-70");
              }

              if (item.iconPosition === "right") {
                className.push("justify-between");
              }

              return (
                <MenuItem key={`menu-item-${index}`}>
                  <button
                    data-testid={`menu-item-${item.title}`}
                    onClick={() => {
                      if (item.onTap && item.enabled) item.onTap();
                    }}
                    className={className.join(" ")}
                  >
                    {item.icon && item.iconPosition === "left" && item.icon}
                    {item.title}
                    {item.icon && item.iconPosition === "right" && item.icon}
                  </button>
                </MenuItem>
              );
            })}
          </MenuItems>
        </Transition>
      </Menu>
    </>
  );
}

export default DropdownMenu;
