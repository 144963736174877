import { IHttpClient } from "core/protocols/http_client";
import * as Sentry from "@sentry/react";

export interface IVerifyEmailCodeUsecase {
    execute(userId: string, email: string, code: string): Promise<boolean>
}

export class VerifyEmailCodeUsecaseImpl implements IVerifyEmailCodeUsecase {
    private httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }

    async execute(userId: string, email: string, code: string): Promise<boolean> {
        const endpoint = `/otp/validate-otp/${code}`;
        return this.httpClient.post(endpoint, { maskedEmail: email, userId: userId }).then(() => {
            return true;
        }).catch((error) => {
            Sentry.captureException(error, {
                tags: {
                    'feature.email_check': "VerifyEmailCodeUsecase",
                    'api.endpoint': endpoint,
                },
            });
            throw error;
        });
    }
}