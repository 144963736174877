function IconCross() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 9H18" stroke="#ACABB7" strokeWidth="1.5" />
      <path d="M9 0.0166016L9 17.9833" stroke="#ACABB7" strokeWidth="1.5" />
    </svg>
  );
}

export default IconCross;
