import { MouseEventHandler, ReactNode } from 'react';

import { BackgroundColor, TextColor } from 'gosafe/themes';

export interface ButtonProps {
    id?: string;
    backgroundColor?: BackgroundColor;
    disabledBackgroundColor?: BackgroundColor;
    color?: TextColor;
    disabledColor?: TextColor;
    text?: string;
    onClick?: MouseEventHandler;
    children?: ReactNode;
    infinity?: boolean;
    enabled?: boolean;
    showIconRight?: boolean;
    iconRight?: ReactNode;
    showIconLeft?: boolean;
    iconLeft?: ReactNode;
    hasBorder?: boolean;
    padding?: string;
    borderRadius?: 'rounded-xl' | 'rounded-2xl' | 'rounded-3xl';
    aligment?: 'justify-center' | 'justify-between';
    fontFamily?: string;
    fontWeigth?: string;
    type?: 'button' | 'submit' | 'reset';
}

function OutlinedButton({
    id,
    disabledBackgroundColor = 'bg-neutral-90',
    color = 'text-primary',
    disabledColor = 'text-neutral-40',
    text,
    onClick,
    infinity = false,
    enabled = true,
    showIconRight = false,
    iconRight,
    showIconLeft = false,
    iconLeft,
    type = 'button'
}: ButtonProps) {
    const className = ['btn-outlined'];

    if (infinity) {
        className.push('w-full');
    }

    if (enabled) {
        className.push(color);
    } else {
        className.push(disabledBackgroundColor);
        className.push(disabledColor);
    }
    return (
        <button
            id={id}
            type={type}
            className={className.join(' ')}
            title={text}
            data-testid={id}
            onClick={enabled ? onClick : undefined}
        >
            {showIconLeft && iconLeft}
            {text}
            {showIconRight && iconRight}
        </button>
    );
}

export default OutlinedButton;
