import { useHttpSalesClient } from "bindings/http_sales_binding";
import { GetFaqUsecaseImpl } from "features/faq/domain/usecases/get_faq_usecase";
import GetFaqContext from "features/faq/get_faq_context";
import FaqPage from "features/faq/presenter/faq_page";

export default function FaqBinding(){
  const httpClient = useHttpSalesClient();

  return (
    <GetFaqContext.Provider value={new GetFaqUsecaseImpl(httpClient)}>
        <FaqPage/>
    </GetFaqContext.Provider>
  );
}
