import Footer from 'features/checkout_app/components/Footer';
import Header from 'features/checkout_app/components/Header';
import useFaqController from 'features/faq/presenter/faq_controller';
import style from './style.module.css';
import { ExaLoading } from 'gosafe';

const FaqPage = () => {
  const { faqs, loading, open } = useFaqController();

  return (
    <div>
      <Header text="Perguntas Frequentes" />
      {loading ?
        <div className='flex flex-col gap-4 justify-center items-center h-[500px] text-primary'>
          <ExaLoading />
        </div> :
        <div className={`${style.listDetails} ${style.center}`}>
          {faqs.map(faq => {
            return (
              <details open={open} key={faq.id}>
                <summary>{faq.order} - {faq.title}</summary>
                <div>
                  <p>{faq.description}</p>
                </div>
              </details>
            );
          })}
        </div>}
      <Footer />
    </div>
  );
};

export default FaqPage;