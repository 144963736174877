import { CardWrapper, IconArrowRight, SecondaryButton, TextField } from "gosafe";
import CreditCard from "./componentes/credit_card";
import useCheckoutController from "./checkout_controller";
import { useSendPaymentUsecase } from "../send_payment_context";
import { useAuthentication } from "features/authentication/authentication_bindings";
import ProductEntity from "../domain/entities/product_entity";
import PaymentLoading from "./componentes/payment_loading";

export interface CheckoutViewProps {
    product: ProductEntity | undefined
    phoneNumber: string | undefined
    imei: string | undefined
    onSuccess?: () => void
    onError?: () => void
}

function CheckoutView(props: CheckoutViewProps ) {

    const sendPayment = useSendPaymentUsecase();

    const authState = useAuthentication();

    const {
        handleChange,
        handleNumberChange,
        handleSubmit,
        errors,
        touched,
        flag,
        number,
        name,
        expiry,
        cvv,
        loading,
     } = useCheckoutController(sendPayment, authState, props);

    return (
        
        <CardWrapper>
            {!loading && <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 p-8">

                <div className="flex justify-center">
                    <CreditCard flag={flag}
                        number={number}
                        name={name}
                        expiry={expiry}
                        cvv={cvv} />
                </div>
                <TextField
                    name="number"
                    onChange={(event) => {
                        handleNumberChange(event.target.value);
                        handleChange(event);
                    }}
                    showErros={touched.number}
                    errorMessage={errors.number}
                    value={number}
                    hintText="0000 0000 0000 0000"
                    mask={'9999 9999 9999 9999'}
                    textLabel="Número do cartão" />
                <TextField
                    name="name"
                    onChange={handleChange}
                    showErros={touched.name}
                    errorMessage={errors.name}
                    value={name}
                    hintText="Nome impresso no cartão"
                    textLabel="Nome impresso no cartão" />
                <div className="flex gap-4">
                    <div className="basis-1/2">
                        <TextField
                            name="expiry"
                            onChange={handleChange}
                            showErros={touched.expiry}
                            errorMessage={errors.expiry}
                            value={expiry}
                            hintText="MM/AA"
                            mask={'99/99'}
                            textLabel="Vencimento" />
                    </div>
                    <div className="basis-1/2">
                        <TextField
                            name="cvv"
                            onChange={handleChange}
                            hintText="000"
                            mask={'999'}
                            value={cvv}
                            showErros={touched.cvv}
                            errorMessage={errors.cvv}
                            textLabel="CVV" />
                    </div>
                </div>
                <SecondaryButton
                    id="btn-submit"
                    enabled={true}
                    showIconRight
                    iconRight={<IconArrowRight />}
                    type="submit"
                    text="Finalizar" />
            </form>}

            {loading && <div className="flex flex-col gap-4 p-8 py-20">
                <PaymentLoading />
            </div>}
            
        </CardWrapper>
    );
}

export default CheckoutView;