import { PlanModel, ProductModel } from 'models';

export class GetProductInput {
    brand: string;
    model: string;
    storage: string;

    constructor(brand: string, model: string, storage: string) {
        this.brand = brand;
        this.model = model;
        this.storage = storage;
    }

    get data() {
        return JSON.stringify({
            brand: this.brand,
            model: this.model,
            storage: this.storage
        });
    }
}

export interface IGetProductsUsecase {
    execute(input: GetProductInput): Promise<ProductModel[]>;
}

export class GetProductsUsecaseImpl implements IGetProductsUsecase {
    async execute(input: GetProductInput): Promise<ProductModel[]> {
        const url = `${process.env.REACT_APP_SALES_URL}/search?brand=${encodeURIComponent(input.brand)}&model=${encodeURIComponent(input.model)}&storage=${encodeURIComponent(input.storage)}`;

        return fetch(url, {
            method: 'GET',
            headers: {
                'X-App-Version': '1'
            }
        })
            .then(async (result) => {
                if (result.ok) {
                    return result.json().then((data) => {
                        return data.map((item: any) => {
                            return new ProductModel(
                                item.price,
                                item.brandId,
                                item.providerId,
                                item.transactionId,
                                item.modelId,
                                new PlanModel(
                                    item.plan.code,
                                    item.plan.id,
                                    item.plan.name,
                                    item.plan.description
                                ),
                                item.model
                            );
                        });
                    });
                }
                return [];
            })
            .catch((error) => {
                throw error;
            });
    }
}
