import { IHttpClient } from "core/protocols/http_client";
import * as Sentry from "@sentry/react";

export interface ISendCodeUsecase {
    execute: (phoneNumber: string) => Promise<void>;
}
class SendCodeUsecase implements ISendCodeUsecase {
    private httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
    }
    async execute(phoneNumber: string): Promise<void> {
        const phone = phoneNumber.replace(/\D/g, '');
        return this.httpClient.post<any>(`/bff/v1/user/sms/send/${phone}`)
            .then(() => {
                return;
            }).catch(error => {
                Sentry.captureException(error, {
                    tags: {
                        'feature.ownership_validation': true,
                        'api.endpoint': '/bff/v1/user/sms/send',
                    },
                    extra: {
                        phoneNumber: phone,
                    },
                });
                throw error;
            });
    }
}

export default SendCodeUsecase;
