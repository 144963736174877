import { createContext, useContext } from "react";
import { IVerifyEmailCodeUsecase } from "./domain/usecases/verify_email_code";

const VerifyEmailCodeContext = createContext<IVerifyEmailCodeUsecase | undefined>(undefined);

export function useVerifyEmailCodeUsecase(){
    const context = useContext(VerifyEmailCodeContext);
    if(!context){
        throw new Error('IVerifyEmailCodeUsecase not found');
    }
    return context;
}

export default VerifyEmailCodeContext;